@import 'scss/variables';
@import 'scss/flex';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';

.inputLabelContainer {
  .inputLabel {
    font-family: Avenir-Medium;
    font-size: $inputLabel;
  }
}
.selectContainer {
  .select {
    width: 100%;
    margin-bottom: 14px;

    min-width: 100%;
    width: 100%;
    position: relative;
    border: 1px solid $disabled-medium;
    border-radius: 8px !important;
    color: $pureBlack !important;
    background-color: $pureWhite !important;
    padding: 4px 17px;
    @include border-radius(4px);
    height: 35px;

    &:focus {
      background-color: $pureWhite !important;
      box-shadow: 0 0 0 2px $savvyYellow !important;
      border: unset !important;
    }
    &:active {
      box-shadow: 0 0 0 2px $savvyYellow !important;
      border: unset !important;
      background-color: $pureWhite !important;
    }
    &::after {
      position: absolute !important;
      right: 20px;
      top: 15px;
    }

    .dropdown-menu {
      width: 100%;
    }
  }
}
.error {
  font-size: 0.8rem;
  color: #c74b4b;
  margin-top: 0.1rem;
}
