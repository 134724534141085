@import 'scss/media_queries';
@import 'scss/variables';
@import 'scss/_theme';
@import 'scss/_flex';

.modal-content {
  min-height: 554px !important;
}

.modal-lg {
  max-width: 894px !important;
}
.disabled-btn {
  opacity: 0.5;
  cursor: not-allowed;
}

.redeem-modal {
  .cross-icon {
    @include flexbox();
    @include justify-content(flex-end);
    padding: 20px 20px 0px 0px;
    span {
      cursor: pointer;
    }
    img {
      opacity: 0.8;
      width: 18px;
    }
    margin-bottom: 15px;
  }
  .title {
    font-size: $headingMain;
    font-family: Avenir-DemiBold;
    text-align: center;
    color: $greyBlack;
  }
  .sub-title {
    font-size: $headingSection;
    color: $greyBlack;
    font-family: Avenir-DemiBold;
  }
  .heading {
    font-size: $headingSection;
    color: $greyBlack;
    font-family: Avenir-Regular;
  }
  .sub-heading {
    font-size: $textParagraphOne;
    color: $greyBlack;
    font-family: Avenir-Regular;
  }
  .custom-btn {
    font-size: $headingSection;
    background-color: $savvyYellow;
    font-family: Avenir-DemiBold;
    color: $greyBlack !important;
    border: 1px solid $savvyYellow;
    padding: 18px 25px;
    border-radius: 8px;
    min-width: 325px;
  }
  .common-btn {
    width: 100%;
    text-align: center;
  }
  .redeem-screen {
    padding: 0px 40px 40px 40px;
    .upper-section {
      padding: 30px 0px 50px 0px;
      @include flexbox();
      @include justify-content(space-around);
      text-align: center;
    }
    .money-transferred {
      background-color: $greyWhiteTwo;
      border-radius: 8px;
      padding: 20px;
      margin-bottom: 25px;
      .heading {
        text-align: center;
        margin-bottom: 9px;
      }
      .line {
        width: 30%;
        height: 1px;
        background-color: $promptGrey;
        margin: 0px auto;
      }
      .transfer {
        @include justify-content(space-between);
        @include flexbox();
        padding-top: 10px;
      }
      .icon {
        padding: 0px 30px 0px 50px;
        @include justify-content(center);
        @include align-items(flex-end);
        @include flexbox();
      }
      .content-box {
        width: 50%;
        text-align: center;
        .number {
          width: 33px;
          height: 33px;
          border-radius: 50%;
          margin: 0px auto;
          background-color: $savvyYellow;
          font-size: $headingSection;
          color: $pureWhite;
          @include justify-content(center);
          @include align-items(center);
          @include flexbox();
          font-family: Avenir-DemiBold;
          margin-bottom: 10px;
        }
      }
    }
  }
  .redeem-otp-screen {
    padding: 20px 40px 100px 40px;

    .heading {
      text-align: center;
    }
    .input-group-box {
      @include flexbox();
      @include justify-content(center);
      padding: 80px 0px 0px 0px;
      .input-box {
        height: 64px;
        width: 47px;
        font-size: $headingSection;
        text-align: center;
        color: $greyBlack;
        border: unset;
        margin-right: 20px;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
        background-color: $greyWhite;
        outline: unset;
        border-radius: 4px;
      }
      .error-input-box {
        box-shadow: 0 4px 10px rgba(255, 81, 82, 0.4);
        background-color: rgba(255, 81, 82, 0.14);
      }
    }
    .error {
      text-align: center;
      padding-top: 20px;
      color: $errorRed;
    }
    .common-btn {
      padding-top: 80px;
    }
  }
  .redeem-success-screen {
    padding: 0px 40px 40px 40px;
    .upper-section {
      padding: 0px 0px 10px 0px;
      .okay-icon {
        padding-bottom: 30px;
      }

      text-align: center;
    }
    .money-transferred {
      background-color: $greyWhiteTwo;
      border-radius: 8px;
      padding: 20px;
      margin-bottom: 25px;
      .heading {
        text-align: center;
        margin-bottom: 9px;
      }
      .line {
        width: 30%;
        height: 1px;
        background-color: $promptGrey;
        margin: 0px auto;
      }
      .transfer {
        @include justify-content(space-between);
        @include flexbox();
        padding-top: 10px;
      }
      .icon {
        padding: 0px 30px 0px 50px;
        @include justify-content(center);
        @include align-items(flex-end);
        @include flexbox();
        img {
          width: 35px;
        }
      }
      .content-box {
        width: 50%;
        text-align: center;
        .number {
          width: 33px;
          height: 33px;
          border-radius: 50%;
          margin: 0px auto;
          background-color: $savvyYellow;
          font-size: $headingSection;
          color: $pureWhite;
          @include justify-content(center);
          @include align-items(center);
          @include flexbox();
          font-family: Avenir-DemiBold;
          margin-bottom: 10px;
        }
      }
    }
  }
}

@include screen-below('lg') {
  .modal-content {
    min-width: unset;
  }
}
