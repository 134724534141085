@import 'scss/variables';
@import 'scss/flex';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';

.inputContainer{
  margin-bottom: 1rem;
  display: flex;
  .labelContainer{
    max-width: 15%;
    display: flex;
    align-items: center;
    font-family: Avenir-Medium;
    &.variantOne{
      text-indent: -9999px;
      // font-size: 0.1px;
    }
  }
  .fieldContainer{
    max-width: 75%;
    &.variantOne{
      width: 30%;
    }
    .inputStyles{
      border-radius: 0.5rem;
    }
  }
}

.imageContainer{
  display: flex;
  width: 85%;
  align-items: center;
  justify-content: center;
  margin: 2rem 0;
  .labelContainer{
    width: 19rem;
    height: 12rem;
    border-radius: 2rem;
    background-color: $gray-medium;
    cursor: pointer;
    box-shadow: 0 0 0.7rem rgba(0,0,0,0.25);
    .label{
      // font-family: Avenir-Medium;
      font-size: 1.5rem;
      color: $gray-2;
    }
  }
}

.container{
  background-color: #fff;
  height: 100%;
  .headerStyles{
    background-color: $gray-medium;
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    .headingStyles{
      font-size: 1.5rem;
      font-family: Avenir-DemiBold;
    }
    .iconContainer{
      margin-left: auto;
      cursor: pointer;
    }
  }
  .formContainer{
    padding: 2rem;
    background-color: #fff;
  }
  .buttonContainer{
    display: flex;
    justify-content: center;
    margin-top: 3rem;
    .ctaTwoStyles{
      @include long-kyc-steps-button();
      color: $brown-dark;
    }
  }
}

@include screen-below('lg'){
  .inputContainer{
    margin-bottom: 1.2rem;
    .labelContainer{
      max-width: 100%;
      margin-bottom: 0.1rem;
    }
    .fieldContainer{
      max-width: 100%;
      &.variantOne{
        width: 100%;
      }
    }
  }
}