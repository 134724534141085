@import 'scss/variables';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';
@import 'scss/flex';

.savvy-pay-works-modal {
  .modal-dialog {
    max-width: 1170px;
    .modal-content {
      min-width: unset;
      width: 100%;
      border-radius: 12px;
      padding: 40px;
      .close-btn {
        position: absolute;
        right: 25px;
        top: 15px;
        span {
          cursor: pointer;
          img {
            width: 15px;
            opacity: 0.9;
          }
        }
      }
      .title {
        font-size: $headingSection;
        color: $offBlack;
        text-align: center;
        span {
          color: $savvyYellow;
          font-family: Avenir-DemiBold;
          font-size: $headingMain;
        }
        padding-bottom: 50px;
      }
      .custom-btn {
        font-size: $headingSection;
        background-color: $savvyYellow;
        font-family: Avenir-Medium;
        color: $offBlack !important;
        border: 1px solid $savvyYellow;
        padding: 10px 25px;
        border-radius: 8px;
        min-width: 300px;
        margin: 0px auto;
        margin-top: 55px;
        margin-bottom: 30px;
      }

      .cards-group {
        @include flexbox();
        @include justify-content(space-between);
        .card {
          width: 300px;
          border-radius: 30px;
          min-height: 363px;
          border-color: $pureWhite;
          box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
          text-align: center;
          .number {
            width: 41px;
            height: 41px;
            border-radius: 50%;
            background-color: $promptGrey;
            font-size: $headingMain;
            font-family: Avenir-DemiBold;
            @include flexbox();
            @include justify-content(center);
            @include align-items(center);
            color: $offBlack;
            margin: 0px auto;
            margin-top: -22px;
            margin-bottom: 25px;
          }
          .active-number {
            background-color: $savvyYellow;
          }
          .name {
            font-family: Avenir-DemiBold;
            font-size: $headingMain;
            margin-bottom: 30px;
            color: $greyBlack;
          }
          .icon {
            height: 120px;
            margin-bottom: 10px;
          }
          hr {
            background-color: $greyBlack;
            width: 80%;
            margin: 0px auto;
          }
          .description {
            padding-top: 20px;
            font-size: $textParagraphOne;
            color: $offBlack;
            padding: 15px 20px;
          }
        }
      }
    }
  }
  @include screen-below('lg') {
    .modal-dialog {
      max-width: 1170px;
      .modal-content {
        min-width: unset;
        width: 100%;
        border-radius: 12px;
        padding: 40px;
        .close-btn {
          position: absolute;
          right: 25px;
          top: 15px;
          span {
            cursor: pointer;
            img {
              width: 15px;
              opacity: 0.9;
            }
          }
        }
        .title {
          font-size: $headingSection;
          color: $offBlack;
          text-align: center;
          span {
            color: $savvyYellow;
            font-family: Avenir-DemiBold;
            font-size: $headingMain;
          }
          padding-bottom: 50px;
        }
        .custom-btn {
          font-size: $headingSection;
          background-color: $savvyYellow;
          font-family: Avenir-Medium;
          color: $offBlack !important;
          border: 1px solid $savvyYellow;
          padding: 10px 25px;
          border-radius: 8px;
          min-width: 300px;
          margin: 0px auto;
          margin-top: 55px;
          margin-bottom: 30px;
        }

        .cards-group {
          @include flexbox();
          @include justify-content(space-between);
          @include flex-direction(column);
          @include align-items(center);

          .card {
            width: 100%;
            border-radius: 30px;
            margin-bottom: 60px;
            min-height: 363px;
            border-color: $pureWhite;
            box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
            text-align: center;
            .number {
              width: 41px;
              height: 41px;
              border-radius: 50%;
              background-color: $promptGrey;
              font-size: $headingMain;
              font-family: Avenir-DemiBold;
              @include flexbox();
              @include justify-content(center);
              @include align-items(center);
              color: $offBlack;
              margin: 0px auto;
              margin-top: -22px;
              margin-bottom: 25px;
            }
            .active-number {
              background-color: $savvyYellow;
            }
            .name {
              font-family: Avenir-DemiBold;
              font-size: $headingMain;
              margin-bottom: 10px;
              color: $greyBlack;
            }
            .icon {
              height: 120px;
              margin-bottom: 10px;
            }
            hr {
              background-color: $greyBlack;
              width: 80%;
              margin: 0px auto;
            }
            .description {
              padding-top: 20px;
              font-size: $textParagraphOne;
              color: $offBlack;
              padding: 15px 20px;
            }
          }
        }
      }
    }
  }
}
