@import 'scss/variables';
@import 'scss/flex';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';

.container {
  @include flexbox();
  @include flex-direction(column);
  @include align-items(center);

  header {
    font-size: $textParagraphOne;
    margin-bottom: 0.4rem;
    color: $footerBlack;
    font-family: Avenir-Regular;
  }
  .videoContainer {
    height: 200px;
    width: 300px;

    .image {
      height: 200px;
      width: 300px;
      border-radius: 5px;
    }
    .video {
      border-radius: 5px;
    }
  }

  .buttonContainer {
    margin-top: 10px;
    z-index: 10;
    width: 300px;
    @include flexbox();
    @include justify-content(center);
    .button {
      width: fit-content;
      margin-right: 5px;
      font-size: 0.8rem;
      padding: 0.25rem 0.5rem;
      flex: 1;
      border: none;
      border-radius: 0.25rem;
      background-color: $disabled-medium;
      font-family: Avenir-Medium;
      white-space: pre;
      .icon {
        margin-right: 5px;
      }

      &.danger {
        background-color: $red-dark;
        color: $extra-light;
      }

      &.primary {
        background-color: $savvyYellow;
        color: $pureBlack;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
