@import 'scss/variables';
@import 'scss/flex';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';

@import 'scss/variables';
@import 'scss/flex';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';

$minWidth: 453px;

.pay-content {
  padding: 0px 40px 0px 40px;
  min-height: 300px;
  @include screen-below('lg') {
    padding: 0px 0px 0px 0px;
  }
}

.disabled-btn {
  background-color: #ffeb87 !important;
  cursor: not-allowed !important;
  color: #0000007a !important;
}

.header {
  @include flexbox();
  @include justify-content(center);
  padding: 15px 10px;
  .inner-header {
    @include flexbox();
    @include justify-content(flex-start);
    // max-width: $minWidth;
    width: 100%;
    .sub-inner-header {
      text-align: center;
      width: 100%;
      .main-title {
        font-family: Avenir-DemiBold;
        color: $pureBlack;
        font-size: $headingSection;
        margin-bottom: 8px !important;
        line-height: 28px;
        text-align: center;
      }
      .price {
        font-family: Avenir-DemiBold;
        color: $pureBlack;
        font-size: $headingSection;
      }
      .note {
        color: $themeBlack;
        font-size: $textFinePrint;
        text-transform: uppercase;
        font-family: Avenir-Medium;
        margin-bottom: 6px;
      }
      .amount {
        color: $themeBlack;
        font-size: $textFinePrint;
        font-family: Avenir-Medium;
        @include flexbox();
        @include justify-content(center);
        @include align-items(center);
        img {
          margin-top: -3px;
          margin-right: 3px;
        }
      }
    }
  }
}

.mobile-space {
  padding-left: 0px;
  padding-right: 0px;
  @include screen-below('lg') {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.mobile {
  display: none;
  @include screen-below('lg') {
    display: block;
  }
}

.line-mob {
  height: 4px;
  width: 24px;
  background-color: $savvyYellow;
  margin-bottom: 20px;
}
.progress-bar {
  height: 4px;
  width: 100%;
  background-color: $greyWhite;
}

.active-progress-bar {
  background-color: $savvyYellow;
  height: 4px;
}

.sip {
  @include flexbox();
  @include justify-content(center);
  @include flex-direction(column);
  @include align-items(center);
  padding-left: 20px;
  padding-right: 20px;

  padding-top: 60px;
  .pay-box {
    @include flexbox();
    .left-box {
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
      border-radius: 8px;
      height: fit-content;
      margin-top: 100px;
      padding-top: 30px;
      max-width: 810px;
      @include screen-up('xxl') {
        min-width: 700px;
      }
    }
    .pay-content {
      .title {
        font-size: $headingSection;
        color: $pureBlack;
        font-family: Avenir-DemiBold;
        margin-bottom: 10px;
        span {
          display: none;
        }
      }
      .line {
        height: 4px;
        width: 24px;
        background-color: $savvyYellow;
      }

      .sub-title {
        font-size: $headingSectionThree;
        font-family: Avenir-DemiBold;
        color: $footerBlack;
        padding-top: 20px;
      }
      .sub-title-light {
        font-size: $headingSectionThree;
        font-family: Avenir-Regular;
        color: $footerBlack;
        margin-bottom: 30px;
      }
    }
  }

  .extra-info {
    margin-top: 70px;
    @include flexbox();
    background: $greyWhiteThree;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    max-width: 952px;
    width: 100%;
    padding-bottom: 50px;
    margin-bottom: 30px;
    .extra-logo {
      padding: 14px 30px 14px 14px;
    }
    .extra-title {
      font-size: $headingSectionThree;
      color: $pureBlack;
      font-family: Avenir-Regular;
      opacity: 0.8;
      padding-top: 20px;
      padding-bottom: 10px;
    }
    .extra-desc {
      font-size: $headingSectionThree;
      color: $pureBlack;
      font-family: Avenir-Regular;
    }
  }

  @include screen-below('lg') {
    width: 100%;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    .pay-box {
      width: 100%;
      .left-box {
        padding-top: 0px;
        margin-top: 0px;
        width: 100%;
        box-shadow: unset;
        max-width: unset;
        border-radius: unset;
        .pay-content {
          .title {
            color: $blackTwo;
            font-size: $textFinePrint;
            text-transform: uppercase;
            padding: 25px 0px 15px 0px;
            font-family: Avenir-Medium;
            text-align: center;
            @include flexbox();
            @include justify-content(center);
            span {
              display: block;
            }
          }
          .footer-title {
            color: $blackTwo;
            font-size: $textFinePrint;
            text-transform: uppercase;
            padding: 10px 0px 15px 0px;
            font-family: Avenir-Medium;
            text-align: center;
          }
          .line {
            display: none;
          }
        }
      }
      .right-box {
        display: none;
        width: 100%;
      }
    }
    .extra-info {
      display: none;
    }
  }
}

.main-dropdown {
  button {
    width: 90px;
    height: 43px;
    background: $pureWhite;
    border: 2.37299px solid $savvyYellow;
    box-sizing: border-box;
    border-radius: 9.49196px;
  }
  .dropdown-menu {
    max-height: 200px;
    overflow-y: auto;
  }
}

.content-wrapper-box-desktop {
  display: block;

  .d-title {
    font-size: $headingMain;
    color: $pureBlack;
    font-family: Avenir-Medium;
    padding-top: 40px;
  }
  .d-sub-title {
    color: $pureBlack;
    font-family: Avenir-Medium;
    font-size: $textParagraphOne;
  }
  .d-note {
    color: $pureBlack;
    font-family: Avenir-Regular;
    padding: 10px 0px 25px 0px;
    font-size: $textParagraphOne;
  }
  .line {
    margin-bottom: 6px;
  }
  .d-footer-box {
    @include flexbox();
    padding-top: 10px;
    padding-bottom: 60px;
    .d-footer-left {
      padding-right: 30px;
    }
    .d-footer-price {
      color: $pureBlack;
      font-family: Avenir-Regular;
      font-size: $headingSection;
    }
    .d-footer-title {
      color: $pureBlack;
      font-family: Avenir-Regular;
      font-size: $textParagraphOne;
      opacity: 0.8;
      text-transform: uppercase;
    }
  }
  @include screen-below('lg') {
    display: none;
  }
}
.content-wrapper-box {
  @include screen-below('lg') {
    @include flexbox();
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 0px;
    padding-bottom: 0px;
    display: block;
  }
  display: none;
}

.content-wrapper {
  @include flexbox();
  padding: 0 1.5rem;
  margin: 0 auto 1rem;
  max-width: 365px;
  height: 65px;
  box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.09);
  border-radius: 10px;
  align-items: center;

  .left {
    flex: 1;
    text-align: left;
    font-size: $textParagraphOne;
    font-family: Avenir-Regular;
    color: $blackTwo;
    white-space: pre;
  }
  .right {
    flex: 1;
    text-align: right;
    color: $greyBlack;
    font-size: $textParagraphOne;
    font-family: Avenir-DemiBold;
  }

  .choose-date {
    border: 2px solid $disabled-medium;
    border-radius: 5px;
    padding: 0.5rem;
    margin: 0;
    color: $primary;
    outline: none !important;
  }

  @include screen-up('lg') {
    @include flex-direction(column-reverse);
    padding: 0 0rem;
    padding-top: 10px;
    height: 130px;
    width: 170px;
    .left {
      text-align: center;
      @include flexbox();
      padding-top: 2px;
      @include align-items(flex-start);
    }
    .right {
      text-align: center;
      @include flexbox();
      @include align-items(center);
      margin-bottom: -10px;
    }
  }
}

.footer {
  height: 70px;
  width: 100%;
  background-color: $savvyYellow;
  border-radius: 0px 0px 0px 8px;
  @include flexbox();
  @include align-items(center);
  @include justify-content(center);
  font-family: Avenir-DemiBold;
  font-size: $headingSection;
  color: $pureBlack;
  cursor: pointer;
  @include screen-below('lg') {
    text-transform: uppercase;
    position: fixed;
    bottom: 0px;
    left: 0px;
  }
}

.divider {
  height: 2px;
  width: 100%;
  max-width: 361px;
  background-color: $greyWhite;
  margin: 35px auto 20px auto;
  @include screen-up('lg') {
    display: none;
  }
}
.icici-note {
  font-family: Avenir-Regular;
  color: $footerBlack;
  font-size: $textFinePrint;
  max-width: 410px;
  padding: 0px 10px;
  margin: auto;
  text-align: center;
  br {
    display: none;
  }
  a {
    color: $greyBlack;
  }
  @include screen-up('lg') {
    max-width: 610px;
    padding-bottom: 10px;
    br {
      display: block;
    }
  }
}
.secure {
  padding: 30px 0px 80px 0px;
  color: $greyBlack;
  font-size: $textParagraphOne;
  font-family: Avenir-Regular;
  text-align: center;
  @include flexbox();
  @include align-items(center);
  @include justify-content(center);
  width: 100%;
  .secure-lock {
    margin-top: -2px;
  }
  span {
    padding: 0px 6px 0px 3px;
  }
  @include screen-up('lg') {
    display: none;
  }
}
