@import 'scss/variables';
@import 'scss/flex';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';

.agent {
  .email-input {
    margin-bottom: 10px;
  }
  .hidden-inputs {
    opacity: 0;
    height: 0px;
    overflow: hidden;
  }
  input {
    &::placeholder {
      color: $greyBlack !important;
      opacity: 0.3;
    }
  }
}
