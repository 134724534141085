@import 'scss/variables';
@import 'scss/_theme';
@import 'scss/flex';
@import 'scss/media_queries';

.jumbotron {
  background-size: cover;
  color: #fff;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30%;
  background: rgba(0, 0, 0, 0.7);
  .body {
    text-align: center;
  }
  // &:after {
  //   position: absolute;
  //   top: 0;
  //   bottom: 0;
  //   left: 0;
  //   right: 0;
  //   // background-color: rgba(0, 0, 0, 0.2);
  //   content: '';
  // }
  .title {
    font-size: $headingMain;
    font-family: 'Avenir-Bold';
    padding: 0px 20px;
    .savvyName {
      color: $savvyYellow;
    }
    .slogan {
      font-size: $textParagraphOne;
      font-family: 'Avenir-Regular';
      color: $pureWhite;
    }
  }
  .generic-title {
    font-family: 'Avenir-Medium';
    font-size: 32px;
    span {
      color: $savvy-yellow;
    }
  }
  .generic-desc {
    font-family: 'Avenir-Regular';
    font-size: 18px;
  }
  .leading {
    font-size: 1.3rem;
    font-family: Avenir-Light;
  }
}

.title {
  font-size: $headingMain;
  font-family: 'Avenir-Bold';
  padding: 0px 20px;
  .savvyName {
    color: $savvyYellow;
  }
  .slogan {
    font-size: $textParagraphOne;
    font-family: 'Avenir-Regular';
    color: $pureWhite;
  }
}
.generic-title {
  font-family: 'Avenir-Medium';
  font-size: 32px;
  span {
    color: $savvy-yellow;
  }
}
.generic-desc {
  font-family: 'Avenir-Regular';
  font-size: 18px;
}
.footer {
  z-index: 4;
  width: 100%;
  height: 25vh;
  bottom: 0px;
  background-color: $pureBlack;
  text-align: center;
  margin-top: -2px;

  @include flexbox();
  @include align-items(center);
  @include justify-content(center);
  padding-top: 25px;
  .title {
    color: $pureWhite;
  }
}

.strike {
  position: relative;
  display: inline-block;
  .value {
    position: absolute;
    top: -2.5rem;
    left: 0;
  }
  .strikeValue {
    text-decoration: line-through;
    text-decoration-color: $primary;
    text-decoration-thickness: 0.3rem;
  }
}

@include screen-below('lg') {
  .jumbotron {
    background-size: cover;
    color: #fff;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30%;
    background: rgba(0, 0, 0, 0.7);
    .body {
      text-align: center;
    }
    // &:after {
    //   position: absolute;
    //   top: 0;
    //   bottom: 0;
    //   left: 0;
    //   right: 0;
    //   // background-color: rgba(0, 0, 0, 0.2);
    //   content: '';
    // }
    .title {
      font-size: $headingMain;
      font-family: 'Avenir-Bold';
      padding: 0px 20px;
      .savvyName {
        color: $savvyYellow;
      }
      .slogan {
        font-size: $textParagraphOne;
        font-family: 'Avenir-Regular';
        color: $pureWhite;
      }
    }
    .generic-title {
      font-family: 'Avenir-Medium';
      font-size: 32px;
      span {
        color: $savvy-yellow;
      }
    }
    .generic-desc {
      font-family: 'Avenir-Regular';
      font-size: 18px;
    }
    .leading {
      font-size: 1.3rem;
      font-family: Avenir-Light;
    }
  }

  .title {
    font-size: $headingSectionTwo;
    font-family: 'Avenir-Bold';
    padding: 0px 20px;
    .savvyName {
      color: $savvyYellow;
    }
    .slogan {
      font-size: $textParagraphThree;
      font-family: 'Avenir-Regular';
      color: $pureWhite;
    }
  }
  .generic-title {
    font-family: 'Avenir-Medium';
    font-size: 32px;
    span {
      color: $savvy-yellow;
    }
  }
  .generic-desc {
    font-family: 'Avenir-Regular';
    font-size: 18px;
  }
  .footer {
    // position: absolute;
    z-index: 4;
    width: 100%;
    height: 200px;
    bottom: 0px;
    background-color: $pureBlack;
    text-align: center;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    .title {
      color: $pureWhite;
    }
  }

  .strike {
    position: relative;
    display: inline-block;
    .value {
      position: absolute;
      top: -2.5rem;
      left: 0;
    }
    .strikeValue {
      text-decoration: line-through;
      text-decoration-color: $primary;
      text-decoration-thickness: 0.3rem;
    }
  }
}

@include screen-below('sm') {
}
