@import 'scss/variables';
@import 'scss/flex';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';

.setup-pay-modal {
  background-color: rgba(255, 255, 255, 0.87);
  .modal-content {
    padding: 6% 10%;
    text-align: center;
  }
  .title {
    font-size: $headingMain;
    font-family: Avenir-DemiBold;
    color: $greyBlack;
    max-width: 667px;
    margin: 0px auto;
    line-height: 38px;
    padding-top: 20px;
  }
  .sub-title {
    color: $themeBlack;
    font-family: Avenir-Regular;
    font-size: $headingSectionThree;
    margin-bottom: 6px;
  }
  .sub-title-two {
    margin-top: 8px;
    margin-bottom: 4%;
  }
  .sub-title-three {
    padding-top: 20px;
  }
  .setup-btn {
    padding-top: 7%;
    button {
      font-size: $headingSection;
      min-width: 310px;
    }
  }
}
