.container{
  display: flex;
  align-items: center;
  padding: 1.5rem 0.3rem;
  box-shadow: 0 0 10px rgba(0,0,0,0.2);
  border-radius: 1rem;
  .label{

  }
  .rightContent{
    margin-left: auto;
  }
}