@import 'scss/variables';
@import 'scss/theme';

.listItemContainer {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: $pureWhite;
  font-family: Avenir-Regular;
  padding: 10px 30px 10px 50px;
  &:hover {
    color: $savvyYellow;
  }
  .icon {
    margin-left: auto;
  }
  &.active {
    color: $savvyYellow;
  }
}

.linkItem {
  display: flex;
  align-items: center;
  text-decoration: none;
  // font-size: $headingSectionThree;
  color: $pureWhite;
  font-family: Avenir-Regular;
  // font-weight: 600;
  text-decoration: none;
  padding: 10px 30px 10px 50px;

  &.active {
    color: $savvyYellow;
  }
  &:hover {
    color: $savvyYellow;
  }
}
.first-list-anchor {
  padding: 35px 30px 10px 50px !important;
}

.link-border {
  border-bottom: 1px solid rgba(243, 243, 243, 0.6);
}

.childMenu {
  list-style-type: none;
  padding-left: 0rem;
  .linkItem {
    font-family: Avenir-Regular;
    // font-size: $headingSectionThree;
    padding: 5px 30px 5px 70px;
  }
}
