@import 'scss/functions'; 
@import 'scss/theme'; 
@import 'scss/media_queries'; 

.rowOne{
  width: 80%;
  margin-bottom: toRem(20);
  &:last-child{
    margin-bottom: 0;
  }
}
.checkboxContainer{
  display: flex;
  flex-flow: row;
  margin: toRem(10) 0;
}
.infoBox{
  margin-bottom: toRem(10);
}
.buttonContainer{
  text-align: center;
  margin: toRem(15) 0;
  button{
    padding: 0 toRem(15);
  }
}
.stackedFields{
  display: flex;
  >div{
    margin-right: toRem(30);
    &:last-child{
      margin-right: 0;
    }
  }
}
.removeField{
  display: flex;
  align-items: flex-end;
  padding-bottom: toRem(15);
  span{
    color: $errorRed;
  }
}
.rowLink{
  display: inline-block;
  margin-top: 1rem;
}
.tooltip{
  position: relative;
  top: toRem(3.6);
  left: toRem(3);
  color: #c7c6c3;
}

@include screen-below('lg'){
  .rowOne{
    width: auto;
    div{
      margin-bottom: toRem(10);
    }
  }
}

