@import 'scss/media_queries';
@import 'scss/variables';
@import 'scss/_theme';
@import 'scss/_flex';

.modal-content {
  min-height: 554px !important;
}

.modal-lg {
  max-width: 894px !important;
}

.cancel-booking-modal {
  .title {
    font-size: $headingMain;
    color: $greyBlack;
    line-height: 38px;
    font-family: Avenir-Regular;
    text-align: center;
    margin-bottom: 5px;
  }

  .amount {
    font-size: $headingMain;
    color: $greyBlack;
    line-height: 38px;
    font-family: Avenir-DemiBold;
    text-align: center;
  }

  .text-input {
    max-width: 420px;
    text-align: center;
    width: 100%;
    height: 64px;
    background-color: $greyWhite;
    outline: unset;
    border: unset;
    font-size: $textParagraphOne;
    padding: 0px 20px;
    border-radius: 4px;
  }

  .custom-btn {
    font-size: $headingSection;
    background-color: $savvyYellow;
    font-family: Avenir-DemiBold;
    color: black !important;
    border: 1px solid $savvyYellow;
    padding: 5px 25px;
    border-radius: 8px;
    min-width: 325px;
  }

  .cross-icon {
    @include flexbox();
    @include justify-content(flex-end);
    padding: 20px 20px 0px 0px;
    span {
      cursor: pointer;
    }
    img {
      opacity: 0.7;
      width: 20px;
    }
    margin-bottom: 15px;
  }

  .report {
    text-align: center;
    margin-bottom: 20px;
  }

  .title {
    font-size: $headingMain;
    color: $greyBlack;
    line-height: 38px;
    font-family: Avenir-DemiBold;
    text-align: center;
    margin-bottom: 40px;
  }

  .description {
    font-size: $headingMain;
    color: $greyBlack;
    line-height: 38px;
    font-family: Avenir-Regular;
    text-align: center;
    margin-bottom: 120px;
  }
  .input-box {
    @include flexbox();
    @include flex-direction(column);
    @include justify-content(center);
    @include align-items(center);
    .error {
      padding-top: 10px;
      color: $errorRed;
    }
  }

  .custom-btn-group {
    @include flexbox();
    @include justify-content(space-between);
    padding: 0 2rem 2rem;
    .custom-btn {
      font-size: $headingSection;
      background-color: $savvyYellow;
      font-family: Avenir-DemiBold;
      color: $offBlack !important;
      border: 1px solid $savvyYellow;
      padding: 10px 25px;
      border-radius: 8px;
      min-width: 250px;
    }
  }

  .withdraw-saving {
    .amount {
      padding-bottom: 35px !important;
    }

    .title {
      font-size: $headingMain;
      color: $greyBlack;
      line-height: 38px;
      font-family: Avenir-Regular;
      text-align: center;
      margin-bottom: 35px;
      padding: 0px 30px;
    }

    .amount {
      font-size: $headingMain;
      color: $greyBlack;
      line-height: 38px;
      font-family: Avenir-DemiBold;
      text-align: center;
      margin-bottom: 20px;
    }

    .horizontal-line-box {
      padding: 35px 50px;
      .horizontal-line {
        height: 1px;
        width: 100%;
        background-color: #e2e2e2;
      }
    }
    .transfer {
      @include flexbox();
      @include justify-content(space-between);
      @include align-items(center);
      padding: 35px 60px 30px 60px;
      .transfer-box {
        background-color: $greyWhite;
        border-radius: 4px;
        text-align: center;
        padding: 15px 30px;
        min-width: 280px;
        .label {
          font-family: Avenir-Regular;
          font-size: $textParagraphTwo;
        }
        .text {
          font-family: Avenir-Medium;
          font-size: $textParagraphTwo;
        }
        .text-hdfc {
          text-decoration: underline;
        }
      }
    }
    .custom-btn-group {
      @include flexbox();
      @include justify-content(center);
      padding: 30px 0px 30px 0px;
    }
  }

  .content {
    padding-bottom: 50px;
  }

  .confirm-withdraw {
    .amount {
      padding-bottom: 100px !important;
    }
    .input-box {
      input {
        text-align: center;
      }
    }
    .title {
      font-size: $headingMain;
      color: $greyBlack;
      line-height: 38px;
      font-family: Avenir-Regular;
      text-align: center;
      margin-bottom: 35px;
      padding: 0px 30px;
    }

    .resend-otp {
      color: $greyBlack;
      text-decoration: underline;
      font-size: $textParagraphOne;
      margin-top: 6px;
      margin-bottom: 100px;
      cursor: pointer;
      background: transparent;
      border: 0;
    }
    .confirm-btn {
      @include flexbox();
      @include justify-content(center);
      padding: 30px 0px 30px 0px;
      .custom-btn {
        margin-right: 0px;
      }
    }
  }

  .withdrawal-successfull {
    .tick {
      text-align: center;
      padding-bottom: 22px;
    }

    .title {
      font-size: $headingMain;
      color: $greyBlack;
      line-height: 38px;
      font-family: Avenir-Regular;
      text-align: center;
      margin-bottom: 35px;
      padding: 0px 30px;
    }

    .done-btn {
      @include flexbox();
      @include justify-content(center);
      padding: 0px 0px 80px 0px;
      .custom-btn {
        margin-right: 0px;
      }
    }
    .amount {
      margin-top: 80px;
      margin-bottom: 10px;
    }
    .description {
      color: $greyBlack;
      font-family: Avenir-Regular;
      font-size: $headingSection;
      text-align: center;
      padding-bottom: 0px;
    }
  }

  .done {
    .description-one {
      margin-bottom: 30px;
      padding-top: 20px;

      strong {
        font-family: Avenir-Medium;
      }
    }
    .title {
      margin-bottom: 70px;
    }
    .description-two {
      margin-bottom: 50px;
    }
    .custom-btn-group {
      @include justify-content(center);
      padding-bottom: 35px;
    }
  }
}

@include screen-below('lg') {
  .modal-content {
    min-width: unset;
  }
  .cancel-booking-modal {
    .withdraw-saving {
      .amount {
        padding-bottom: 35px !important;
      }

      .title {
        font-size: $headingMain;
        color: $greyBlack;
        line-height: 38px;
        font-family: Avenir-Regular;
        text-align: center;
        margin-bottom: 35px;
        padding: 0px 30px;
      }

      .amount {
        font-size: $headingMain;
        color: $greyBlack;
        line-height: 38px;
        font-family: Avenir-DemiBold;
        text-align: center;
        margin-bottom: 20px;
      }

      .horizontal-line-box {
        padding: 35px 50px;
        .horizontal-line {
          height: 1px;
          width: 100%;
          background-color: #e2e2e2;
        }
      }
      .transfer {
        @include flexbox();
        @include justify-content(space-between);
        @include align-items(center);
        padding: 35px 60px 30px 60px;
        .transfer-box {
          background-color: $greyWhite;
          border-radius: 4px;
          text-align: center;
          padding: 15px 30px;
          min-width: 280px;
          .label {
            font-family: Avenir-Regular;
            font-size: $textParagraphTwo;
          }
          .text {
            font-family: Avenir-Medium;
            font-size: $textParagraphTwo;
          }
          .text-hdfc {
            text-decoration: underline;
          }
        }
      }
      .custom-btn-group {
        @include flexbox();
        @include justify-content(center);
        @include flex-direction(column);
        padding: 30px 0px 30px 0px;
      }
    }

    .content {
      padding-bottom: 50px;

      .custom-btn-group {
        @include flexbox();
        @include justify-content(center);
        @include flex-direction(column);
        padding: 30px;
        .custom-btn {
          margin-bottom: 20px;
        }
      }
    }

    .confirm-withdraw {
      .amount {
        padding-bottom: 100px !important;
      }
      .input-box {
        input {
          text-align: center;
        }
      }
      .title {
        font-size: $headingMain;
        color: $greyBlack;
        line-height: 38px;
        font-family: Avenir-Regular;
        text-align: center;
        margin-bottom: 35px;
        padding: 0px 30px;
      }

      .resend-otp {
        color: $greyBlack;
        text-decoration: underline;
        font-size: $textParagraphOne;
        margin-top: 6px;
        margin-bottom: 100px;
        cursor: pointer;
        background: transparent;
        border: 0;
      }
      .confirm-btn {
        @include flexbox();
        @include justify-content(center);
        padding: 30px 0px 30px 0px;
        .custom-btn {
          margin-right: 0px;
        }
      }
    }

    .withdrawal-successfull {
      .tick {
        text-align: center;
        padding-bottom: 22px;
      }

      .title {
        font-size: $headingMain;
        color: $greyBlack;
        line-height: 38px;
        font-family: Avenir-Regular;
        text-align: center;
        margin-bottom: 35px;
        padding: 0px 30px;
      }

      .done-btn {
        @include flexbox();
        @include justify-content(center);
        padding: 0px 0px 80px 0px;
        .custom-btn {
          margin-right: 0px;
        }
      }
      .amount {
        margin-top: 80px;
        margin-bottom: 10px;
      }
      .description {
        color: $greyBlack;
        font-family: Avenir-Regular;
        font-size: $headingSection;
        text-align: center;
        padding-bottom: 0px;
      }
    }

    .done {
      .description-one {
        margin-bottom: 30px;
        padding-top: 20px;
        line-height: 35px;
        font-size: $headingSection;
        strong {
          font-family: Avenir-Medium;
        }
      }
      .title {
        margin-bottom: 70px;
      }
      .description-two {
        font-size: $headingSection;
        margin-bottom: 50px;
        line-height: 35px;
      }
      .custom-btn-group {
        @include justify-content(center);
        padding-bottom: 35px;
      }
    }
  }
}
