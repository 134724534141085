@import 'scss/media_queries';
@import 'scss/variables';
@import 'scss/_theme';
@import 'scss/_flex';

.header {
  margin: 1rem 0;
  padding-left: 2rem;
  .heading {
    font-family: Avenir-Medium;
    font-size: 1.2rem;
  }
}
.helpText {
  font-size: 0.8rem;
  text-align: right;
}

.back-btn {
  color: $pureWhite;
  background: $savvyYellow;
  border-radius: 5px;
  font-size: 15px;
  outline: unset;
  box-shadow: none;
  border: none;
  padding: 2px 15px 0px 15px;
  margin-left: 30px;
}

.goal-header {
  @include flexbox();
  @include justify-content(space-between);
  background-color: $gray-medium;
  padding: 10px 2rem;
  font-family: Avenir-DemiBold;
  font-size: $h2;
  color: black;
  margin-bottom: 40px;
}

.header-tab {
  @include flexbox();
  @include justify-content(space-between);
  background-color: $gray-medium;
  padding: 10px 2rem;
  font-family: Avenir-DemiBold;
  font-size: $h2;
  color: rgba(0, 0, 0, 0.7);
}

.content-box {
  @include flexbox();
  overflow-x: hidden;
  &:hover {
    overflow-x: auto;
  }
  .goal-progress {
    cursor: pointer;
    text-align: center;
    padding: 1.3rem 2rem 3rem 2rem;
    min-width: 380px;
    // margin-bottom: 1rem;

    .goal-image-container {
      width: 100px;
      margin: 0 auto 1rem;

      .goal-image {
        width: 90%;
        height: 90%;
        border-radius: 50%;
        background-position: center;
        background-size: cover;
      }
    }
    .goal-name {
      font-family: Avenir-Regular;
      font-size: $h2;
      color: rgba(0, 0, 0, 0.7);
    }
    .amount-saved {
      font-family: Avenir-Regular;
      font-size: $h4;
      color: rgba(0, 0, 0, 0.7);
      margin-bottom: 0;
    }
    .total-goal-amount {
      font-family: Avenir-Regular;
      font-size: $h4;
      color: rgba(0, 0, 0, 0.7);
      margin-bottom: 0;
    }
    .total-goal-amount-yellow {
      font-family: Avenir-DemiBold;
      color: #eda12c;
    }

    .offer-title {
      font-family: Avenir-DemiBold;
      font-size: $h2;
      color: rgba(0, 0, 0, 0.7);
      margin-bottom: 2px;
    }

    .your-return {
      font-family: Avenir-DemiBold;
      font-size: $h4;
      color: $success-light;
    }
    &.overview {
      background-color: $greyWhite;
      border-radius: 8px;
      .title {
        font-family: Avenir-DemiBold;
        font-size: $h2;
        color: rgba(0, 0, 0, 0.7);
        padding: 25px 0px 10px 0px;
      }
      .overview-inner-box {
        border-radius: 8px;
        background-color: $pureWhite;
        padding-bottom: 5px;
      }
    }
  }
}
