@import 'scss/variables';
@import 'scss/flex';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';

.setup-pay-modal {
  background-color: rgba(255, 255, 255, 0.87);
  .modal-content {
    padding: 10% 10%;
    text-align: center;
  }
  .title {
    font-size: $headingMain;
    font-family: Avenir-DemiBold;
    color: $greyBlack;
    max-width: 667px;
    margin: 0px auto;
    line-height: 38px;
    margin-bottom: 8%;
  }
  .sub-title {
    color: $greyBlack;
    font-family: Avenir-Regular;
    font-size: $headingSection;
  }
  .setup-btn {
    padding-top: 8%;
    button {
      font-size: $headingSection;
    }
  }
}
