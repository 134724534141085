@import 'scss/variables';
@import 'scss/_theme';
@import 'scss/media_queries';
@import 'scss/_flex';

.goal-progress {
  text-align: center;
  padding: 1.3rem 2rem;
  // margin-bottom: 1rem;

  .payment-box {
    @include flexbox();
    @include justify-content(center);
    @include align-items(center);
    border: 1px solid #c2c2c2;
    box-sizing: border-box;
    border-radius: 8px;
    width: fit-content;
    margin: 0px auto;
    padding: 10px 20px;
  }
  .vertical-line {
    width: 1px;
    height: 31px;
    margin: 0px 20px;
    background-color: $pureBlack;
  }
  .goal-image-container {
    width: 100px;
    margin: 0 auto 1rem;

    .goal-image {
      width: 90%;
      height: 90%;
      border-radius: 50%;
      background-position: center;
      background-size: cover;
    }
  }
  .goal-name {
    font-family: Avenir-Regular;
    font-size: $h2;
    color: rgba(0, 0, 0, 0.7);
  }
  .amount-saved {
    font-family: Avenir-Regular;
    font-size: $h4;
    color: rgba(0, 0, 0, 0.7);
    margin-bottom: 0;
  }
  .total-goal-amount {
    font-family: Avenir-Regular;
    font-size: $h4;
    color: rgba(0, 0, 0, 0.7);
    margin-bottom: 0;
  }
  .total-goal-amount-yellow {
    font-family: Avenir-DemiBold;
    color: #eda12c;
  }
  .pending-amount {
    font-family: Avenir-DemiBold;
    font-size: $h4;
    color: $primary;
    margin-bottom: 0px;
  }
  .no-of-offer {
    font-family: Avenir-DemiBold;
    font-size: $h2;
    color: rgba(0, 0, 0, 0.7);
    margin-bottom: 2px;
  }

  .your-return {
    font-family: Avenir-DemiBold;
    font-size: $h4;
    color: $success-light;
  }
  &.overview {
    background-color: $greyWhite;
    border-radius: 8px;
    height: 100%;
    .title {
      font-family: Avenir-DemiBold;
      font-size: $h2;
      color: rgba(0, 0, 0, 0.7);
      padding: 25px 0px 10px 0px;
    }
    .overview-inner-box {
      height: 100%;
      border-radius: 8px;
      background-color: $pureWhite;
      padding-bottom: 5px;
    }
  }
}

@include screen-below('lg') {
  .goal-progress {
    padding: 2rem 1rem;
  }
}
