@import 'scss/variables';

.inputLabelContainer {
  margin-bottom: 0.2rem;
  .inputLabel {
    font-family: Avenir-Medium;
    font-size: $inputLabel;
  }
}
.selectContainer{
  .select{
    border: none;
    border-radius: 0;
    border-bottom: 1px solid rgb(114, 114, 114);
  }
}
.error {
  font-size: 0.8rem;
  color: #c74b4b;
  margin-top: 0.1rem;
}
