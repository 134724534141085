@import 'scss/variables';

.container {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.imageContainer {
  margin: 1rem;
  padding: 1rem;
  border: 1px solid #f9f9f9;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 1rem #d1d1d1;
  position: relative;
  width: 9rem;
  img {
    max-width: 5rem;
  }
  .icon {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    cursor: pointer;
  }
}
.imageUploading {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-left: 20px;
}
