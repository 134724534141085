@import 'scss/variables';
@import 'scss/flex';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';

.bank-account-input {
  padding-top: 40px;
  .ba-group-input {
    label {
      font-family: Avenir-DemiBold;
      font-size: 16px;
      line-height: 21px;
      margin-bottom: 10px;
      color: rgba(0, 0, 0, 0.7);
    }
    .ba-input {
      @include border-radius(8px);
      max-width: 563px;
      width: 100%;
      height: 48px;
      font-size: 15px;
      font-family: Avenir-Medium;
      border: 1px solid #ccc;
      outline: none;
      color: #333;
      background-color: transparent;
      padding: 0px 15px;
      &:disabled {
        background-color: $disabled-light;
      }

      &:focus {
        border: 1px solid $primary;
        box-shadow: 0 0 2px $primary;
      }
      margin-bottom: 5px;
    }
  }
  .link-account {
    font-size: 0.9rem;
    display: inline-block;
    color: $dark;
    background-color: transparent;
    border: none;
    text-decoration: underline;
    cursor: pointer;
    margin-bottom: 60px;
  }

  @include screen-below('lg') {
    padding: 0 1rem;

    .ba-group-input {
      label {
        font-family: Avenir-DemiBold;
        font-size: 16px;
        line-height: 21px;
        margin-bottom: 6px;
        color: rgba(0, 0, 0, 0.7);
      }
      .ba-input {
        @include border-radius(8px);
        max-width: 563px;
        width: 100%;
        height: 48px;
        font-size: 15px;
        font-family: Avenir-Medium;
        border: 1px solid #ccc;
        outline: none;
        color: #333;
        background-color: transparent;
        padding: 0px 15px;
        &:disabled {
          background-color: $disabled-light;
        }

        &:focus {
          border: 1px solid $primary;
          box-shadow: 0 0 2px $primary;
        }
      }
      margin-bottom: 20px;
    }

    .link-account {
      width: 100%;
    }
  }
}
