@import 'scss/variables';
@import 'scss/flex';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';

$minWidth: 453px;

.title-heading {
  @include flexbox();
  @include justify-content(space-between);
  @include align-items(center);
}

.pay-content {
  padding: 30px 80px 0px 40px;
  min-height: 440px;
  @include screen-below('lg') {
    padding: 0px 0px 0px 0px;
  }
}

.radio-btns {
  @include flexbox();
  padding-left: 0px;
  ul {
    list-style: none;
  }
  li {
    margin-right: 20px;
    list-style: none;
    input {
      margin-right: 10px;
      transform: scale(1.5);
    }
  }
}
.header {
  @include flexbox();
  @include justify-content(center);
  padding: 15px 10px;
  .inner-header {
    @include flexbox();
    @include justify-content(flex-start);
    max-width: $minWidth;
    width: 100%;
    .sub-inner-header {
      text-align: center;
      width: 100%;
      .title {
        font-family: Avenir-DemiBold;
        color: $pureBlack;
        font-size: $headingSection;
        line-height: 28px;
      }
      .price {
        font-family: Avenir-DemiBold;
        color: $pureBlack;
        font-size: $headingSection;
      }
      .note {
        color: $themeBlack;
        font-size: $textFinePrint;
        text-transform: uppercase;
        font-family: Avenir-Medium;
        margin-bottom: 6px;
      }
      .amount {
        color: $themeBlack;
        font-size: $textFinePrint;
        font-family: Avenir-Medium;
        @include flexbox();
        @include justify-content(center);
        @include align-items(center);
        img {
          margin-top: -3px;
          margin-right: 3px;
        }
      }
    }
  }
}

.mobile-space {
  padding-left: 0px;
  padding-right: 0px;
  @include screen-below('lg') {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.mobile {
  display: none;
  @include screen-below('lg') {
    display: block;
  }
}

.line-mob {
  height: 4px;
  width: 24px;
  background-color: $savvyYellow;
  margin-bottom: 20px;
}
.progress-bar {
  height: 4px;
  width: 100%;
  background-color: $greyWhite;
}

.active-progress-bar {
  background-color: $savvyYellow;
  height: 4px;
}

.demographic {
  @include flexbox();
  @include flex-direction(column);
  height: 100%;
  @include justify-content(space-between);
  .title {
    font-size: $headingSection;
    color: $pureBlack;
    font-family: Avenir-DemiBold;
    margin-bottom: 0px;
  }
  .line {
    height: 4px;
    width: 24px;
    background-color: $savvyYellow;
  }
  .sub-title {
    font-size: $headingSectionThree;
    font-family: Avenir-DemiBold;
    color: $footerBlack;
    text-transform: uppercase;
    padding-top: 10px;
  }
  .sub-title-light {
    font-size: $headingSectionThree;
    font-family: Avenir-Regular;
    color: $footerBlack;
    margin-bottom: 5px;
  }

  .form-box {
    label {
      font-size: $headingSectionThree;
      font-family: Avenir-Regular;
      color: $footerBlack;
      opacity: 0.9;
      margin-bottom: 6px;
    }
    .input-box {
      @include border-radius(8px);
      width: 100%;
      height: 35px;
      font-size: 15px;
      font-family: Avenir-Medium;
      border: 1px solid $disabled-medium;
      outline: none;
      color: $footerBlack;
      background-color: transparent;
      padding: 0px 15px;
      &:disabled {
        background-color: $disabled-light;
      }

      &:focus {
        border: 1px solid $primary;
        box-shadow: 0 0 2px $primary;
      }
      margin-bottom: 5px;
    }
  }

  .married-form-box {
    padding-top: 4px;
  }

  .custom-dropdown {
    width: 100%;
    margin-bottom: 14px;

    button {
      min-width: 100%;
      width: 100%;
      position: relative;
      border: 1px solid $disabled-medium;
      border-radius: 8px !important;
      color: $pureBlack !important;
      background-color: $pureWhite !important;
      padding: 4px 17px;
      @include border-radius(4px);
      height: 35px;
      text-align: left;
      &:focus {
        background-color: $pureWhite !important;
        box-shadow: 0 0 0 2px $savvyYellow !important;
        border: unset !important;
      }
      &:active {
        box-shadow: 0 0 0 2px $savvyYellow !important;
        border: unset !important;
        background-color: $pureWhite !important;
      }
      &::after {
        position: absolute !important;
        right: 20px;
        top: 15px;
      }
    }

    .dropdown-menu {
      width: 100%;
    }
  }

  .form-box-space {
    margin-top: -10px;
  }

  .checkbox {
    font-size: $headingSectionThree;
    font-family: Avenir-Regular;
    color: $footerBlack;
    @include screen-below('lg') {
      font-size: $textParagraphOne;
      line-height: 19px;
    }
  }

  @include screen-below('lg') {
    position: relative;
    .form-box {
      label {
        font-size: $textParagraphOne;
      }
    }
    .link {
      padding-top: 93px;
    }
    .pay-content {
      // height: 100vh;
      .title {
        display: none;
      }
      .title-heading {
        display: block;
        padding: 20px 20px 0px 20px;
      }
      .line {
        display: none;
      }
      .sub-title {
        font-size: $textParagraphOne;
        color: $footerBlack;
        padding: 30px 20px 4px 20px;
      }
      .sub-title-light {
        font-size: $textParagraphOne;
        color: $footerBlack;
        padding: 0px 20px 0px 20px;
        line-height: 20px;
        margin-bottom: 5px;
      }
    }
  }
}

.footer-button {
  height: 70px;
  width: 100%;
  background-color: $savvyYellow;
  border-radius: 0px 0px 0px 8px;
  border: unset;
  color: $pureBlack;
  font-size: $headingSection;
  @include flexbox();
  @include justify-content(center);
  @include align-items(center);
  font-family: Avenir-DemiBold;
  cursor: pointer;
  @include screen-below('lg') {
    border-radius: 0px 0px 0px 0px;
    border: none;
  }
  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
}
