@import 'scss/variables';
@import 'scss/theme';

.navbarContainer {
  z-index: 2;
  position: relative;
}

.verticalLine {
  height: 58px !important;
  width: 1px;
  background-color: $borderBlack;
  position: relative;
  margin-left: 1.5rem;
}
.dropdown {
  .cta {
    border-radius: 0;
    color: $greyBlack;

    // margin-left: auto;
    background-color: $savvyYellow;
    width: 8rem;
    border: 0;
    // padding: 0.45rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: $fontFamilySemiBold;
    height: 58px;
    width: 180px;

    .text {
      margin-left: 0.3rem;
      font-size: $headingSectionTwo;
      font-family: Avenir-Medium;
      color: $pureWhite;
    }
    &:after {
      display: none;
    }
  }
  .linkItem {
    text-decoration: none;
    color: $dark-xt;

    &.active {
      color: $savvyYellow;
      text-decoration: none;
    }
  }
  .dropdownMenu {
    right: 0;
    font-size: 0.825rem;
    width: 100%;
    .item {
      font-size: $textParagraphTwo;
      &:active,
      &.active {
        background-color: $savvyYellow;
        .linkItem {
          color: $greyBlack;
        }
      }
    }
  }
  &:hover {
    color: #fff;
  }
}
.linkItem {
  text-decoration: none;
  width: 100%;
  display: inline-block;
}
.sidebarToggle {
  margin-left: 0.8rem;
}
.searchbar {
  // margin-left: auto;
  .input {
    &.visible {
      transition: all 0.25s ease-in;
      width: 50rem;
    }
  }
}
.navbar {
  height: 58px;
  border-bottom: 1px solid $borderBlack;
  background-color: $pureWhite;

  .navRight {
    margin-left: auto;
    display: flex;
    align-items: center;

    .icon {
      color: $promptGrey;
      margin: 0 1rem;
    }
  }
}
