@import 'scss/media_queries.scss';
@import 'scss/variables.scss';

.selfieContentContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 0;
  position: relative;
  
  .camera{
    width: 60%;    
    text-align: center;

    .image {
      height: 200px;
      width: 300px;
      background-size: contain;
      display: inline-block;
      border-radius: 5px;
    }
    .video {
      border-radius: 5px;
    }
  }
  .buttonContainer{
    width: 30%;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  
  .button {
    background-color: $primary;
    color: $extra-light;
    border-radius: 5px;
    border: none;
    padding: 0.25rem 1rem;
    margin-right: 5px;

    &:last-child {
      margin-right: 0;
    }

    &.danger {
      background-color: $red-dark;
    }
    .icon {
      margin-right: 5px;
    }
  }
  .divider{
    text-align: center;
    font-size: 2rem;
    margin: 0.6rem 0;
    font-family: Avenir-Medium;
  }
}

@include screen-below('lg'){
  .selfieContentContainer{
    align-items: unset;
    .camera{
      width: auto;
      text-align: center;
    }
    .buttonContainer{
      width: auto;
    }
  }
}