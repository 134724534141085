@import 'scss/variables';
@import 'scss/flex';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';

.cb-card {
  background-color: #f3f3f3;
  width: 500px;
  @include border-radius(4px);
  padding: 20px 13px 10px 13px;
  margin-bottom: 60px;
  position: relative;

  .cb-card-content {
    .cb-card-content-row {
      @include flexbox();
      margin-bottom: 10px;
      .cb-card-key {
        font-size: 16px;
        margin-right: 5px;
      }
      .cb-card-value {
        font-size: 16px;
        font-family: Avenir-DemiBold;
      }
    }
  }

  .cb-guard-icon-img {
    display: block;
    position: absolute;
    right: 15px;
    bottom: 15px;
  }
  .cb-guard-icon-mobile-img {
    display: none;
  }

  @include screen-below('lg') {
    width: 100%;
    margin: 0px auto;
    margin-bottom: 0px;

    .cb-card-content {
      .cb-card-content-row {
        @include flexbox();
        margin-bottom: 10px;
        .cb-card-key {
          font-size: 16px;
          margin-right: 5px;
        }
        .cb-card-value {
          font-size: 16px;
          font-family: Avenir-DemiBold;
        }
      }
    }

    .cb-guard-icon-img {
      display: none;
    }
    .cb-guard-icon-mobile-img {
      display: block;
      position: absolute;
      right: 10px;
      bottom: 10px;
    }
  }
}

.link-account {
  font-size: 16px;
  text-align: center;
  width: 100%;
  display: inline-block;
  background-color: transparent;
  border: none;
  text-decoration: underline;
  cursor: pointer;
  font-family: Avenir-DemiBold;
  color: $savvy-yellow;
  margin-top: 10px;
  margin-bottom: 100px;
  display: none;

  @include screen-below('lg') {
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 100px;
    display: block;
  }
}
