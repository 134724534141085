@import 'scss/variables';

.container {
  display: flex;
  align-items: center;
  margin-top: 0.7rem;
  .input {
    font-size: 2rem;
    input {
      cursor: pointer;
      margin-top: 0;
    }
  }
}
