@import 'scss/media_queries';
@import 'scss/variables';
@import 'scss/theme';

.form-check-input {
  &:checked {
    background-color: $successGreen !important;
    border-color: $successGreen !important;
    // border-color: $successGreen !important;
    box-shadow: 0 0 0 0.25rem rgba($successGreen, 0.25) !important;
  }
}
