@import 'scss/variables';
@import 'scss/flex';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';

.custom-modal-title {
  font-size: 30px;
  font-family: Avenir-DemiBold;
  text-align: center;
  padding-top: 70px;
  padding-bottom: 50px;
}

.custom-modal-footer {
  @include flexbox();
  @include justify-content(center);
  padding-bottom: 30px;

  .modal-btn {
    font-family: Avenir-DemiBold !important;
    color: rgba(0, 0, 0, 0.7) !important;
    font-size: $h2 !important;
    width: 297px !important;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    @include border-radius(10px !important);
    margin: 0px 10px !important;
  }
}

.alert-icon {
  padding-top: 30px;
  padding-bottom: 0px;
  text-align: center;
}
.custom-success-modal-title {
  font-size: 30px;
  font-family: Avenir-DemiBold;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 50px;
}
