@import 'scss/variables';
@import 'scss/flex';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';

// .container{
//     margin-top: 2rem;
.generate-new-offer {
  margin-top: -18px;
  padding-bottom: 10px;
  padding-left: 15px;
}
.expire-offer {
  text-align: center;
  padding: 40px 0px 10px 0px;
}
.mainImage {
  text-align: center;
  img {
    width: 100%;
    max-height: 180px;
    height: 100%;
    object-fit: contain;
    @include border-radius(4px);
  }
}
.innerImageBox {
  width: 100%;
  min-height: 180px;
  background: rgba(196, 196, 196, 0.2);
  font-family: Avenir-DemiBold;
  @include flexbox();
  @include justify-content(center);
  @include align-items(center);
  @include border-radius(4px);
}

.rightSideBox {
  @include flexbox();
  @include justify-content(space-between);
  @include flex-direction(column);
  height: 100%;

  .editor {
    figure {
      img {
        width: 100%;
      }
    }
  }
}

.list {
  @include flexbox();
  @include align-items(center);
  font-size: $h4;
  font-family: Avenir-Regular;

  &.description {
    margin-bottom: 0;
  }
  .price {
    font-family: Avenir-Medium;
  }
  .inputLabel {
    font-weight: 500;
    margin-right: 10px;
  }
  .editor {
    width: 100%;
  }
}

.pageHeading {
  font-size: $pageHeading;
  font-weight: 600;
}
.label {
  font-family: Avenir-DemiBold;
  font-size: $headingSectionThree;
  color: $themeBlack;
}
.editorClassName {
  border: 1px solid #8080802b !important;
  min-height: 200px;
  padding: 0px 10px;
}
.description {
  padding-bottom: 30px;
}

.card {
  border-radius: 12px !important;
  margin-top: 1rem;
  margin-bottom: 2rem;
  @include box-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
  input,
  select {
    border-radius: 8px;
  }
  .cardHeader {
    display: flex;
    border-radius: 12px 12px 0px 0px !important;
    background-color: $pureWhite;
    .cardHeading {
      font-weight: 600;
      font-size: 1rem;
      color: rgba(0, 0, 0, 0.7);
    }
    .toggleButton {
      margin-left: auto;
      @include flexbox();
      @include align-items(center);
    }
  }

  .expiry-card-header {
    border-radius: 12px 12px 12px 12px !important;
    background-color: #fff;
  }
  .cardBody {
    padding: 1rem 0;
  }
  .cardRow {
    padding: 0px 20px;
    label {
      font-family: Avenir-DemiBold;
    }
  }
  .schedule-payments {
    padding-bottom: 0px;
    margin-bottom: 0px !important;
  }
  .cardRow,
  .field {
    margin-bottom: 1rem;
  }
}

.save-btn {
  padding-top: 10px;
  padding-bottom: 60px;
  text-align: center;
  button {
    font-family: Avenir-DemiBold;
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.7);
    padding: 10px 60px;
    border-radius: 8px;
  }
}
.expiry-date {
  padding-top: 14px;
  padding-bottom: 14px;
  @include flexbox();
  @include justify-content(space-between);
  width: 100%;
  @include align-items(center);
  .expiry-date-left {
    @include flexbox();
    font-family: Avenir-DemiBold;
    font-size: $headingSectionThree !important;
    color: $themeBlack;
    span {
      margin-right: 60px;
    }
  }
  .date-picker {
    border-radius: 8px !important;
    border: 1px solid #ced4da;
    padding: 4px;
  }
  .is-expiry {
    @include flexbox();
    @include align-items(center);
    label {
      font-family: Avenir-DemiBold;
      font-size: $headingSectionThree !important;
      color: $themeBlack;
      margin-right: 10px;
    }
  }
  .end-offer {
    @include flexbox();
    span {
      font-family: Avenir-DemiBold;
      font-size: $headingSectionThree !important;
      color: $themeBlack;
      margin-right: 10px;
    }
    position: relative;
    svg {
      position: absolute;
      right: 6px;
      top: 5px;
      font-size: 20px;
    }
  }
}

.status {
  border-top: 1px solid #ced4da;
  padding: 25px 20px 7px 20px;
  .label {
    font-family: Avenir-DemiBold;
    font-size: $headingSectionThree !important;
    color: $themeBlack;
  }
  .is-status {
    label {
      margin-right: 10px;
      font-size: $headingSectionThree;
      color: $themeBlack;
    }
  }
}

.discount {
  border-top: 1px solid #ced4da;
  border-bottom: 1px solid #ced4da;
  padding: 25px 20px 7px 20px;
  .label {
    font-family: Avenir-DemiBold;
    font-size: $headingSectionThree !important;
    color: $themeBlack;
  }
  label {
    font-family: Avenir-DemiBold;
    font-size: $headingSectionThree !important;
    color: $themeBlack;
  }
  .is-discount-available {
    label {
      margin-right: 10px;
      font-size: $headingSectionThree !important;
      color: $themeBlack;
    }
  }
  .gst-tcs {
    margin-bottom: 10px;
  }
}

.discounted-price {
  padding: 10px 20px 10px 20px;
  border-bottom: 1px solid #ced4da;
  font-family: Avenir-DemiBold;
  font-size: $headingSectionThree !important;
}

.months {
  padding: 25px 20px 7px 20px;
  .label {
    padding-top: 10px;
    font-family: Avenir-DemiBold;
    font-size: $headingSectionThree !important;
    color: $themeBlack;
  }
  label {
    font-family: Avenir-DemiBold;
    font-size: $headingSectionThree !important;
    color: $themeBlack;
  }
}

.payment-schedule {
  @include flexbox();
  padding-bottom: 20px;
  .or {
    font-family: Avenir-DemiBold;
    font-size: 1rem !important;
    color: rgba(0, 0, 0, 0.7);
    @include flexbox();
    @include align-items(flex-end);
    padding: 0px 10px;
  }
  .input-box {
    min-width: 172px;
    max-width: 172px;
  }
  .arrow {
    font-family: Avenir-DemiBold;
    font-size: 1rem !important;
    color: rgba(0, 0, 0, 0.7);
    @include flexbox();
    @include align-items(flex-end);
    padding: 0px 20px 18px 20px;
  }
  .first-payment {
    font-family: Avenir-DemiBold;
    font-size: $headingSectionThree !important;
    color: $themeBlack;
    @include flexbox();
    @include align-items(flex-end);
    padding: 0px 20px;
    padding-bottom: 0.4rem;
  }
  .paymentInputLabel {
    &.variantOne {
      text-indent: -1000px;
    }
  }
  .advance {
    @include flexbox();
    width: 100%;
    label {
      margin-bottom: 0.2rem;

      &.no-label {
        height: 10px;
        margin-bottom: 0.5rem;
      }
    }
    .advance-input {
      width: 100%;
      max-width: 300px;

      padding-top: 5px;
    }
    input {
      // width: 100%;
      border-radius: 0.5rem;
    }
    .t-hyphen {
      font-family: Avenir-DemiBold;
      font-size: $headingSectionThree !important;
      color: $themeBlack;
      @include flexbox();
      @include align-items(flex-end);
      padding-right: 5px;
      padding-bottom: 0.8rem;
    }
    .delete {
      font-family: Avenir-Regular;
      font-size: 12px !important;
      @include flexbox();
      @include align-items(center);
      margin-left: 8px;
      padding-top: 33px;
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.user-payment-schedule {
}
.saving-schedule {
  .saving-total {
    font-size: $h5;
    font-family: Avenir-DemiBold;
    color: $dark;
  }
  .saving-payment-schedule {
    @include flexbox();
    font-size: $h5;
    font-family: Avenir-Medium;
    .arrow {
      padding: 0px 30px 0px 20px;
    }
    margin-bottom: 5px;
  }
  .remaining-payment {
    font-family: Avenir-DemiBold;
    font-size: $headingSectionThree !important;
    color: $themeBlack;
    padding: 5px 10px;
  }
  .remaining-payment-schedule-box {
    .remaining-payment-schedule {
      @include flexbox();
      font-family: Avenir-Regular;
      font-size: $headingSectionThree !important;
      color: $themeBlack;
      .arrow {
        padding: 0px 30px 0px 20px;
      }
      margin-bottom: 10px;
    }
  }
}

.define-payment-schedule {
  @include flexbox();
  @include justify-content(space-between);
  @include align-items(center);
  label {
    margin-right: 10px;
  }
}

.remaining-payment {
  font-family: Avenir-Regular;
  font-size: $headingSectionThree !important;
  color: $themeBlack;
  @include flexbox();
  @include align-items(flex-end);
  padding: 10px 10px;
  color: rgba(0, 0, 0, 0.7);
  border-top: 1px solid #ced4da;
  border-bottom: 1px solid #ced4da;
  margin-bottom: 10px;
}

.schedule-error {
  color: $red-dark;
  text-align: center;
  padding: 5px 0 15px 0;
}

.paymentMethodError {
  color: $red-dark;
  padding: 0 0 5px 12px;
  font-size: 0.78rem;
}

.add-row {
  text-align: center;
  span {
    cursor: pointer;
    text-decoration: underline;
  }
  .not-allowed {
    cursor: not-allowed;
    &:hover {
      color: grey;
      text-decoration-color: grey;
    }
  }
  color: #1eb605;
  font-size: $headingSectionThree;
  font-family: Avenir-Regular;
}

.anchorHand {
  cursor: pointer;
}

.solidHeadingStyles {
  font-family: 'Avenir-DemiBold';
}

.helpText {
  font-size: 0.78rem;
  margin-left: 0.3rem;
  color: #9b9b9b;
}

.payment-methods {
  .paymentMethodContainer {
    display: flex;
    margin-bottom: 1rem;

    .checkBoxContainer {
      margin-right: 3rem;
      display: flex;
      align-items: center;
      cursor: pointer;

      .tick-box {
        width: 24px;
        height: 24px;
        border: 1px solid rgba(51, 51, 51, 0.3);
        box-sizing: border-box;
        border-radius: 2px;
        @include border-radius(2px);
        @include flexbox();
        @include justify-content(center);
        @include align-items(center);
        cursor: pointer;
        img {
          width: 17px;
        }
        margin-right: 5px;
      }

      span {
        font-size: 1.2rem;
        line-height: 1.2rem;
        font-family: Avenir-Medium;
      }

      .tooltip {
        margin-left: 5px;
        color: #666;
      }
    }
  }
  .paymentMethodDescription {
    font-size: 0.85rem;
  }
}

@include screen-below('lg') {
  .cardCol {
    margin-bottom: 1rem;
  }
}

@include screen-below('sm') {
  .payment-schedule {
    flex-direction: column;
    .arrow {
      display: none;
    }
    .advance {
      margin-top: 1rem;
    }
  }
}
