@import 'scss/variables';
@import 'scss/flex';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';

.sign-in-container {
  display: flex;
  height: calc(100vh);

  .sign-in-form {
    box-shadow: 0px 2px 5px #999;
    padding: 2rem;
    border-radius: 1rem;
    width: 400px;
    margin: auto;
    position: relative;

    .title {
      color: rgba(0, 0, 0, 0.7);
      text-align: center;
      font-family: Avenir-DemiBold;
      font-size: 1.2rem;
    }
    .description {
      color: rgba(0, 0, 0, 0.7);
      text-align: center;
      font-family: Avenir-Regular;
      margin-bottom: 2rem;
    }
    .email-address {
      text-align: center;
      // margin-bottom: 1rem;
    }
    .password {
      text-align: center;
    }
    .button {
      width: 100%;
    }
  }
  .password-box {
    position: relative;
    .password-icon {
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
    }
  }

  @include screen-below('sm') {
    .sign-in-form {
      max-width: 100%;
    }
  }
}

.admin-merchant-container {
  @include flexbox();
  @include align-items(center);
  .admin-merchant-checkbox {
    margin-right: 5px;
  }
  font-size: $textParagraphThree;
  a {
    color: $themeBlack;
  }
  margin-bottom: 1rem;
}

.disabled-btn {
  opacity: 0.5;
  cursor: not-allowed;
}
