@import 'scss/mixins';
@import 'scss/_theme';

.savvy-support {
  position: fixed;
  bottom: 1rem;
  right: 0px;
  text-align: center;
  padding: 1rem !important;
  z-index: 2000;
}

.savvy-support-text {
  font-family: Avenir-Medium;
  font-size: $textFinePrint;
  line-height: 15px;
  padding-top: 10px;
}

.savvy-support-btn {
  padding: 1rem !important;
  outline: none !important;
  @include box-shadow(0px 3px 10px rgba(0, 0, 0, 0.5));
  border-radius: 50% !important;
  background-color: $savvyYellow !important;
  border-color: $savvyYellow !important;
}
