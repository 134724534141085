@import 'scss/variables';
@import 'scss/flex';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';

.link-bank-account {
  padding: 0 3rem 2rem 3rem;

  .bank-ac-title {
    font-family: Avenir-DemiBold;
    font-size: $h3;
    color: rgba(0, 0, 0, 0.7);
    padding-top: 20px;
    padding-bottom: 30px;
  }
  .bank-ac-heading {
    color: black;
    font-size: 16px;
    padding-bottom: 10px;
  }
  .next-btn {
    background-color: $savvy-yellow;
    @include border-radius(7px);
    min-width: 140px;
    width: unset;
    height: 44px;
    font-family: Avenir-DemiBold;
    font-size: 16px;
  }
  .link-account-btn {
    margin-left: 30px;
    @include border-radius(7px);
    width: unset;
    height: 44px;
    color: $savvy-yellow;
    border-color: $savvy-yellow;
    font-family: Avenir-DemiBold;
    font-size: 16px;
    &:hover {
      color: white;
      background-color: $savvy-yellow;
    }
  }
}

@include screen-below('lg') {
  .link-bank-account {
    padding: 1rem 0;

    .bank-ac-title {
      display: none;
    }
    .bank-ac-heading {
      display: none;
      color: black;
      font-size: 14px;
      padding-bottom: 10px;
    }
    .next-btn {
      background-color: $savvy-yellow;
      @include border-radius(7px);
      min-width: 140px;
      width: 100%;
      height: 44px;
      font-family: Avenir-DemiBold;
      font-size: 16px;
    }
    .link-account-btn {
      display: none;
    }
  }
}

.mobile-penny-check {
  text-align: center;

  .title {
    font-family: Avenir-DemiBold;
    font-size: 24px;
    padding-top: 30px;
    padding-bottom: 10px;
  }
  .description {
    font-size: 16px;
    line-height: 21px;
    padding-bottom: 20px;
  }
  .timer {
    padding-top: 10px;
    padding-bottom: 30px;
    font-size: 16px;
  }
  .sand-clock-mobile {
    height: 150px;
    width: 150px;
  }
}
