@import 'scss/variables.scss';
@import 'scss/media_queries.scss';
@import 'scss/functions';
@import 'scss/theme';
@import 'scss/flex';

.container {
  margin-bottom: 5rem;
  margin-top: 2rem;

  .select-p-offer {
    height: map-get($textInput, 'height');
    font-size: map-get($textInput, 'fontSize');
    padding: 2px 25px;
    color: $offBlack;
    border-radius: map-get($textInput, 'borderRadius');
    border: map-get($textInput, 'borderWidth') solid $themeBlack;
    &:hover {
      border-color: $themeBlack;
    }
  }
  .heading-title {
    color: $themeBlack;
  }
  .error-box {
    font-size: 0.8rem;
    color: #c74b4b;
    margin-top: 0.1rem;
  }
  .pageHeading {
    margin-bottom: toRem(10);
    color: $themeBlack;
  }
  .card {
    border-radius: 5px;
    margin-bottom: 2rem;
    margin-top: 20px;

    box-shadow: 0 3px 5px darken($gray-medium, 10%);
    .cardHeader {
      background-color: #fff;
      .cardHeading {
        font-size: $cardHeading;
        font-weight: 600;
      }
    }
    .cardBody {
      padding: 1rem 0;

      .remaining-payment {        
        font-size: 1.25rem;
        font-family: Avenir-DemiBold;        
        color: $themeBlack;
        @include flexbox();
        @include align-items(flex-end);
        padding: 10px 20px;
        color: rgba(0, 0, 0, 0.7);
        border-top: 1px solid #ced4da;
        border-bottom: 1px solid #ced4da;
        margin-bottom: 1rem;
      }

      .delete {
        height: 100%;
        display: flex;
        align-items: flex-end;
        padding-bottom: 2rem;
        color: $red-dark;
        text-decoration: underline;
        cursor: pointer;
      }

      .add-row {
        text-align: center;
        span {
          cursor: pointer;
          text-decoration: underline;
        }
        color: #1eb605;
        font-size: $headingSectionThree;
        font-family: Avenir-Regular;
      }
    }
    .cardRow {
      margin-bottom: 1rem;
    }
    .cardSection {
      margin-bottom: 2rem;
    }
    .inputTitle {
      font-weight: 600;
      font-size: 0.9rem;
      padding-bottom: 0.2rem;
    }
    .urlContainer {
      text-align: center;
      margin: 1rem 0 0;
      font-size: 0.8rem;

      .clipboard {
        background-color: transparent;
        border: none;
        padding-left: 5px;
      }
      .urlInput {
        width: 270px;
        padding: 0.25rem;
        border: none;
        background-color: transparent;
        outline: none !important;
      }
      .copied {
        color: $green;
      }
    }
  }

  .modalHeader {
    display: flex;
    flex-direction: row;
    .headerLeft {
      flex: 1;
    }
    .headerRight {
      flex: 1;
    } 
  }
  .modalBody {
    padding: 1rem;
  }
}

@include screen-below('lg') {
  .cardCol {
    margin-bottom: 1rem;
  }
}
