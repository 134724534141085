@import 'scss/media_queries';
@import 'scss/variables';

.container {
  padding: 2rem;
  display: flex;
  align-items: center;
  .label {
    margin-right: 0.5rem;
    font-size: 0.8rem;
    font-family: Avenir-DemiBold;
  }
  .title {
    font-family: Avenir-DemiBold;
    font-size: 1rem;
    color: $primary;
  }
  .textOne {
    font-family: Avenir-DemiBold;
    font-size: 1rem;
  }
  .textTwo {
    font-size: 0.8rem;
    color: $disabled;
  }
  .section {
    margin-bottom: 1rem;
  }

  .offerImage {
    border-radius: 5px;
  }
}

@include screen-below('lg') {
  .cardCol {
    margin-bottom: 1rem;
  }
}
