@import 'scss/variables';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/flex';
@import 'scss/theme';

.container {
  display: flex;
  height: 100vh;
}

.resend-otp-box {
  @include flexbox();
  @include justify-content(center);
  @include align-items(center);
  margin-top: 1rem;
}

.remaining-time {
  font-size: $textFinePrint;
  padding-top: 5px;
}

.leftContainer {
  flex-basis: 50%;
  position: relative;
  .customHeader {
    // position: absolute;
    z-index: 2;
    width: 100%;
  }
  .footer {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 80px;
    bottom: 0px;
    background-color: $pureBlack;
  }
  .headerStyles {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $pureBlack;
    height: 15vh;
  }
  .headingStyles {
    font-size: $headingSection;
    color: $pureWhite;
    line-height: 2.2rem;
    .highlightedText {
      color: $savvyYellow;
      margin-left: 0.5rem;
      font-family: Avenir-Bold;
      font-size: $headingMain;
    }
  }
}
.loginContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  position: relative;
  flex-basis: 50%;
  .logo {
    position: absolute;
    top: 0.8rem;
    right: 0.8rem;
    max-width: 3rem;
  }
  .loginBox {
    text-align: center;
    .link {
      color: $gray-2;
      display: inline-block;
    }
    .loginTitle {
      font-family: Avenir-DemiBold;
      font-size: $headingMain;
      margin-bottom: 2rem;
      color: $greyBlack;
    }
    .label {
      font-family: Avenir-Regular;
      font-size: $headingSection;
      margin-bottom: 0.5rem;
      color: $greyBlack;
    }
    .input {
      border-radius: 0.5rem;
      height: 62px;
      font-family: Avenir-Regular;
      border-color: $greyBlack;
      text-align: center;
      font-size: $headingSection;
      max-width: 458px;
      width: 100%;
      border: 1px solid $greyBlack;
    }
    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $greyBlack;
      opacity: 0.6; /* Firefox */
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: $greyBlack;
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: $greyBlack;
    }
    .ctaTwoStyles {
      @include long-kyc-steps-button();
      color: #fff;
      margin-top: 3rem;
    }
  }
}
.submitBtn {
  margin-top: 100px;
}
.jumbotron {
  background-size: cover;
  color: #fff;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40%;
  .body {
    text-align: center;
  }
  &:after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.45);
    content: '';
  }
  .title {
    font-size: 2rem;
    font-family: 'Avenir-DemiBold';
  }
  .leading {
    font-size: 1.3rem;
    font-family: Avenir-Light;
  }
}
.featuresContainer {
  background-color: $gray-1;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem 0;
}
.how-works {
  background: #ffffff45;
  width: 100%;
  text-align: center;
  color: white;
  font-family: Avenir-Medium;
  font-size: 24px;
  padding: 5px 0px;
  margin-bottom: 54px;
}

.cardContainerStyles {
  margin-bottom: 2rem;
  display: flex;
  width: 70%;
  align-self: center;
  color: #fff;
  font-size: 1.2rem;
  .leftContainer {
    flex-basis: 30%;
    .titleContainer {
      font-size: 1.5rem;
      span {
        border-bottom: 0.2rem solid $primary;
        padding: 0 0.4rem 0.8rem 0;
      }
    }
  }
  .rightContainer {
    font-family: Avenir-Light;
    flex-basis: 70%;
  }
}
.strike {
  position: relative;
  display: inline-block;
  .value {
    position: absolute;
    top: -2.5rem;
    left: 0;
  }
  .strikeValue {
    text-decoration: line-through;
    text-decoration-color: $primary;
    text-decoration-thickness: 0.3rem;
  }
}

@include screen-below('lg') {
  .container {
    display: unset;
  }

  .leftContainer {
    position: relative;
    .customHeader {
      position: relative;
      z-index: 2;
      width: 100%;
    }
    .headerStyles {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $pureBlack;
      height: 60px;
    }
    .headingStyles {
      font-size: $headingSectionTwo;
      color: $pureWhite;
      line-height: 2.2rem;
      .highlightedText {
        color: $savvyYellow;
        margin-left: 0.5rem;
        font-family: Avenir-DemiBold;
        font-size: $headingMainTwo;
      }
      margin-bottom: -2px;
    }
  }

  .loginContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    position: relative;
    flex-basis: 50%;
    padding-top: 50px;
    .logo {
      position: absolute;
      top: 0.8rem;
      right: 0.8rem;
      max-width: 2rem;
      display: none;
    }
    .loginBox {
      text-align: center;
      .loginTitle {
        font-family: Avenir-DemiBold;
        font-size: $headingSection;
        margin-bottom: 0rem;
        color: $themeBlack;
      }
      .label {
        font-family: Avenir-Regular;
        font-size: $textParagraphOne;
        margin-bottom: 4rem;
        color: $themeBlack;
      }
      .labelcontainerstyles {
        padding-right: 0px !important;
      }
      .containerstyles {
        margin-right: 0px !important;
      }
      .fieldcontainerstyles {
        padding-right: 0px !important;
        margin-right: 0px !important;
      }

      .input {
        border-radius: 0.5rem;
        height: 42px;
        font-family: Avenir-Regular;
        border-color: $greyBlack;
        text-align: center;
        font-size: $headingSectionThree;
        max-width: 270px;
        color: $themeBlack;
        width: 100%;
        border: 1px solid $greyBlack;
      }
      ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $greyBlack;
        opacity: 0.6; /* Firefox */
      }

      :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: $greyBlack;
      }

      ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: $greyBlack;
      }
      .ctaTwoStyles {
        @include long-kyc-steps-button();
        color: #fff;
        margin-top: 3rem;
      }
    }
  }
  .submitBtn {
    margin-top: 80px;
  }
}

@include screen-below('sm') {
}
