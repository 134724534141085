@import 'scss/variables';
@import 'scss/flex';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';

// .container{
//     margin-top: 2rem;
.pageHeading {
  font-size: $pageHeading;
  font-weight: 600;
}
.error {
  font-size: 0.8rem;
  color: #c74b4b;
  margin-top: 0.1rem;
}
.taxes {
  .label {
    font-weight: 600;
    font-size: $headingSectionThree;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: 1px;
    padding-bottom: 1px;
    margin-bottom: 15px;
  }
  .checkboxes-group {
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 15px;
    @include flexbox();

    .checkbox {
      @include flexbox();
      @include align-items(center);
      &:first-child {
        margin-right: 80px;
      }
      .tick-box {
        width: 16px;
        height: 16px;
        border: 1px solid rgba(51, 51, 51, 0.3);
        box-sizing: border-box;
        border-radius: 2px;
        @include border-radius(2px);
        @include flexbox();
        @include justify-content(center);
        @include align-items(center);
        cursor: pointer;
        img {
          width: 11px;
        }
        margin-right: 5px;
      }
      .checkbox-label {
        @include flexbox();
        @include align-items(center);
        font-weight: 600;
        font-size: $headingSectionThree;
      }
      .checkbox-percentage {
        font-size: $headingSectionThree;
      }
      .checkbox-text {
        input {
          @include border-radius(8px);
          width: 60px !important;
          font-size: $headingSectionThree;
          margin: 0px 6px;
        }
      }
    }
  }
}
.locked-quantity {  
  .locked-quantity-wrapper {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 1.2rem;
    padding-top: 0.3rem;
    margin-bottom: 0.5rem;
    .label {
      display: flex;
      align-items: center;
      margin-bottom: 0.2rem;
    }
    label {
      font-family: Avenir-DemiBold;
      font-size: $headingSectionThree !important;
      color: $themeBlack;
    }
    .quantity-text {
      position: relative;

      input {
        position: absolute;
        top: -24px;
      }
    }
  }  
}
.card {
  border-radius: 12px !important;
  margin-bottom: 2rem;
  box-shadow: 0 3px 5px darken($gray-medium, 10%);
  .cardHeader {
    display: flex;
    border-radius: 12px 12px 0px 0px !important;
    background-color: $savvyYellow;
    border-bottom: unset !important;
    .cardHeading {
      font-size: $cardHeading;
      font-weight: 600;
    }
    .cardHeaderTitle {
      color: $themeBlack;
    }
    .toggleButton {
      margin-left: auto;
    }
  }
  .cardBody {
    padding: 1rem 0;
    .labelContainerStyles {
      background-color: $yellow-0;
    }
  }
  .cardRow,
  .field {
    margin-bottom: 1rem;
  }
  .cardSection {
    padding: 0 1rem;
    margin-bottom: 1rem;
  }

  .contentWrapper {
    border-bottom: 1px solid #ddd;
    margin-bottom: 2rem;
    padding-bottom: 2rem;

    &:last-child {
      border-bottom: none;
      margin-bottom: 0rem;
    }

    .titleContainer {
      display: flex;
      flex: 1;

      .titleInput {
        flex: 1;
      }
      .titleAction {
        justify-content: center;
        align-items: center;
        display: flex;

        .delete {
          color: $red-dark;
          border: transparent;
          background-color: transparent;
          padding: 0 1rem;
          position: relative;
          top: 4px;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
  .addMoreContainer {
    text-align: center;

    .addMore {
      color: $green;
      border: transparent;
      background-color: transparent;
      padding: 0 1rem;
      position: relative;
      top: 4px;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
// }
.buttonContainer {
  text-align: center;
  margin-bottom: 2rem;
}

.anchorHand {
  cursor: pointer;
}

.solidHeadingStyles {
  font-family: 'Avenir-DemiBold';
}

.helpText {
  font-size: 0.78rem;
  margin-left: 0.3rem;
  color: #9b9b9b;
}

.label {
  font-size: $headingSectionThree;
  color: $themeBlack;
  font-family: 'Avenir-DemiBold';
}
.editorClassName {
  border: 1px solid #8080802b !important;
  min-height: 200px;
  padding: 0px 10px;
}

@include screen-below('lg') {
  .cardCol {
    margin-bottom: 1rem;
  }
}
