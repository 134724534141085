@import 'scss/media_queries';

.progress {
  // padding: 0 2rem;
}

@include screen-below('lg') {
  .progress {
    padding: 0;
  }
}
