@import 'scss/variables';
@import 'scss/flex';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';

.offer-details-title {
  font-family: Avenir-DemiBold;
  font-size: 22px;
  color: rgba(0, 0, 0, 0.7);
  margin-top: -18px;
  padding-bottom: 10px;
  padding-left: 15px;
}

.custom-btn-group {
  @include flexbox();
  @include justify-content(center);
  padding: 0px 20px;
  background-color: $pureWhite;
  padding: 30px 0px;
  .custom-btn {
    font-size: $headingSection;
    background-color: $savvyYellow;
    font-family: Avenir-DemiBold;
    color: $offBlack !important;
    border: 1px solid $savvyYellow;
    padding: 10px 25px;
    border-radius: 8px;
    min-width: 250px;
    width: 100%;
    margin-right: 80px;
  }
}
.card {
  border-radius: 12px !important;
  margin-bottom: 2rem;
  box-shadow: 0 3px 5px darken($gray-medium, 10%);
  .card-header {
    @include flexbox();
    border-radius: 12px 12px 0px 0px !important;
    background-color: #fff;
    .card-heading {
      font-family: Avenir-DemiBold;
      font-size: 22px;
      color: rgba(0, 0, 0, 0.7);
    }
    .toggle-button {
      margin-left: auto;
      @include flexbox();
      @include align-items(center);
    }
  }
  .expire-card-header {
    border-radius: 12px 12px 12px 12px !important;
  }

  .image-box {
    img {
      width: 100%;
      @include border-radius(4px);
    }
    .inner-image-box {
      width: 100%;
      min-height: 213px;
      background: rgba(196, 196, 196, 0.2);
      @include flexbox();
      @include justify-content(center);
      @include align-items(center);
      @include border-radius(4px);
    }
  }

  .right-side {
    .list {
      margin-bottom: 20px;
      .list-group {
        @include flexbox();
        .label {
          font-family: Avenir-Regular;
          font-size: 22px;
          color: rgba(0, 0, 0, 0.7);
        }
        .value {
          font-family: Avenir-DemiBold;
          font-size: 22px;
          color: rgba(0, 0, 0, 0.7);
        }
      }
    }
    .title {
      font-family: Avenir-Regular;
      font-size: 22px;
      color: rgba(0, 0, 0, 0.7);
    }
  }

  .timeline {
    .timeline-group {
      @include flexbox();
      @include align-items(center);
      margin-bottom: 25px;
      &:nth-last-child(1) {
        margin-bottom: 0px;
      }
      .label {
        font-family: Avenir-DemiBold;
        font-size: 22px;
        color: rgba(0, 0, 0, 0.7);
      }
      .arrow {
        padding: 0px 30px 0px 20px;
      }
      .value {
        font-family: Avenir-DemiBold;
        font-size: 22px;
        color: rgba(0, 0, 0, 0.7);
      }
    }
  }
}

.detail-btns {
  @include flexbox();
  @include justify-content(space-between);
  padding-bottom: 40px;
  button {
    margin-bottom: 10px;
    font-family: Avenir-DemiBold;
    font-size: 22px;
    color: rgba(0, 0, 0, 0.7);
    width: 100%;
    @include border-radius(8px);
  }
}
