@import 'scss/variables';

.app-checkbox {
  display: flex;
  cursor: pointer;
  background-color: transparent;
  border: none;
  font-size: 0.9rem;

  .app-checkbox-icon {
    background-image: url('https://savvy-web.s3-ap-southeast-1.amazonaws.com/partner/check.png');
    background-size: cover;
    height: 18px;
    width: 20px;
    background-position: 0px 0px;
    opacity: 0.7;

    &.checked {
      background-position: 0px -19px;
    }
  }
  .app-checkbox-description {
    flex: 1;
    padding-left: 0.5rem;
    text-align: left;
    color: #333;
    line-height: 1.2rem;
  }
}
