@import 'scss/functions';
@import 'scss/theme';

.logoHeading{
  font-size: toRem(58);
  font-family: $fontFamilySemiBold;
  color: $greyBlack;
  line-height: toRem(55);
}
.logoLeading{
  font-size: toRem(16);
  font-family: $fontFamilySemiBold;
  color: $greyBlack;
}