@import 'scss/variables';
@import 'scss/flex';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';

.product-detail-container {
  width: 100%;
  position: absolute;
  top: 213px;
  padding: 0 20px 0 15px;

  .product-detail {
    display: flex;
    flex: 1;
    background-color: #fff;
    border-radius: 15px;
    padding: 15px 15px;
    box-shadow: 0px 2px 10px #666;

    .left {
      display: flex;
      flex: 3;
      flex-direction: column;
      justify-content: center;
      padding-left: 20px;
      .product-name {
        font-family: Avenir-Medium;
      }

      .passengers {
        @include flexbox();
        @include align-items(center);
      }
      .main-dropdown {
        margin-left: 10px;
        button {
          min-width: 67px;
          background: #ffffff;
          border: 2.37299px solid $savvyYellow;
          box-sizing: border-box;
          border-radius: 9.49196px;
          padding: 0px 0px 0px 12px;
          position: relative;
          font-size: $textParagraphOne;
          text-align: left;
          &::after {
            display: inline-block;
            position: absolute;
            top: 11px;
            right: 8px;
            vertical-align: 0.255em;
            content: '';
            border-top: 0.3em solid;
            border-right: 0.3em solid transparent;
            border-bottom: 0;
            border-left: 0.3em solid transparent;
            color: orange;
          }
        }
        .dropdown-menu {
          max-height: 200px;
          overflow-y: auto;
          a {
            font-size: $textParagraphOne;
          }
        }
      }
    }
    .center {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .discount-container {
        background-color: $success-light;
        color: $extra-light;
        width: 45px;
        height: 45px;
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        line-height: 14px;

        :first-child {
          font-family: Avenir-DemiBold;
          margin-top: 5px;
        }
        :last-child {
          font-size: 10px;
          font-family: Avenir-Medium;
        }
      }
    }
    .right {
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      :first-child {
        font-family: Avenir-Medium;
        font-size: 26px;
      }
      :last-child {
        font-size: 16px;
        text-decoration: line-through;
      }
    }
  }
}

@include screen-below('lg') {
  .product-detail-container {
    top: -30px;
    padding: 0;
    position: relative;

    .product-detail {
      padding: 10px 10px;

      .left {
        padding-right: 8px;

        :first-child {
          font-size: 16px;
        }
        :last-child {
          font-size: 14px;
        }
      }

      .center {
        display: none;
      }

      .right {
        align-items: flex-start;
        flex: initial;

        :first-child {
          font-size: 16px;
        }
        :last-child {
          font-size: 14px;
        }
      }
    }
  }
}
