@import 'scss/variables';
@import 'scss/theme';

.listItemContainer {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: $themeBlack;
  font-family: Avenir-DemiBold;

  &:hover {
    color: $primary;
  }
  .icon {
    margin-left: auto;
  }
  &.active {
    color: $primary;
  }
}

.listItem {
  padding: 0.5rem 0;
  font-size: $headingSectionThree;
}
.linkItem {
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: $headingSectionThree;
  color: $themeBlack;
  font-family: Avenir-DemiBold;
  // font-weight: 600;
  text-decoration: none;
  padding: 7px 0px;

  &.active {
    color: $primary;
  }
  &:hover {
    color: $primary;
  }
}

.childMenu {
  list-style-type: none;
  padding-left: 0rem;
  .linkItem {
    font-family: Avenir-Regular;
    font-size: $headingSectionThree;
    padding: 0px 0px;
  }
}
