@import 'scss/variables';
@import 'scss/flex';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';

// modal
.custom-modal {
  background-color: rgba(255, 255, 255, 0.75);
  .modal-dialog {
    max-width: 820px;
    .modal-content {
      @include border-radius(16px);
      background-color: unset;
      border: unset;
      .lock-fare-deposite-box {
        @include border-radius(16px);
        background-color: white;
        margin-top: 50px;
        text-align: center;
        .lock-fare-ok-icon {
          width: 125px;
          height: 125px;
          margin-top: 40px !important;
          margin-bottom: 10px !important;
        }
        .lock-fare-error-icon {
          width: 100px;
          height: 100px;
          margin-top: -50px !important;
          margin-bottom: 50px !important;
        }
        .lock-fare-thank-you {
          font-size: $h1;
          line-height: 38px;
          color: $gray-text;
          margin-bottom: 10px;
          padding-top: 30px;
        }
        .payment-ref {
          font-size: $h1;
          line-height: 38px;
          font-family: Avenir-DemiBold;
          color: rgba(0, 0, 0, 0.7);
          margin-bottom: 5px;
        }
        .payment-receipt {
          font-size: $h1;
          line-height: 38px;
          color: $gray-text;
          margin-bottom: 50px;
        }
        .lock-fare-failed-reason {
          margin-bottom: 30px;          
          b {
            font-family: Avenir-DemiBold;
          }
        }
        .lock-fare-failed-support {
          margin-bottom: 25px;
          font-size: 0.85rem;
        }
        .lock-fare-start-saving {
          font-size: $h1;
          line-height: 38px;
          font-family: Avenir-DemiBold;
          color: rgba(0, 0, 0, 0.7);
          margin-bottom: 25px;
        }
        .verify-kyc-btn {
          min-width: 300px;
          height: 56px;
          @include border-radius(7px);
          background-color: $savvy-yellow;
          outline: unset;
          border: unset;
          font-size: 22px;
          font-family: Avenir-DemiBold;
          color: white;
          margin-bottom: 60px;
        }

        .download-btn {
          min-width: 300px;
          height: 56px;
          @include border-radius(7px);
          outline: unset;
          border: unset;
          background-color: white;
          font-size: 22px;
          font-family: Avenir-DemiBold;
          color: $savvy-yellow;
          border: 2px solid $savvy-yellow;
          margin-bottom: 60px;
          margin-left: 50px;
        }

        .go-back {
          position: relative;
          top: -25px;
        }
      }
    }
  }
  @include screen-below('lg') {
    .modal-dialog {
      max-width: 820px;
      .modal-content {
        @include border-radius(16px);
        background-color: unset;
        border: unset;
        .lock-fare-deposite-box {
          @include border-radius(16px);
          background-color: white;
          margin-top: 50px;
          text-align: center;
          .lock-fare-ok-icon {
            margin-top: -25px;
            margin-bottom: 50px;
          }
          .lock-fare-thank-you {
            font-size: 16px;
            line-height: 21px;
            color: $gray-text;
            margin-bottom: 80px;
            padding: 0px 10px;
          }
          .lock-fare-start-saving {
            font-size: 16px;
            line-height: 21px;
            font-family: Avenir-DemiBold;
            color: rgba(0, 0, 0, 0.7);
            margin-bottom: 40px;
            padding: 0px 10px;
          }
          .verify-kyc-btn {
            min-width: 273px;
            height: 45px;
            @include border-radius(7px);
            background-color: $savvy-yellow;
            outline: unset;
            border: unset;
            font-size: 18px;
            font-family: Avenir-DemiBold;
            color: white;
            margin-bottom: 45px;
          }
          .go-back {
            top: -25px;
          }
        }
      }
    }
  }
}
