@import 'scss/theme';
@import 'scss/functions';

.buttonContainer{
  text-align: center;
  margin: toRem(15) 0;
}
.topButtons{
  min-width: auto;
  padding: 0 toRem(15);
  display: none;
}