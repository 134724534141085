@import 'scss/variables';
@import 'scss/flex';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';

.user-details-read {
  .main-heading {
    padding: 0px 35px;
    margin-top: -11px;
    .title {
      font-size: 22px;
      color: $themeBlack;
      font-family: 'Avenir-DemiBold';
      margin-bottom: 10px;
    }
  }
  .offer-table {
    .offer-table-heading {
      padding: 12px 35px 8px 35px;
      background-color: $pureWhite;
      font-size: 22px;
      border-bottom: 1px solid #d9d9d9;
      color: $themeBlack;
      font-family: 'Avenir-DemiBold';
    }
    table {
      width: 100%;
      thead {
        tr {
          background-color: $savvyYellow;
          text-transform: uppercase;
          th {
            color: $themeBlack;
            font-size: $headingSectionThree;
            font-family: 'Avenir-DemiBold';
            padding: 12px 0px;
            &:nth-child(1) {
              padding-left: 37px;
            }
          }
        }
      }
      tbody {
        tr {
          &:nth-child(odd) {
            background-color: $greyWhite;
            td {
              padding: 12px 0px;
            }
          }
          &:nth-child(even) {
            background-color: $pureWhite;
          }
          td {
            max-width: 220px;
            text-overflow: ellipsis;
            font-size: 18px;
            color: #000000;
            &:nth-child(1) {
              padding-left: 37px;
            }
            font-family: Avenir-Medium;
          }
        }
      }
    }
  }
  .content {
    @include flexbox();
    .content-box {
      padding: 0px 25px 30px 0px;
      .label {
        font-size: 40px;
        color: $themeBlack;
        font-family: 'Avenir-Regular';
        text-align: center;
        line-height: 40px;
      }
      .text {
        text-align: center;
        font-size: 14px;
        color: $themeBlack;
        font-family: 'Avenir-Regular';
        white-space: pre;
      }
    }
  }

  .profile {
    background-color: $pureWhite;
    border-top: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
    padding: 50px 40px;
    .profile-img {
      width: 221px;
      height: 221px;
      object-fit: cover;
      border-radius: 100%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .profile-content {
      padding: 0px 0px 10px 0px;
      .profile-title {
        font-size: 22px;
        color: $themeBlack;
        font-family: 'Avenir-DemiBold';
        margin-bottom: 20px;
      }
      .profile-list {
        .profile-data {
          @include flexbox();
          .profile-label {
            font-size: 22px;
            color: $themeBlack;
            font-family: 'Avenir-Regular';
          }
          .profile-text {
            font-size: 22px;
            color: $themeBlack;
            font-family: 'Avenir-DemiBold';
          }
        }
      }
    }
  }

  .card {
    border-radius: 10px;
    margin-bottom: 2rem;
    box-shadow: 0 3px 5px darken($gray-medium, 10%);
    .cardHeader {
      background-color: $peach;
      .cardHeading {
        font-size: $cardHeading;
        font-weight: 600;
      }
    }
    .cardBody {
      padding: 1rem 0;
    }
    .cardRow,
    .field {
      margin-bottom: 1rem;
    }
    .cardSection {
      padding: 0 1rem;
      margin-bottom: 1rem;
    }
    .inputLabelContainer {
      .inputLabel {
        font-weight: 600;
        font-size: $inputLabel;
      }
    }
  }

  .solidHeadingStyles {
    font-family: 'Avenir-DemiBold';
  }

  .helpText {
    font-size: 0.78rem;
    margin-left: 0.3rem;
    color: #9b9b9b;
  }
}
@include screen-below('lg') {
  .cardCol {
    margin-bottom: 1rem;
  }
}
