.plan-goal {
  padding-top: 2rem;
  padding-bottom: 3rem;
  position: relative;

  .header-banner {
    height: 250px;
    border-radius: 10px;
  }
}
