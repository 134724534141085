@import 'scss/variables.scss';
@import 'scss/media_queries.scss';

.imageInputContainer {
  border: 1px solid #c4c4c4;
  border-radius: 10px;
  padding: 1rem;
  display: flex;
  align-items: center;
  width: 100%;
    .imageContainer {
      width: 100px;

      img {
        width: 100%;
        border-radius: 5px;        
      }
      video {
        width: 100%;
        border-radius: 5px;        
      }
    }
    .header{
      .heading{

      }
    }
    .label {
      // background-color: $primary;
      // padding: 0.375rem 0.75rem;
      // align-items: center;
      // display: flex;
      // justify-content: center;
      // border-radius: 5px;
      margin-left: 1rem;
    }
    .text{
      font-size: .8rem;
      font-family: Avenir-Regular;
    }
    .input {
      width: 68%;
    }
    .rightContainer{
      margin-left: auto;
  }
}
.helpText {
  font-size: 0.78rem;
  margin-left: 0.3rem;
  color: #c5c5c5;
}

@include screen-below('lg'){
  .imageInputContainer {
    padding: 0.5rem;;
  }
}
