@import 'scss/variables';
@import 'scss/flex';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';

.imageInputContainer {
  border: 1px solid #c4c4c4;
  border-radius: 10px;
  padding: 1rem;
  display: flex;
  align-items: center;
  width: 100%;
  .imageContainer {
    width: 100px;

    img {
      width: 100%;
      border-radius: 5px;
    }
    video {
      width: 100%;
      border-radius: 5px;
    }
  }
  .header {
    .heading {
    }
  }
  .label {
    background-color: $savvyYellow;
    font-family: Avenir-DemiBold;
    color: $pureBlack;
    margin-left: 1rem;
  }
  .text {
    font-size: 0.8rem;
    font-family: Avenir-Regular;
  }
  .input {
    width: 68%;
  }
  .rightContainer {
    margin-left: auto;
    @include flexbox();
    @include justify-content(center);
    @include align-items(center);
  }
}
.helpText {
  font-size: 0.78rem;
  margin-left: 0.3rem;
  color: #c5c5c5;
}

@include screen-below('lg') {
  .imageInputContainer {
    padding: 0.5rem;
  }
}
