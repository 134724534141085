@import 'scss/variables';
@import 'scss/flex';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';

.demographic-info {
  padding: 0 3rem;

  .demo-info-title {
    font-family: Avenir-DemiBold;
    font-size: $h3;
    color: rgba(0, 0, 0, 0.7);
    padding-top: 20px;
    padding-bottom: 22px;
  }
  .demo-info-heading {
    color: rgba(0, 0, 0, 0.7);
    font-size: $h3;
    padding-bottom: 40px;
  }
  .react-datepicker__triangle {
    left: -20px !important;
  }
  .demo-info-group-input {
    margin-bottom: 20px;
    label {
      font-family: Avenir-DemiBold;
      font-size: 16px;
      line-height: 21px;
      margin-bottom: 5px;
      color: rgba(0, 0, 0, 0.7);
    }
    .demo-info-input,
    input {
      @include border-radius(8px);
      width: 563px;
      height: 48px;
      font-size: 15px;
      font-family: Avenir-Medium;
      border: 1px solid #ccc;
      outline: none;
      color: #333;
      background-color: transparent;
      padding: 0px 15px;
      &:disabled {
        background-color: $disabled-light;
      }

      &:focus {
        border: 1px solid $primary;
        box-shadow: 0 0 2px $primary;
      }
      margin-bottom: 5px;
      @include placeholder-color(#c4c4c4);
    }
  }
  .demo-info-all-done-btn {
    background-color: $savvy-yellow;
    @include border-radius(7px);
    min-width: 140px;
    width: unset;
    height: 44px;
    font-family: Avenir-DemiBold;
    font-size: 16px;
    margin-bottom: 20px;
    padding-left: 63px;
    padding-right: 63px;
  }
  .partner-imgs {
    img {
      margin-right: 12px;
    }
  }
  .message {
    font-family: Avenir-Medium;
    color: rgba(0, 0, 0, 0.7);
    font-size: 12px;
    padding-bottom: 25px;
  }
  .custom-dropdown {
    button {
      color: rgba(0, 0, 0, 0.7);
      background-color: white !important;
      border: unset;
      border-bottom: 1px solid rgba(0, 0, 0, 0.7);
      @include border-radius(0px);
      min-width: 300px;
      text-align: left;
      position: relative;
      &:focus {
        outline: unset !important;
        box-shadow: unset !important;
        background-color: white !important;
        color: rgba(0, 0, 0, 0.7);
      }
      &:active {
        outline: unset !important;
        box-shadow: unset !important;
        background-color: white !important;
        color: rgba(0, 0, 0, 0.7) !important;
      }
    }
    .dropdown-toggle {
      &::after {
        position: absolute;
        right: 60px;
        top: 15px;
      }
    }
    .dropdown-menu {
      min-width: 300px;
    }
  }
  .drodown-light-placeholder {
    color: #c4c4c4 !important;
  }
  .demo-info-lakh {
    .dropdown-toggle {
      &::after {
        margin-left: 150px;
      }
    }
  }

  .demo-info-checkbox {
    margin-top: 20px;
    margin-bottom: 14px;
    font-size: 29px;

    label {
      font-family: Avenir-DemiBold;
      font-size: 16px;
    }
  }
}

.folio-created-box {
  @include border-radius(16px);
  background-color: white;
  margin-top: 50px;
  text-align: center;
  .folio-created-ok-icon {
    width: 150px;
    transform: scale(1.5);
  }
  .folio-created-thank-you {
    font-size: 28px;
    line-height: 38px;
    color: $gray-text;
    margin-bottom: 50px;
  }
  .folio-created-start-saving {
    font-size: 28px;
    line-height: 38px;
    font-family: Avenir-DemiBold;
    color: rgba(0, 0, 0, 0.7);
    margin-bottom: 50px;
  }
  .folio-created-btn {
    min-width: 300px;
    height: 56px;
    @include border-radius(7px);
    background-color: $savvy-yellow;
    outline: unset;
    border: unset;
    font-size: 16px;
    font-family: Avenir-DemiBold;
    color: white;
    margin-bottom: 60px;
  }
}

@include screen-below('lg') {
  .demographic-info {
    padding: 0 1rem;

    .demo-info-title {
      display: none;
    }
    .demo-info-heading {
      display: none;
    }
    .demo-info-group-input {
      margin-bottom: 10px;

      .demo-info-input,
      input {
        width: 100%;
        background-color: #f3f3f3;
        border: unset;
        @include border-radius(4px);
      }
      &:nth-child(3) {
        margin-top: 20px;
      }
      label {
        display: none;
      }
    }
    .custom-dropdown {
      width: 100%;
      margin-bottom: 14px;

      button {
        min-width: 100%;
        width: 100%;
        position: relative;
        border: unset;
        background-color: #f3f3f3 !important;
        padding: 14px 17px;
        @include border-radius(4px);
        &:focus {
          background-color: #f3f3f3 !important;
        }
        &:active {
          background-color: #f3f3f3 !important;
        }
      }
      .dropdown-toggle {
        &::after {
          position: absolute;
          right: 20px;
          top: 23px;
        }
      }
      .dropdown-menu {
        width: 100%;
      }
    }

    .demo-info-checkbox {
      margin-top: 0px;
      margin-bottom: 25px;
      font-size: 25px;
      .form-check-input {
        background-color: #f3f3f3;
        &:checked {
          background-color: #eda12c;
        }
        border: unset;
      }
      label {
        font-family: Avenir-Medium;
        color: rgba(0, 0, 0, 0.7);
        font-size: 16px;
      }
    }
    .demo-info-all-done-btn {
      background-color: $savvy-yellow;
      @include border-radius(7px);
      min-width: 140px;
      width: 100%;
      height: 44px;
      text-transform: uppercase !important;
      font-family: Avenir-DemiBold;
      font-size: 16px;
      margin-bottom: 50px;
      padding-left: 63px;
      padding-right: 63px;
    }
  }

  .folio-created-box {
    @include border-radius(16px);
    background-color: white;
    margin-top: 50px;
    text-align: center;
    .folio-created-ok-icon {
      margin-top: -25px;
      margin-bottom: 50px;
    }
    .folio-created-thank-you {
      font-size: 16px;
      line-height: 21px;
      color: $gray-text;
      margin-bottom: 30px;
    }
    .folio-created-start-saving {
      font-size: 16px;
      line-height: 21px;
      font-family: Avenir-DemiBold;
      color: rgba(0, 0, 0, 0.7);
      margin-bottom: 40px;
    }
    .folio-created-btn {
      min-width: 273px;
      height: 45px;
      @include border-radius(7px);
      background-color: $savvy-yellow;
      outline: unset;
      border: unset;
      font-size: 18px;
      font-family: Avenir-DemiBold;
      color: white;
      margin-bottom: 45px;
    }
  }
}
