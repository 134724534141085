@import 'scss/variables.scss';
@import 'scss/media_queries.scss';

.container{
  // padding: 3rem;
  .header{
    margin-bottom: 1rem;
  }
  .heading{
    font-size: 2.3rem;
    font-family: Avenir-DemiBold;
  }
  .inputContainer{
    // width: 70%;
    margin-bottom: 2rem;
  }
  .contentContainer{
    div{
      line-height: 2rem;
    }
    label{
      color: $dark;
      margin-right: 0.5rem;
    }
    span{
      font-family: Avenir-Medium;
      color: #000000;
    }
  }
}

@include screen-below('lg'){
  .container{
    .heading{
      font-size: 1.2rem;
    }
    .inputContainer{
      width: auto;
    }
  }
}