@import 'scss/variables';
@import 'scss/media_queries';

.container{
  .contentClassName{
    max-width: none;
    padding: 0;
  }
  .jumbotron{
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: 3rem;
    &:after{
      position: absolute;
      content: "";
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(255,255,255,0.6);
    }
  }
  .main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .contentHeader{
      .heading{
        margin-bottom: 2rem;
        font-family: 'Avenir-DemiBold';
        font-size: 1.2rem;
      }
    }
  }
  .ctaContainer{
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 3rem 0;
    .text{
      color: $dark;
      margin-bottom: 0.8rem;
      font-size: 0.9rem;
    }
  }
  /* Modal */
}
.otpModal{
  .otpModalContent{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 2rem;
    .header{
      text-align: center;
      margin-bottom: 2rem;
      .heading{
        font-family: Avenir-DemiBold;
        font-size: 1.2rem;
        margin-bottom: 1rem;
      }
      .sub{
        font-size: 0.8rem;
      }
    }
    .ctaTwo{
      // margin-top: 2rem;
    }
    .resendOTP {
      margin: 1.5rem 0 0.5rem;
      background: none;
      border: none;
      text-decoration: underline;
    }
  }
}

/* Custom card three styles */
.customCardThree{
  min-width: 22rem;
  margin-bottom: 1rem;
  padding: 1.5rem 1rem;
  .labelstyles{
    color: $dark-light;
  }
  .rightContentStyleOne,
  .rightContentStyleTwo,
  .rightContentStyleThree{
    font-family: Avenir-DemiBold;
  }
  .rightContentStyleOne{
    color: $green;
  }
  .rightContentStyleTwo{
    color: $red;
  }
  .rightContentStyleThree{
    color: $primary;
  }
}

/* cta two styles */
.ctaTwo{
  min-width: 15rem;
}

@include screen-below('lg'){
  .container{
    .contentClassName{
      max-width: none;
      padding: 0;
    }
    .jumbotron{
      position: relative;
      background-size: cover;
      background-repeat: no-repeat;
      margin-bottom: 3rem;
      &:after{
        position: absolute;
        content: "";
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(255,255,255,0.6);
      }
    }
    .main{
      .contentHeader{
        .heading{
          text-align: center;
        }
      }
    }
    .ctaContainer{
      .text{
        text-align: center;
        font-size: 0.8rem;
        max-width: 75%;
      }
    }
    /* Modal */
  }
}



