@import 'scss/variables.scss';

.email-code-modal {
  .modal-header {
    .header-left {
      font-family: Avenir-DemiBold;
      font-size: 1.2rem;
    }
    .header-right {
      span {
        cursor: pointer;
        img {
          height: 25px;
          width: 25px;
        }
      }
    }
  }
  .modal-body {
    padding: 1rem;
    min-width: 100%;
    min-height: calc(100vh - 125px);

    .tab-content {
      padding: 1rem 0;      

      textarea {
        width: 100%;
        min-height: calc(100vh - 315px);
        margin-bottom: 1rem;
      }
    }
    .code-copied {
      background-color: $green;
      position: absolute;
      color: $extra-light;
      padding: 0.1rem 0.5rem;
      border-radius: 0.25rem;
      left: 40%;
    }
  }
}