@import 'scss/variables';
@import 'scss/flex';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  
  header {
    font-size: $textParagraphOne;
    margin-bottom: 0.4rem;
    color: $footerBlack;
    font-family: Avenir-Regular;
  }
  .videoContainer {
    width: 300px;
    height: 200px;
    position: relative;
  }
  .placeholder {
    border: 2px dashed $greyWhiteFour;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    height: 100%;
    width: 100%;

    .placeholderIcon {
      font-size: 3rem;
      color: $dark;
    }
  }
  .video {
    height: 100%;
    width: 100%;
    border-radius: 5px;
  }
  .counterContainer {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 5px;

    .counter {
      font-size: 3rem;
      font-family: 'Avenir-DemiBold';
      color: $primary;
    }
  }

  .buttonContainer {
    margin-top: 10px;
    z-index: 10;
    width: 300px;
    @include flexbox();
    @include justify-content(center);
    .button {
      width: fit-content;
      margin-right: 5px;
      font-size: 0.8rem;
      padding: 0.25rem 0.5rem;
      flex: 1;
      border: none;
      border-radius: 0.25rem;
      background-color: $disabled-medium;
      font-family: Avenir-Medium;
      white-space: pre;
      .icon {
        margin-right: 5px;
      }

      &.danger {
        background-color: $red-dark;
        color: $extra-light;
      }

      &.primary {
        background-color: $savvyYellow;
        color: $pureBlack;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
