@import 'scss/media_queries';
@import 'scss/variables';
@import 'scss/_theme';
@import 'scss/_flex';

.modal-content {
  min-height: 554px !important;
}

.save-more-modal {
  .cross-icon {
    @include flexbox();
    @include justify-content(flex-end);
    padding: 20px 20px 0px 0px;
    span {
      cursor: pointer;
    }
    img {
      opacity: 0.7;
      width: 20px;
    }
    margin-bottom: 15px;
  }

  .title {
    font-size: $headingMain;
    color: $greyBlack;
    line-height: 38px;
    font-family: Avenir-Regular;
    text-align: center;
    margin-bottom: 5px;
  }

  .amount {
    font-size: $headingMain;
    color: $greyBlack;
    line-height: 38px;
    font-family: Avenir-DemiBold;
    text-align: center;
  }

  .text-input {
    max-width: 400px;
    width: 100%;
    height: 64px;
    background-color: $greyWhite;
    outline: unset;
    border: unset;
    font-size: $textParagraphOne;
    padding: 0px 20px;
    border-radius: 4px;
  }

  .input-box {
    @include flexbox();
    @include flex-direction(column);
    @include justify-content(center);
    @include align-items(center);
    .error {
      padding-top: 10px;
      color: $errorRed;
    }
  }

  .custom-btn {
    font-size: $headingSection;
    background-color: $savvyYellow;
    font-family: Avenir-DemiBold;
    color: $offBlack !important;
    border: 1px solid $savvyYellow;
    padding: 5px 25px;
    border-radius: 8px;
    min-width: 325px;
  }

  .modal-lg {
    max-width: 894px !important;
  }

  .withdraw-saving {
    .amount {
      padding-bottom: 40px !important;
    }
    .input-box {
      padding: 60px 0px 100px 0px;
      input {
        text-align: center;
      }
    }
    .withdraw-btn {
      @include flexbox();
      @include justify-content(center);
      padding: 30px 0px 80px 0px;
      .custom-btn {
        margin-right: 0px;
      }
    }
  }

  .confirm-withdraw {
    .amount {
      padding-bottom: 10px !important;
    }
    .payment-type {
      @include flexbox();
      @include justify-content(center);
      font-size: $headingSection;
      color: $offBlack;
      line-height: 38px;
      font-family: Avenir-Medium;
      margin-bottom: 20px;
      .upi {
        cursor: pointer;
        width: 280px;
        height: 43px;
        background-color: $greyWhite;
        border-radius: 14px 0px 0px 14px;
        @include flexbox();
        @include align-items(center);
        @include justify-content(center);
      }
      .banking {
        cursor: pointer;
        width: 280px;
        height: 43px;
        background-color: $greyWhite;
        border-radius: 0px 14px 14px 0px;
        @include flexbox();
        @include align-items(center);
        @include justify-content(center);
      }
      .active-payment {
        background-color: $savvyYellow;
        font-family: Avenir-DemiBold;
      }
    }
    .payment-methods-group {
      @include flexbox();
      @include justify-content(center);
      margin-bottom: 35px;
      .payment-method-box {
        margin: 0px 20px;
        cursor: pointer;
        text-align: center;
        @include flexbox();
        @include align-items(center);
        @include flex-direction(column);
        @include justify-content(center);
        .payment-method {
          width: 56px;
          height: 56px;
          border-radius: 100%;
          border: 2px solid $greyWhite;
          margin-bottom: 6px;
          overflow: hidden;
          @include flexbox();
          @include align-items(center);
          @include justify-content(center);
          img {
            object-fit: contain;
          }
        }
        .label {
          font-family: Avenir-Medium;
          color: $offBlack;
          font-size: $textParagraphOne;
        }
        .active-payment-method {
          border: 2px solid #f47a23;
        }
      }
    }
    .bank-details {
      text-align: center;
      font-family: Avenir-Medium;
      color: $offBlack;
      font-size: $textParagraphOne;
    }
    .horizontal-line-box {
      padding: 6px 100px 40px 100px;
      .horizontal-line {
        height: 1px;
        width: 100%;
        background-color: #e2e2e2;
      }
    }
    .input-box {
      @include flexbox();
      @include align-items(center);
      @include justify-content(center);
      margin-bottom: 25px;
      .label {
        font-family: Avenir-DemiBold;
        font-size: $headingSection;
        margin-right: 20px;
        color: $offBlack;
      }
      input {
        font-size: $headingSection;
        height: 54px;
        font-family: Avenir-Medium;
        text-align: left;
        max-width: 350px;
        border-radius: 7px;
        text-align: center;
      }
    }
    .confirm-btn {
      @include flexbox();
      @include justify-content(center);
      padding: 30px 0px 30px 0px;
      .custom-btn {
        margin-right: 0px;
      }
      .mt-extra {
        margin-top: 100px;
      }
    }
  }

  .withdrawal-successfull {
    .tick {
      text-align: center;
      padding-bottom: 22px;
    }
    .title {
      padding-bottom: 80px;
    }
    .done-btn {
      @include flexbox();
      @include justify-content(center);
      padding: 30px 0px 30px 0px;
      .custom-btn {
        margin-right: 0px;
      }
    }
    .amount {
      margin-bottom: 10px;
    }
    .description {
      color: $greyBlack;
      font-family: Avenir-Regular;
      font-size: $headingSection;
      text-align: center;
      padding-bottom: 100px;
    }
  }

  .timer {
    .payment {
      font-size: $headingMain;
      font-family: Avenir-DemiBold;
      color: $offBlack;
      text-align: center;
    }
    .amount {
      font-size: $headingSection;
      font-family: Avenir-Medium;
      color: $offBlack;
      text-align: center;
      margin-bottom: 50px;
    }
    .inner-box {
      margin: 0px 20px;
      background-color: $greyWhite;
      border-radius: 8px;
      padding: 20px 20px 10px 20px;
    }
    .description-one {
      font-size: $textParagraphOne;
      font-family: Avenir-Regular;
      color: $offBlack;
      text-align: center;
    }
    .description-two {
      font-size: $textParagraphOne;
      font-family: Avenir-Regular;
      color: $offBlack;
      text-align: center;
    }
    .fetch-transaction {
      text-align: center;
      padding: 1rem 0;
    }
    .title {
      font-size: $headingSection;
      font-family: Avenir-DemiBold;
      color: $offBlack;
      text-align: center;
    }
    .countdown {
      @include flexbox();
      @include justify-content(center);
      padding: 50px 0px 20px 0px;
      .remaining-time {
        font-size: $headingSection;
        font-family: Avenir-DemiBold;
        color: $offBlack;
        text-align: center;
        background-color: $pureWhite;
        width: 85px;
        height: 85px;
        border-radius: 100%;
        @include flexbox();
        @include justify-content(center);
        @include align-items(center);
      }
    }
    .cancel {
      text-align: center;
      padding: 60px 0px 40px 0px;
      .cancel-payment {
        font-size: $textParagraphOne;
        font-family: Avenir-Regular;
        color: $offBlack;
        text-align: center;
        cursor: pointer;
        text-decoration: underline;
        text-decoration-color: $savvyYellow;
      }
    }
  }
}

.disabled-btn {
  opacity: 0.5;
  cursor: not-allowed;
}

@include screen-below('lg') {
  .modal-content {
    min-width: unset;
  }

  .save-more-modal {
    .cross-icon {
      @include flexbox();
      @include justify-content(flex-end);
      padding: 20px 20px 0px 0px;
      span {
        cursor: pointer;
      }
      img {
        opacity: 0.7;
        width: 20px;
      }
      margin-bottom: 15px;
    }

    .title {
      font-size: $headingMain;
      color: $greyBlack;
      line-height: 38px;
      font-family: Avenir-Regular;
      text-align: center;
      margin-bottom: 5px;
    }

    .amount {
      font-size: $headingMain;
      color: $greyBlack;
      line-height: 38px;
      font-family: Avenir-DemiBold;
      text-align: center;
    }

    .text-input {
      max-width: 400px;
      width: 100%;
      height: 64px;
      background-color: $greyWhite;
      outline: unset;
      border: unset;
      font-size: $textParagraphOne;
      padding: 0px 20px;
      border-radius: 4px;
    }

    .input-box {
      @include flexbox();
      @include flex-direction(column);
      @include justify-content(center);
      @include align-items(center);
      .error {
        padding-top: 10px;
        color: $errorRed;
      }
    }

    .custom-btn {
      font-size: $headingSection;
      background-color: $savvyYellow;
      font-family: Avenir-DemiBold;
      color: $offBlack !important;
      border: 1px solid $savvyYellow;
      padding: 5px 25px;
      border-radius: 8px;
      min-width: 325px;
    }

    .modal-lg {
      max-width: 894px !important;
    }

    .withdraw-saving {
      padding: 20px;
      .amount {
        font-size: $headingSection;
        padding-bottom: 20px !important;
      }
      .title {
        font-size: $headingSection;
        line-height: 30px;
      }
      .input-box {
        padding: 60px 0px 70px 0px;
        input {
          height: 55px;
          text-align: center;
        }
      }
      .withdraw-btn {
        @include flexbox();
        @include justify-content(center);
        padding: 30px 0px 80px 0px;
        .custom-btn {
          margin-right: 0px;
        }
      }
    }

    .confirm-withdraw {
      .amount {
        padding-bottom: 10px !important;
        font-size: $headingSection;
        line-height: 30px;
      }
      .title {
        font-size: $headingSection;
        line-height: 30px;
      }
      .payment-type {
        padding: 10px 20px;
        @include flexbox();
        @include justify-content(center);
        font-size: $headingSectionThree;
        color: $offBlack;
        line-height: 38px;
        font-family: Avenir-Medium;
        margin-bottom: 20px;
        .upi {
          cursor: pointer;
          width: 280px;
          height: 43px;
          background-color: $greyWhite;
          border-radius: 14px 0px 0px 14px;
          @include flexbox();
          @include align-items(center);
          @include justify-content(center);
        }
        .banking {
          cursor: pointer;
          width: 280px;
          height: 43px;
          background-color: $greyWhite;
          border-radius: 0px 14px 14px 0px;
          @include flexbox();
          @include align-items(center);
          @include justify-content(center);
        }
        .active-payment {
          background-color: $savvyYellow;
          font-family: Avenir-DemiBold;
        }
      }
      .payment-methods-group {
        @include flexbox();
        @include justify-content(center);
        @include flex-wrap(wrap);
        margin-bottom: 35px;
        .payment-method-box {
          margin: 0px 20px;
          cursor: pointer;
          text-align: center;
          @include flexbox();
          @include align-items(center);
          @include flex-direction(column);
          @include justify-content(center);
          margin-bottom: 20px;
          .payment-method {
            width: 36px;
            height: 36px;
            border-radius: 100%;
            border: 2px solid $greyWhite;
            margin-bottom: 6px;
            overflow: hidden;
            @include flexbox();
            @include align-items(center);
            @include justify-content(center);
            img {
              object-fit: contain;
              width: 100%;
            }
          }
          .label {
            font-family: Avenir-Medium;
            color: $offBlack;
            font-size: $textParagraphThree;
          }
          .active-payment-method {
            border: 2px solid #f47a23;
          }
        }
      }
      .bank-details {
        text-align: center;
        font-family: Avenir-Medium;
        color: $offBlack;
        font-size: $textParagraphOne;
      }
      .horizontal-line-box {
        padding: 6px 100px 40px 100px;
        .horizontal-line {
          height: 1px;
          width: 100%;
          background-color: #e2e2e2;
        }
      }
      .input-box {
        @include flexbox();
        @include align-items(center);
        @include justify-content(center);
        margin-bottom: 25px;
        .label {
          font-family: Avenir-DemiBold;
          font-size: $headingSectionTwo;
          margin-right: 20px;
          color: $offBlack;
        }
        input {
          font-size: $headingSection;
          height: 44px;
          font-family: Avenir-Medium;
          text-align: left;
          max-width: 320px;
          border-radius: 7px;
          text-align: center;
        }
      }
      .confirm-btn {
        @include flexbox();
        @include justify-content(center);
        padding: 30px 0px 30px 0px;
        .custom-btn {
          margin-right: 0px;
        }
        .mt-extra {
          margin-top: 100px;
        }
      }
    }

    .withdrawal-successfull {
      .tick {
        text-align: center;
        padding-bottom: 22px;
      }
      .title {
        padding-bottom: 80px;
      }
      .done-btn {
        @include flexbox();
        @include justify-content(center);
        padding: 30px 0px 30px 0px;
        .custom-btn {
          margin-right: 0px;
        }
      }
      .amount {
        margin-bottom: 10px;
      }
      .description {
        color: $greyBlack;
        font-family: Avenir-Regular;
        font-size: $headingSection;
        text-align: center;
        padding-bottom: 100px;
      }
    }

    .timer {
      .custom-btn {
        min-width: 280px;
      }
      .payment {
        font-size: $headingMain;
        font-family: Avenir-DemiBold;
        color: $offBlack;
        text-align: center;
      }
      .amount {
        font-size: $headingSection;
        font-family: Avenir-Medium;
        color: $offBlack;
        text-align: center;
        margin-bottom: 50px;
      }
      .inner-box {
        margin: 0px 20px;
        background-color: $greyWhite;
        border-radius: 8px;
        padding: 20px 20px 10px 20px;
      }
      .description-one {
        font-size: $textParagraphOne;
        font-family: Avenir-Regular;
        color: $offBlack;
        text-align: center;
      }
      .description-two {
        font-size: $textParagraphOne;
        font-family: Avenir-Regular;
        color: $offBlack;
        text-align: center;
      }
      .fetch-transaction {
        text-align: center;
        padding: 1rem 0;
      }
      .title {
        font-size: $headingSection;
        font-family: Avenir-DemiBold;
        color: $offBlack;
        text-align: center;
      }
      .countdown {
        @include flexbox();
        @include justify-content(center);
        padding: 50px 0px 20px 0px;
        .remaining-time {
          font-size: $headingSection;
          font-family: Avenir-DemiBold;
          color: $offBlack;
          text-align: center;
          background-color: $pureWhite;
          width: 85px;
          height: 85px;
          border-radius: 100%;
          @include flexbox();
          @include justify-content(center);
          @include align-items(center);
        }
      }
      .cancel {
        text-align: center;
        padding: 60px 0px 40px 0px;
        .cancel-payment {
          font-size: $textParagraphOne;
          font-family: Avenir-Regular;
          color: $offBlack;
          text-align: center;
          cursor: pointer;
          text-decoration: underline;
          text-decoration-color: $savvyYellow;
        }
      }
    }
  }
}
