@import 'scss/theme';

@mixin commonBadgeStyles($color){
  padding: 0.25rem 0.5rem;
  border: 1px solid $color;
  border-radius: 5px;
  display: inline-block;
  color: $color;
  font-size: 0.8rem;
}

@mixin fillButtonStyles($color){
  padding: 0.25rem 0.5rem;
  border-radius: toRem(8);
  display: inline-block;
  color: $pureWhite;
  font-size: 0.8rem;
  background-color: $color;
  font-family: $avenirSemiBold;
}

.success {
  @include commonBadgeStyles($actionGreen)
}

.error {
  @include commonBadgeStyles($errorRed)
}

.info {
  @include commonBadgeStyles($savvyYellow)
}

.ongoing, .completed{
  @include fillButtonStyles($greenBright);
}

.expired{
  @include fillButtonStyles($redBright);
}

.booked{
  @include fillButtonStyles($savvyYellow);
}

.created{
  @include fillButtonStyles($skyblueBright);
}


