@import 'scss/variables.scss';
@import 'scss/media_queries.scss';

.container {
  margin-top: 2rem;
  .pageHeading {
    font-size: $pageHeading;
    font-weight: 600;
  }
  .card {
    border-radius: 5px;
    margin-bottom: 2rem;
    box-shadow: 0 3px 5px darken($gray-medium, 10%);
    .cardHeader {
      background-color: #fff;
      .cardHeading {
        font-size: $cardHeading;
        font-weight: 600;
      }
    }
    .cardBody {
      padding: 1rem 0;
    }
    .cardRow {
      margin-bottom: 1rem;
    }
    .cardSection {
      margin-bottom: 2rem;
    }
  }
}

@include screen-below('lg') {
  .cardCol {
    margin-bottom: 1rem;
  }
}
