@import 'scss/theme';

.multilineColumn {
  display: flex;
  flex-direction: column;
  > a {
    text-decoration: none;
  }
  .childOne {
    font-weight: bold;
  }
  .childTwo {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}
