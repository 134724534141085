@import 'scss/variables';
@import 'scss/flex';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';

.transaction-data-table {
  th {
    font-size: $headingSectionThree;
    color: $themeBlack;
  }
  td {
    font-size: $textParagraphOne;
    color: $themeBlack;

    .transactions-offer-uuid {
      text-overflow: ellipsis;
      width: 85px;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}
