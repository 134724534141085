@import 'scss/variables';
@import 'scss/theme';
@import 'scss/functions';

.inputLabelContainer {
  margin-bottom: 0.2rem;
  .largeLabel {
    font-size: $headingSectionThree;
    color: $themeBlack;
  }
}
.textFieldContainer{
  display: flex;
  align-items: center;
}
.prefix{
  margin-right: toRem(10);
  display: inline-block;
}
.suffix{
  display: inline-block;
  margin-left: toRem(10);
}
