@import 'scss/variables';
@import 'scss/flex';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';

.group-btns {
  @include flexbox();
  @include justify-content(center);
  .inner-btn {
    margin-right: 50px;
    &:nth-last-child(1) {
      margin-right: 0px;
    }
  }
  button {
    font-family: Avenir-DemiBold;
    font-size: 22px;
    color: rgba(0, 0, 0, 0.7);
    padding: 20px 35px;
    @include border-radius(10px);
    margin-bottom: 10px;
  }
  margin-bottom: 70px;
}

.close-btn {
  @include flexbox();
  @include justify-content(flex-end);
  img {
    opacity: 0.7;
    margin: 20px;
  }
}

.alert-icon {
  text-align: center;
  padding: 40px 0px 60px 0px;
}

.title {
  font-family: Avenir-DemiBold;
  font-size: 30px;
  color: rgba(0, 0, 0, 0.7);
  text-align: center;
  margin-bottom: 80px;
}
