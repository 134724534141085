.app-modal-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.75);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;

  &.app-modal-enter {
    opacity: 0;
  }

  &.app-modal-enter-active {
    opacity: 1;
    transition: opacity 300ms;
  }

  &.app-modal-exit {
    opacity: 1;
  }

  &.app-modal-exit-active {
    opacity: 0;
    transition: opacity 300ms;
  }

  .app-modal-body {
    &.app-modal-wrapper-enter {
      transform: scale(0);
    }

    &.app-modal-wrapper-enter-active {
      transform: scale(1);
      transition: transform 300ms;
    }

    &.app-modal-wrapper-exit {
      transform: scale(1);
    }

    &.app-modal-wrapper-exit-active {
      transform: scale(0);
      transition: transform 300ms;
    }
  }
}
