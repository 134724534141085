@import 'scss/variables';
@import 'scss/flex';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';

$minWidth: 453px;

.title-heading {
  @include flexbox();
  @include justify-content(space-between);
  @include align-items(center);
}
.pay-content {
  padding: 30px 80px 0px 40px;
  min-height: 400px;
  @include screen-below('lg') {
    padding: 0px 0px 0px 0px;

    .link {
      font-size: $headingSectionThree;
      font-family: Avenir-DemiBold;
      color: $footerBlack;
      opacity: 0.7;
      text-decoration: underline;
      text-align: center;
      padding-top: 25px;
      margin-bottom: 10px;
      cursor: pointer;
    }
  }
}

.list-group {
  padding-top: 40px;
  .list {
    font-size: $textParagraphOne;
    color: $pureBlack;
    font-family: Avenir-Regular;
    margin-bottom: 10px;
    span {
      font-family: Avenir-DemiBold;
    }
  }
}

.img-box {
  @include screen-below('lg') {
    padding: 0px 20px 0px 20px;
  }
}
.placeholder-outer {
  .placeholder {
    width: 235px;
    height: 236px;
    border: 2px dashed $greyWhiteFour;
    box-sizing: border-box;
    border-radius: 10px;
    @include flexbox();
    @include justify-content(center);
    @include align-items(center);
    .placeholder-inner {
      width: 192px;
      height: 202px;
      background: $savvyYellow;
      border-radius: 5px;
      @include flexbox();
      @include justify-content(center);
      @include align-items(center);
      @include flex-direction(column);
      color: $greyBlackTwo;
      font-family: Avenir-DemiBold;
    }
  }

  @include screen-below('lg') {
    padding: 0px 20px 0px 20px;
  }
}
.spacer {
  padding-bottom: 40px;
}
.header {
  @include flexbox();
  @include justify-content(center);
  padding: 15px 10px;
  .inner-header {
    @include flexbox();
    @include justify-content(flex-start);
    max-width: $minWidth;
    width: 100%;
    .sub-inner-header {
      text-align: center;
      width: 100%;
      .title {
        font-family: Avenir-DemiBold;
        color: $pureBlack;
        font-size: $headingSection;
        line-height: 28px;
        margin-bottom: 13px !important;
      }
      .price {
        font-family: Avenir-DemiBold;
        color: $pureBlack;
        font-size: $headingSection;
      }
      .note {
        color: $themeBlack;
        font-size: $textFinePrint;
        text-transform: uppercase;
        font-family: Avenir-Medium;
        margin-bottom: 6px;
      }
      .amount {
        color: $themeBlack;
        font-size: $textFinePrint;
        font-family: Avenir-Medium;
        @include flexbox();
        @include justify-content(center);
        @include align-items(center);
        img {
          margin-top: -3px;
          margin-right: 3px;
        }
      }
    }
  }
}

.label-style {
  background: $savvyYellow !important;
  color: $pureBlack !important;
  font-family: Avenir-Bold !important;
  width: 156px;
  border: unset !important;
  padding: 4px !important;
}

.pan-note {
  font-family: Avenir-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: $textFinePrint;
  color: $pureBlack;
  line-height: 15px;
  margin-bottom: 69px;
}
.mobile-space {
  padding-left: 0px;
  padding-right: 0px;
  @include screen-below('lg') {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.mobile {
  display: none;
  @include screen-below('lg') {
    display: block;
  }
}

.line-mob {
  height: 4px;
  width: 24px;
  background-color: $savvyYellow;
  margin-bottom: 20px;
}
.progress-bar {
  height: 4px;
  width: 100%;
  background-color: $greyWhite;
}

.active-progress-bar {
  background-color: $savvyYellow;
  height: 4px;
}

.another-account-overlay {
  position: relative;
}
.another-account {
  @include flexbox();
  @include flex-direction(column);
  height: 100%;
  @include justify-content(space-between);
  .title {
    font-size: $headingSection;
    color: $pureBlack;
    font-family: Avenir-DemiBold;
    margin-bottom: 10px;
  }
  .line {
    height: 4px;
    width: 24px;
    background-color: $savvyYellow;
  }
  .sub-title {
    font-size: $headingSectionThree;
    font-family: Avenir-DemiBold;
    color: $footerBlack;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .sub-title-light {
    font-size: $headingSectionThree;
    font-family: Avenir-Regular;
    color: $footerBlack;
    margin-bottom: 15px;
  }

  .form-box {
    label {
      font-size: $headingSectionThree;
      font-family: Avenir-Regular;
      color: $footerBlack;
      opacity: 0.9;
      margin-bottom: 6px;
    }
    .input-box {
      @include border-radius(8px);
      max-width: 343px;
      width: 100%;
      height: 35px;
      font-size: 15px;
      font-family: Avenir-Medium;
      border: 1px solid $disabled-medium;
      outline: none;
      color: $footerBlack;
      background-color: transparent;
      padding: 0px 15px;
      &:disabled {
        background-color: $disabled-light;
      }

      &:focus {
        border: 1px solid $primary;
        box-shadow: 0 0 2px $primary;
      }
      margin-bottom: 5px;
    }
  }

  @include screen-below('lg') {
    position: relative;
    .form-box {
      label {
        font-size: $textParagraphOne;
      }
    }
    .link {
      padding-top: 93px;
    }
    .pay-content {
      // height: 100vh;
      .title {
        display: none;
      }
      .title-heading {
        display: block;
        padding: 20px 20px 0px 20px;
      }
      .line {
        display: none;
      }
      .sub-title {
        font-size: $textParagraphOne;
        color: $footerBlack;
        padding: 30px 20px 4px 20px;
      }
      .sub-title-light {
        font-size: $textParagraphOne;
        color: $footerBlack;
        padding: 0px 20px 0px 20px;
        line-height: 20px;
        margin-bottom: 5px;
      }
    }
  }
}

.footer {
  height: 70px;
  width: 100%;
  background-color: $savvyYellow;
  border-radius: 0px 0px 0px 8px;
  color: $pureBlack;
  font-size: $headingSection;
  @include flexbox();
  @include justify-content(center);
  @include align-items(center);
  font-family: Avenir-DemiBold;
  cursor: pointer;
  @include screen-below('lg') {
    border-radius: 0px 0px 0px 0px;
  }
}
