@import 'scss/variables';
@import 'scss/flex';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';

.offer-tabs {
  box-shadow: 0px 3px 5px #ccc;
  border: 1px solid #eee;
  border-radius: 10px;
  text-align: left;
  margin-bottom: 35px;

  .title {
    font-size: 1.2rem;
    padding: 0.5rem 1.5rem 0 1.5rem;
    color: $dark;
    text-align: center;
    padding: 20px 0px;
    font-family: Avenir-DemiBold;
  }
  .tab-links {
    border-top: 1px solid #eee;

    .nav-item {
      flex: 1;
      background-color: #f3f3f3;

      &:first-child {
        padding-right: 1px;
      }

      .nav-link {
        text-align: center;
        padding: 0.5rem 0;

        h4 {
          font-family: Avenir-Regular;
          font-size: 1.3rem;
          color: $dark;
        }
        img {
          height: 60px;
          width: 60px;
          margin-bottom: 10px;
        }

        &.active {
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
          border-radius: 0px 0px 4px 4px;

          h4 {
            font-family: Avenir-DemiBold;
          }
        }
      }
    }
  }

  @include screen-below('lg') {
    .title {
      font-size: 1.1rem;
    }
    .tab-links {
      .nav-item {
        .nav-link {
          h4 {
            font-size: 1rem;
          }
        }
      }
    }
  }
}
