@import 'scss/theme';
@import 'scss/functions';

@mixin commonStyles(){
  color: $footerBlack;
  &.semiBold{
    font-family: $avenirSemiBold;
  }
  &.block{
    display: block;
  }
  &.center{
    text-align: center;
  }
}

.main{
  @include commonStyles();
  font-size: toRem(14);
  font-family: $avenirLight;
}

.head{
  @include commonStyles();
  font-size: toRem(16);
  font-family: $avenirSemiBold;
}

.link{
  @include commonStyles();
  font-size: toRem(14);
  font-family: $avenirLight;
  text-decoration: underline;
  cursor: pointer;
}

.error{
  @include commonStyles();
  color: $errorRed;
  font-size: 0.8rem;
  font-family: $fontFamilyRegular;
}