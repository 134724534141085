@import 'scss/variables';

.textarea {
  resize: none;
  margin-bottom: 0.2rem;

  .inputLabel {
    font-family: Avenir-DemiBold;
    font-size: $inputLabel;
  }
}
