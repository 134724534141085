@import 'scss/media_queries';

.overview {
  padding: 0 3rem 0 2rem;
  .left-space {
    padding-left: 3rem;
  }
  .right-space {
    height: 100%;
    padding-right: 1rem;
  }
  .transactions {
    padding-top: 30px;
    margin-bottom: 30px;
  }
}

@include screen-below('lg') {
  .overview {
    padding: 1rem 0 0 0;
    .left-space {
      padding-left: 0rem;
    }
    .right-space {
      padding-right: 0rem;
    }
    .right-side {
      padding-top: 20px;
    }
  }
}
