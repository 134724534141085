@import 'scss/variables';
@import 'scss/flex';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';

.modal-content {
  border: unset !important;
  border-radius: 10px !important;
}

.create-offer-btn {
  margin-bottom: 100px;
  button {
    font-size: $headingSection;
    padding: 10px 40px;
  }
}
