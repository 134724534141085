@import 'scss/theme';

.pagination{
  .page-item.active .page-link{
    background-color: $savvyYellow;
    border-color: $savvyYellow;
  }
  .page-link{
    color: $savvyYellow;
    &:hover{
      color: $savvyYellow;
    }
  }
}