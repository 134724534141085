@import 'scss/variables.scss';
@import 'scss/media_queries.scss';

.pageHeading {
    font-size: $pageHeading;
    font-weight: 600;
}
.card {
    border-radius: 10px;
    margin-bottom: 2rem;
    box-shadow: 0 3px 5px darken($gray-medium, 10%);
    .cardHeader {
        background-color: $peach;
        .cardHeading {
            font-size: $cardHeading;
            font-weight: 600;
        }
    }
    .cardBody {
        padding: 1rem 0;
    }
    .cardRow,
    .field {
        margin-bottom: 1rem;
    }
    .cardSection {
        padding: 0 1rem;
        margin-bottom: 1rem;
    }
}

.solidHeadingStyles {
    font-family: 'Avenir-DemiBold';
}

.helpText {
    font-size: 0.78rem;
    margin-left: 0.3rem;
    color: #9b9b9b;
}

@include screen-below('lg') {
    .cardCol {
        margin-bottom: 1rem;
    }
}
