@import 'scss/variables';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';
@import 'scss/flex';

.sign-in-sign-up-modal {
  .modal-dialog {
    .modal-content {
      min-width: unset;
      width: 100%;
      border-radius: 12px;
      padding: 40px;

      .loginContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
        position: relative;
        flex-basis: 50%;

        .loginBox {
          text-align: center;

          .resend-otp-box {
            @include flexbox();
            @include justify-content(center);
            @include align-items(center);
            margin-top: 1rem;
          }

          .remaining-time {
            font-size: $textFinePrint;
            padding-top: 5px;
          }

          .link {
            color: $gray-2;
            display: inline-block;
          }
          .crossIcon {
            position: absolute;
            right: -20px;
            top: -25px;

            span {
              cursor: pointer;
            }
            img {
              opacity: 0.8;
              width: 18px;
            }
            margin-bottom: 15px;
          }
          .loginTitle {
            font-family: Avenir-DemiBold;
            font-size: $headingMain;
            margin-bottom: 2rem;
            color: $greyBlack;
          }
          .label {
            font-family: Avenir-Regular;
            font-size: $headingSection;
            margin-bottom: 0.5rem;
            color: $greyBlack;
          }
          .input {
            border-radius: 0.5rem;
            height: 62px;
            font-family: Avenir-Regular;
            border-color: $greyBlack;
            text-align: center;
            font-size: $headingSection;
            max-width: 458px;
            width: 100%;
            border: 1px solid $greyBlack;
          }
          ::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: $greyBlack;
            opacity: 0.6; /* Firefox */
          }

          :-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: $greyBlack;
          }

          ::-ms-input-placeholder {
            /* Microsoft Edge */
            color: $greyBlack;
          }

          ::-webkit-inner-spin-button {
            -webkit-appearance: none;
          }

          .submitBtn {
            @include long-kyc-steps-button();
            color: $pureBlack;
            margin-top: 3rem;
            margin-bottom: 0;
            background-color: $savvyYellow;
          }
        }
      }
    }
  }
}
