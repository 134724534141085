@import 'scss/variables';

.app-drop-down {
  .input-container {
    position: relative;

    .app-drop-down-toggle-button {
      border: none;
      background-color: transparent;
      position: absolute;
      // top: 24px;
      // right: 0;
      // height: 36px;
      // width: 50px;
      top: 24px;
      left: 0;
      height: 36px;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      cursor: pointer;

      img {
        position: relative;
        right: 15px;
      }
    }
  }

  .app-drop-down-overlay {
    background-color: $dark;
    padding: 1rem;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    width: 285px;

    .app-drop-down-body {
      display: flex;
      flex-direction: column;
      max-height: 50vh;
      overflow-y: scroll;

      .app-drop-down-item {
        background-color: transparent;
        border: none;
        color: $primary;
        font-size: 0.9rem;
        border-bottom: 1px solid $primary;
        padding: 0.75rem;
        text-align: left;
        cursor: pointer;
        position: relative;

        &:last-child {
          border-bottom: none;
        }

        .selected-icon {
          width: 22px;
          height: 22px;
          position: absolute;
          right: 10px;
          top: 10px;
        }
      }
    }

    .app-drop-down-footer {
      display: flex;
      flex-direction: column;

      .app-drop-down-confirm-button {
        margin: 0.5rem 0;
      }
    }
  }
}
