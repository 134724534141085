@import 'scss/variables';
@import 'scss/flex';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';

.link-bank {
  .title {
    color: $greyBlack;
    font-size: $headingMain;
    font-family: Avenir-DemiBold;
    margin-top: 2rem;
    margin-bottom: 1rem;
    padding-left: 0px;
  }
  .sub-title {
    color: $greyBlack;
    font-size: $headingSectionThree;
    font-family: Avenir-Regular;
  }
  .custom-btn {
    button {
      font-size: $headingSectionThree;
    }
  }
  .bank-account-input {
    padding-top: 40px;
    padding-bottom: 2rem;
    .ba-group-input {
      margin-bottom: 1rem;
      label {
        font-family: Avenir-DemiBold;
        font-size: $headingSection;
        line-height: 21px;
        margin-bottom: 10px;
        color: $greyBlack;
      }
      .ba-input {
        @include border-radius(8px);
        max-width: 563px;
        width: 100%;
        height: 48px;
        font-size: 15px;
        font-family: Avenir-Medium;
        border: 1px solid #ccc;
        outline: none;
        color: #333;
        background-color: transparent;
        padding: 0px 15px;
        &:disabled {
          background-color: $disabled-light;
        }

        &:focus {
          border: 1px solid $primary;
          box-shadow: 0 0 2px $primary;
        }
        margin-bottom: 5px;
      }
    }
    .link-account {
      font-size: 0.9rem;
      display: inline-block;
      color: $dark;
      background-color: transparent;
      border: none;
      text-decoration: underline;
      cursor: pointer;
      margin-bottom: 60px;
    }

    @include screen-below('lg') {
      padding: 0 1rem;

      .ba-group-input {
        label {
          font-family: Avenir-DemiBold;
          font-size: 16px;
          line-height: 21px;
          margin-bottom: 6px;
          color: rgba(0, 0, 0, 0.7);
        }
        .ba-input {
          @include border-radius(8px);
          max-width: 563px;
          width: 100%;
          height: 48px;
          font-size: 15px;
          font-family: Avenir-Medium;
          border: 1px solid #ccc;
          outline: none;
          color: #333;
          background-color: transparent;
          padding: 0px 15px;
          &:disabled {
            background-color: $disabled-light;
          }

          &:focus {
            border: 1px solid $primary;
            box-shadow: 0 0 2px $primary;
          }
        }
        margin-bottom: 20px;
      }

      .link-account {
        width: 100%;
      }
    }
  }

  .encrypt {
    padding-top: 2px;
    padding-bottom: 15px;
    .encrypt-inner {
      padding: 10px 0px 5px 0px;
      text-align: center;
      .encrypt-text {
        color: $greyBlack;
        font-size: $textFinePrint;
        font-style: italic;
        margin-bottom: 5px;
      }
      .encrypt-images {
        img {
          height: 50px;
        }
      }
    }
  }
}
