.footer {
  height: 25px;
  color: #999;
  padding: 0 0.5rem;
  border-top: 1px solid #0000004d;

  .link {
    color: #999;
    font-size: 0.8rem;
    text-decoration: none;
    margin: 0 5px;

    &:hover {
      text-decoration: underline;
    }
  }
}