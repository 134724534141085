@import 'scss/variables';
@import 'scss/flex';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';

.fatcha {
  padding: 2rem 3rem;
  .pv-to-start {
    font-size: $h3;
    line-height: 30px;
    margin-bottom: 60px;
    margin-top: 30px;
  }
  .pv-next-btn {
    background-color: $savvy-yellow;
    @include border-radius(7px);
    min-width: 140px;
    height: 44px;
    font-family: Avenir-DemiBold;
    font-size: $headingSection;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .pv-group-input {
    label {
      font-family: Avenir-DemiBold;
      font-size: $textParagraphOne;
      line-height: 24px;
      margin-bottom: 10px;
    }

    .custom-select {
      margin-bottom: 15px;
    }
    .pv-pan-verify-input {
      @include border-radius(8px);
      max-width: 563px;
      width: 100%;
      height: 48px;
      font-size: $textParagraphOne;
      font-family: Avenir-Medium;
      border: 1px solid #ccc;
      outline: none;
      color: #333;
      background-color: transparent;
      padding: 0px 15px;
      &:disabled {
        background-color: $disabled-light;
      }

      &:focus {
        border: 1px solid $primary;
        box-shadow: 0 0 2px $primary;
      }
      margin-bottom: 15px;
    }
    .pv-description {
      font-family: Avenir-Medium;
      font-size: 12px;
      line-height: $textParagraphOne;
      color: rgba(0, 0, 0, 0.7);
      margin-bottom: 120px;
    }

    .message {
      font-family: Avenir-Medium;
      color: rgba(0, 0, 0, 0.7);
      font-size: 12px;
      font-style: italic;
      margin-bottom: 5px;
    }
    .partner-imgs {
      padding-bottom: 55px;
      img {
        margin-right: 12px;
      }
    }
  }
}
