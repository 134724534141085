@import 'scss/media_queries';
@import 'scss/variables';
@import 'scss/_theme';
@import 'scss/_flex';

.transactions {
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
  .inner-content {
    border: 1px solid #c2c2c2;
    border-radius: 8px;
    overflow: hidden;
  }
  .header {
    background-color: $pureWhite;
    text-align: center;
    font-size: $h2;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    padding: 0.2rem 3rem;
    color: rgba(0, 0, 0, 0.7);

    .description {
      text-align: center;
      font-family: Avenir-Bold;
      color: rgba(0, 0, 0, 0.7);
    }
    .date {
      display: inline;
      background-color: transparent;
      border: navajowhite;
      text-align: right;
      font-family: Avenir-DemiBold;
      color: $greyBlack;
      cursor: pointer;
      // pointer-events: none;

      &:focus {
        outline: none;
      }
    }
  }
  .header-tabs {
    @include flexbox();
    background-color: $pureWhite;
    color: rgba(0, 0, 0, 0.7);
    font-size: $h4;
    font-family: Avenir-DemiBold;
    .tab {
      cursor: pointer;
      &:first-child {
        margin-right: 30px;
      }
      padding-right: 20px;
      padding-top: 5px;
      padding-bottom: 5px;
      margin-bottom: -3px;
    }
    .active-tab {
      border-bottom: 3px solid $savvyYellow;
    }
    padding: 0px 5px 0px 50px;
    border: 1px solid #c2c2c2;
    border-right: unset;
    border-left: unset;
  }
  .header-row {
    display: flex;
    background-color: $pureWhite;
    color: rgba(0, 0, 0, 0.7);
    font-size: $h4;
    font-family: Avenir-DemiBold;
    padding: 5px 70px 5px 50px;

    .title {
      flex: 1;
    }
    .date {
      flex: 1;
      text-align: center;
    }
    .amount {
      flex: 1;
      text-align: right;
    }
    .status {
      flex: 1;
      text-align: center;
    }
    border-bottom: 1px solid #c2c2c2;
  }
  .body {
    overflow-y: auto;
    height: 250px;

    .row {
      display: flex;
      background-color: #f3f3f3;
      padding: 0.5rem 3rem 0.2rem 3rem;
      border-bottom: 1px solid #c2c2c2;
      &:nth-last-child(1) {
        border-bottom: unset;
      }
      .description {
        display: flex;
        flex: 1;
        flex-direction: column;
        color: rgba(0, 0, 0, 0.7);

        .title {
          font-size: $h5;
          color: black;
        }
      }
      color: black;
      .date {        
        font-size: $h5;
        flex: 1;
        text-align: center;
      }
      .amount {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        color: black;

        flex: 1;
        font-family: Avenir-Medium;

        .type {
          // color: green
          font-size: 0.6rem;

          &.red {
            color: $red-dark;
          }

          &.green {
            color: $green;
          }
        }
      }
      .status {        
        flex: 1;
        text-align: center;
        font-size: 0.9rem;

        .error {
          color: $red-dark;
        }

        .completed {
          color: $green;
        }
        .in_progress, .pending_investment {
          color: $savvyYellow;
        }
      }

      background-color: white;

      &.withdrawal {
        .amount {
          .type {
            color: red;
          }
        }
      }
    }
  }

  &.overview {
    padding: 0;
    height: 100%;
    background-color: $greyWhite;
    padding: 1.5rem 1rem 1.3rem 1rem;
    border-radius: 20px;
  }
}

.example-custom-input {
  cursor: pointer;
  padding: 5px 15px;
  border: 0;
  border-radius: 4px;
  background-color: #216ba5;
  font: inherit;
  color: #fff;
}

@include screen-below('lg') {
  .transactions {
    padding: 0;

    .overview {
      margin-bottom: 2rem;
    }

    .header {
      padding: 1rem 0.5rem;
    }

    .body {
      .row {
        padding: 0.5rem;
      }
    }
  }
}
