@import 'scss/variables';
@import 'scss/flex';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';

.save-btn {
  @include flexbox();
  @include justify-content(center);
  width: 100%;
  button {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.error-box {
  font-size: $textFinePrint;
  color: $errorRed;
  margin-top: 0.1rem;
}

.label {
  font-weight: 600;
  font-size: $inputLabel;
}
