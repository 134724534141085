@import 'scss/variables';

.container {
  padding: 0.25rem 0.5rem;
  border: 1px solid $green;
  border-radius: 5px;
  display: inline-block;
  color: $green;
  font-size: 0.8rem;
}

.success {
  border: 1px solid $green;
  color: $green;
}

.error {
  border: 1px solid $red;
  color: $red;
}

.info {
  border: 1px solid $primary;
  color: $primary;
}
