@import 'scss/variables';
@import 'scss/flex';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';

.goal-details {
  .error {
    color: #ff0000 !important;
  }
  .success {
    color: #1eb605 !important;
  }

  .custom-btn-group {
    @include flexbox();
    @include justify-content(center);
    padding: 30px 20px;
    width: 100%;
    .custom-btn {
      font-size: $headingSection;
      background-color: $savvyYellow;
      font-family: Avenir-DemiBold;
      color: $offBlack !important;
      border: 1px solid $savvyYellow;
      padding: 8px 65px;
      border-radius: 8px;
      min-width: 250px;
    }
  }
  .main-heading {
    padding: 10px 35px 0px 35px;
    .title {
      font-size: 18px;
      color: rgba(0, 0, 0, 0.7);
      font-family: 'Avenir-DemiBold';
      margin-bottom: 10px;
    }
    .content {
      @include flexbox();

      .content-box {
        padding: 0px 25px 30px 0px;
        .label {
          font-size: 40px;
          color: rgba(0, 0, 0, 0.7);
          font-family: 'Avenir-Regular';
          text-align: center;
          line-height: 40px;
        }
        .text {
          text-align: center;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.7);
          font-family: 'Avenir-Regular';
          white-space: pre;
        }
      }
    }
  }

  .main-content {
    background-color: #fff;
    border-top: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
    .heading {
      @include flexbox();
      @include justify-content(space-between);
      .offer-detail-heading {
        font-family: 'Avenir-DemiBold';
        color: rgba(0, 0, 0, 0.7);
        font-size: 18px;
      }
      padding: 10px 35px;
      border-top: 1px solid #d9d9d9;
      border-bottom: 1px solid #d9d9d9;
      .dropdown {
        button {
          background-color: #f3f3f3;
          color: rgba(0, 0, 0, 0.7);
          border: unset;
          text-transform: uppercase;
          &:focus {
            box-shadow: unset;
          }
        }
        .dropdown-menu {
          width: 100%;
        }
      }
    }
    .offer-detail {
      padding: 20px 35px;
      .offer-detail-content {
        @include flexbox();
      }
      .agent-id {
        @include flexbox();
        @include justify-content(center);
        padding: 20px 0px 5px 0px;
      }
      .lt-content {
        @include flexbox();
        margin-bottom: 2px;
      }
      .label {
        font-family: 'Avenir-Regular';
        color: rgba(0, 0, 0, 0.7);
        font-size: 18px;
        margin-right: 5px;
      }
      .text {
        font-family: 'Avenir-DemiBold';
        color: rgba(0, 0, 0, 0.7);
        font-size: 18px;
      }
      .text-green {
        color: #1eb605;
      }
      .text-danger {
        color: #ff0000;
      }
      .left-side {
        width: 49%;
        padding-top: 15px;
        padding-bottom: 25px;
        padding-right: 2%;
      }
      .center {
        border-left: 1px solid #d9d9d9;
        width: 1%;
      }
      .right-side {
        width: 49%;
        padding-left: 5%;
        padding-top: 15px;
        padding-bottom: 25px;
      }
    }
    table {
      width: 100%;
      thead {
        border-top: 1px solid #d9d9d9;
        border-bottom: 1px solid #d9d9d9;
        tr {
          th {
            padding: 8px 0px;
            font-size: 16px;
            color: rgba(0, 0, 0, 0.7);
            font-family: 'Avenir-DemiBold';
            &:nth-child(1) {
              padding-left: 37px;
            }
          }
        }
      }
      tbody {
        tr {
          &:nth-child(odd) {
            background-color: #f3f3f3;
            td {
              padding: 16px 0px;
            }
          }
          &:nth-child(even) {
            td {
              line-height: 5px;
            }
          }
          td {
            font-size: 14px;
            color: #000000;
            font-family: 'Avenir-Medium';
            &:nth-child(1) {
              padding-left: 37px;
            }
          }
        }
      }
    }
  }
}
