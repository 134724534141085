@import 'scss/media_queries.scss';

.videoContentContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 0;
  position: relative;
  
  .item{
    width: 60%;
    margin-bottom: 1rem;
  }
  .button{
    width: 30%;
    flex-direction: column;
    align-items: center;
    display: flex;
  }
  .divider{
    text-align: center;
    font-size: 2rem;
    margin: 0.6rem 0;
    font-family: Avenir-Medium;
  }
}

@include screen-below('lg'){
  .videoContentContainer{
    align-items: unset;
    .item{
      width: auto;
    }
    .button{
      width: auto;
    }
  }
}