@import 'scss/media_queries';
@import 'scss/variables';
@import 'scss/_theme';
@import 'scss/_flex';

.goal-details {
  .complete-steps {
    text-align: center;
    margin-bottom: 1rem;
  }
  .banner {
    margin: 0 2rem 2.5rem 2rem;
    border: 1px solid $promptGrey;
    border-radius: 8px;
    @include flexbox();
    @include justify-content(space-between);
    & > div {
      text-align: center;
      width: 100%;
      font-family: Avenir-DemiBold;
      font-size: $h4;
      .label {
        color: black;
      }
      .text {
        color: $success-light;
      }
      padding: 20px 10px;

      &:nth-child(2) {
        border-right: 1px solid $promptGrey;
        border-left: 1px solid $promptGrey;
      }
    }
  }

  .next-date {
    font-family: Avenir-DemiBold;
    color: black;
    border: 1px solid $promptGrey;
    border-radius: 8px;
    padding: 20px;
    margin: 2rem 2rem 0 2rem;
    text-align: center;
  }

  .custom-btns {
    @include flexbox();
    @include justify-content(space-between);
    padding: 40px 30px;
    .custom-btn {
      font-size: $h2;
      background-color: $savvyYellow;
      font-family: Avenir-DemiBold;
      color: black !important;
      border: 1px solid $savvyYellow;
      padding: 10px 25px;
      border-radius: 8px;
      min-width: 325px;
    }
  }

  @include screen-below('lg') {
    .custom-btns {
      @include flexbox();
      @include justify-content(space-between);
      @include flex-direction(column);
      padding: 0px 10px;
      padding-top: 20px;
      .custom-btn {
        font-size: $h2;
        background-color: $savvyYellow;
        font-family: Avenir-DemiBold;
        color: black !important;
        border: 1px solid $savvyYellow;
        padding: 10px 25px;
        border-radius: 8px;
        min-width: 325px;
        margin-bottom: 20px;
      }
    }
  }
}
