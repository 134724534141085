@import 'scss/media_queries';
@import 'scss/variables';
@import 'scss/_theme';
@import 'scss/_flex';

.account-details {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem 0;

  .title {
    font-family: Avenir-DemiBold;
    color: rgba(0, 0, 0, 0.7);
    margin: 0 0 10px 0;
    font-size: $h2;
  }

  .description {
    font-family: Avenir-Regular;
    font-size: $h2;
    color: rgba(0, 0, 0, 0.7);
    margin-bottom: 0.5rem;
  }

  .wrapper {
    background-color: #f3f3f3;
    text-align: left;
    padding: 1rem;
    min-width: 390px;
    position: relative;
    border-radius: 4px;
    .protech {
      position: absolute;
      right: 10px;
      bottom: 20px;
    }
    p {
      margin: 0 0 10px 0;
      color: rgba(0, 0, 0, 0.7);
      font-size: $h2;
      &:last-child {
        margin: 0;
      }

      strong {
        font-family: Avenir-Bold;
        color: rgba(0, 0, 0, 0.7);
      }
    }

    .secure-shield-lottie {
      width: 50px;
      height: 50px;
      position: absolute;
      bottom: 10px;
      right: 10px;
    }
  }

  &.overview {
    background-color: #f3f3f3;
    padding: 1.7rem 1rem;
  }

  @include screen-below('lg') {
    .wrapper {
      width: 100%;
      background-color: #f3f3f3;
      text-align: left;
      padding: 1rem;
      min-width: unset;
      position: relative;
      border-radius: 4px;
      .protech {
        position: absolute;
        right: 10px;
        bottom: 20px;
      }
      p {
        margin: 0 0 10px 0;
        color: rgba(0, 0, 0, 0.7);
        font-size: $headingSectionTwo;
        &:last-child {
          margin: 0;
        }

        strong {
          font-family: Avenir-Bold;
          color: rgba(0, 0, 0, 0.7);
        }
      }

      .secure-shield-lottie {
        width: 50px;
        height: 50px;
        position: absolute;
        bottom: 10px;
        right: 10px;
      }
    }
  }
}
