@import 'scss/variables.scss';

.partner-box {
  .message {
    font-family: Avenir-Medium;
    color: black;
    font-size: 15px;
    margin-bottom: 8px;
  }
  .partner-images {
    img {
      margin-right: 17px;
    }
  }
}
