@import 'scss/variables';
@import 'scss/flex';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';

.offer-accordion {
  .offer-accordion-item {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.09);
    border-radius: 15px;
    text-align: left;
    border: 1px solid $savvyYellow;
    margin-bottom: 35px;
    .offer-header {
      text-decoration: none;
      width: 100%;
      padding: 20px;
      box-shadow: none;
      border-radius: 15px;

      .offer-upper-header {
        padding-top: 25px;
        @include flexbox();
        .offer-header-tab-arrow {
          @include flexbox();
          @include align-items(center);
          padding: 0px 30px;
        }
        .offer-header-tab {
          .offer-header-title {
            color: $dark;
            font-size: $textParagraphThree;
            font-family: Avenir-Regular;
            text-align: left;
            span {
              font-family: Avenir-Medium;
            }
            line-height: 17px;
          }
          .offer-header-heading {
            color: $dark;
            font-size: $headingSectionTwo;
            font-family: Avenir-Medium;
            text-align: left;
          }
          .offer-header-heading-bold {
            color: $dark;
            font-size: $headingSectionTwo;
            font-family: Avenir-Regular;
            font-weight: 600;
            span {
              font-size: $textParagraphThree;
            }
          }
        }
      }

      .offer-header-wrapper {
        display: flex;
        flex: 1;

        .left {
          display: flex;
          font-size: $textParagraphThree;
          font-family: Avenir-Regular;
          text-align: left;
          color: $dark;
          padding-top: 10px;
          .return-amount {
            font-family: Avenir-Bold;
            color: $actionGreen;
          }
          .amount {
            color: $savvyYellow;
            font-family: Avenir-DemiBold;
            margin-bottom: 0;
            font-size: $h1;
            .suffix {
              font-size: $h4;
              margin-left: 0.3rem;
              position: relative;
              top: -2px;
              font-family: Avenir-Regular;
            }
          }
          .saved {
            @include flexbox();
            @include align-items(flex-end);
            .saved-amount {
              color: $savvyYellow;
              line-height: 2.4rem;
            }
            .saved-amount-suffix {
              color: $savvyYellow;
              line-height: 2.4rem;
            }
          }
          .description {
            display: flex;
            margin-top: 1rem;
            .left {
              padding-right: 25px;
            }
            .right {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
            }

            .description-title {
              // font-size: $h6;
              color: $dark;
              font-family: Avenir-Medium;
              white-space: pre;
            }

            .description-value {
              color: $dark;
              font-size: $h4;
            }
            .cashback-percentage {
              color: $success-light;
              font-family: Avenir-DemiBold;
            }
          }
        }

        .right {
          flex: 1;
          text-align: right;
          .right-content {
            display: flex;
            justify-content: flex-end;
            height: 100%;

            .right-inner-content {
              display: flex;
              flex-direction: column;
              flex-wrap: wrap-reverse;
              width: fit-content;
              align-items: flex-start;
              justify-content: space-between;
              height: 100%;
              padding-top: 10px;
              .active-arrow {
                transform: (rotate(180deg));
              }
              .button {
                background-color: #f2f2f2;
                border-color: #f2f2f2;
                color: rgba(0, 0, 0, 0.7) !important;
                font-family: Avenir-DemiBold;
                font-size: $h5;
                border: unset;
                padding: 5px 10px;
                @include border-radius(3px);
                &.active {
                  background-color: $gray-button;
                  border-color: $gray-button;
                }
              }
              img {
                width: 25px;
                margin-bottom: 8px;
              }
            }
          }
        }
      }
    }
    .offer-header-active {
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.09);
      border-radius: 15px 15px 0px 0px;
    }
    .learn-more {
      font-size: $headingSectionThree;
      color: $themeBlack;
      text-decoration: underline;
      text-align: right;
      padding-top: 10px;
      cursor: pointer;
    }
    .partner-images {
      text-align: center;
      @include flexbox();
      @include justify-content(flex-end);
      @include align-items(center);
      padding-top: 5px;
      padding-bottom: 20px;
      color: black;
      font-family: Avenir-Regular;

      .invested-at {
        margin-top: 15px;
        margin-bottom: 20px;
        font-size: $h5;
      }
      .icici-img {
        margin: 0px 15px;
      }
      img {
        margin-right: 10px;
      }
    }
    .accordion-body {
      background-color: rgb(26, 26, 26);
      @include border-radius(0px 0px 15px 15px);
      padding: 25px 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.8);
      .header {
        margin: auto;
        width: 90%;
        margin-bottom: 4px;
        .heading {
          color: #ffffff;
        }
      }

      .earn-cashback {
        text-align: center;
        font-style: italic;
        // font-size: 12px;
        span {
          color: $success-light;
        }
        color: #ffffffe0;
      }
      .tnc-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 1rem;
        padding-left: 10px;
        padding-right: 10px;
        .tnc-checkbox {
          margin-right: 5px;
          margin-top: -3px;
          height: 18px;
          width: 18px;
          cursor: pointer;
        }
        .tnc-text {
          font-size: 14px;
          color: #ffffff;
        }
      }
      .pay-first-btn {
        padding-bottom: 20px;
        button {
          font-size: $h5;
          padding: 8px 45px !important;
          border: unset;
          padding: 0.5rem 2rem;
          @include border-radius(10px !important);
          color: white;
        }
      }
      .total-amount-content {
        color: rgba(255, 255, 255, 0.7);
        // font-size: $h6;
        margin-left: -1px;
        width: 90%;
        margin: 0px auto;
      }
      .offer-amount-content {
        color: rgba(255, 255, 255, 0.7);
        font-size: 12px;
        margin-left: -1px;
        width: 90%;
        margin: 0px auto;
        padding-bottom: 10px;
      }
    }
  }

  .offer-accordion-item-active {
    border: 0px solid $primary;
    box-shadow: unset;
  }

  .timeline-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 8px;
    margin-bottom: 1.5rem;
    .timeline {
      width: 90%;
      position: relative;
      margin: auto;

      .orange-box {
        color: $savvyYellow;
        font-family: Avenir-DemiBold;
      }
      .grey-bg {
        background-color: rgba(255, 255, 255, 0.15);
      }
      .common-box {
        position: relative;
        margin-bottom: 10px;
        color: white;
        @include border-radius(0px 6px 6px 0px);
        .icici-link {
          color: $pureWhite;
          padding-left: 18px;
        }
        .hidder {
          width: 10px;
          height: 51px;
          background-color: rgb(26, 26, 26);
          position: absolute;
          top: 33px;
          left: -4px;
          z-index: 3;
        }
        .content {
          padding: 1rem 1rem;
          // font-size: 1rem;
          display: flex;
          // font-size: $h6;
          .left {
            @include flexbox();
            color: $pureWhite;
          }
          .right {
            margin-left: auto;
            color: $pureWhite;
          }
        }
        .remaining-installments-text {
          padding-bottom: 0px;
          color: $pureWhite;
        }
        &:after {
          position: absolute;
          height: 10px;
          width: 10px;
          border-radius: 50%;
          background: #c4c4c4;
          left: -4.5px;
          top: 19px;
          content: '';
          z-index: 3;
        }
      }
      .common-box-after {
        &:after {
          position: absolute;
          height: 10px;
          width: 10px;
          border-radius: 50%;
          background: $savvyYellow !important;
          left: -4.5px;
          top: 18px;
          content: '';
          z-index: 3;
        }
      }
      .tick {
        position: absolute;
        left: -13px;
        z-index: 5;
        top: 14px;
        width: 26px;
      }
      .buy-the-holiday {
        margin-bottom: 0px;
        .content {
          padding-bottom: 0px;
        }
      }

      .point-node {
        // padding: 5px;
        position: relative;
        margin-bottom: 1rem;
        .content {
          padding: 0px 0px 0px 35px;
          font-size: 1rem;
          color: white;
          display: flex;
          align-items: center;
          // font-size: $h6;
          .left {
            @include flexbox();
            color: $pureWhite;
          }
          .right {
            margin-left: auto;
            color: $pureWhite;
          }
        }

        &:after {
          position: absolute;
          height: 1px;
          width: 26px;
          background: #c4c4c4;
          left: 0px;
          top: 9px;
          content: '';
          z-index: 3;
        }
      }

      .point-node-last {
        margin-bottom: 0.2rem !important;
      }

      .node {
        position: relative;
        z-index: 3;
        .content {
          font-size: $h4;
          color: $gray-light;
          margin-bottom: 1rem;
          background-color: #f3f3f3;
        }
      }
      &:after {
        position: absolute;
        height: 100%;
        width: 1px;
        background: rgb(207, 207, 207);
        left: 0;
        top: 0;
        content: '';
        z-index: 2;
      }
    }
  }
  .buttonMain {
    padding: 0 3rem;
  }
  @include screen-below('lg') {
    .offer-accordion-item {
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.09);
      border-radius: 15px;
      text-align: left;
      border: unset;
      margin-bottom: 35px;
      .learn-more {
        font-size: $textParagraphThree;
        color: $themeBlack;
        text-decoration: underline;
        text-align: right;
        padding-top: 10px;
        cursor: pointer;
        padding-right: 10px;
        padding-left: 10px;
      }
      .offer-header {
        text-decoration: none;
        width: 100%;
        padding: 10px;
        box-shadow: none;
        border-radius: 15px;

        .offer-upper-header {
          padding-top: 10px;
          text-align: center;
          .offer-header-tab-arrow {
            @include flexbox();
            @include align-items(center);
            padding: 10px 1px;
            justify-content: center;
            width: 100%;
            img {
              width: 8px;
            }
          }
          .offer-header-tab {
            .offer-header-title {
              color: $dark;
              font-size: $textFinePrint;
              font-family: Avenir-Regular;
              text-align: center;
              white-space: pre;
              span {
                font-family: Avenir-Medium;
              }
              line-height: 17px;
            }
            .offer-header-heading {
              font-size: $textParagraphThree;
              font-family: Avenir-Regular;
              font-weight: 600;
            }
            .offer-header-heading-bold {
              font-size: $textParagraphThree;
            }
          }
        }

        .offer-header-wrapper {
          display: flex;
          flex: 1;

          .left {
            text-align: left;
            white-space: pre;
            .on-your-saving {
              display: none;
            }
            .amount {
              color: $primary;
              font-family: Avenir-DemiBold;
              margin-bottom: 0;
              font-size: $h1;
              .suffix {
                font-size: $h4;
                margin-left: 0.3rem;
                position: relative;
                top: -2px;
                font-family: Avenir-Regular;
              }
            }
            .saved {
              @include flexbox();
              @include align-items(flex-end);
              .saved-amount {
                font-family: Avenir-Medium;
                font-size: $h4;
              }
              .saved-amount-suffix {
                font-size: $h6;
                padding-bottom: 2px;
              }
              color: $primary;
            }
            .description {
              display: flex;
              flex-direction: column;
              margin-top: 5px;
              .left {
                padding-right: 25px;
                margin-bottom: 10px;
              }
              .right {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
              }

              .description-title {
                font-size: $h6;
                color: $dark;
                font-family: Avenir-Medium;
                white-space: pre;
              }

              .description-value {
                color: $dark;
                font-size: $h5;
              }
              .cashback-percentage {
                color: $success-light;
                font-family: Avenir-DemiBold;
              }
            }
          }

          .right {
            flex: 1;
            text-align: right;
            .right-content {
              display: flex;
              justify-content: flex-end;
              height: 100%;

              .right-inner-content {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap-reverse;
                width: fit-content;
                align-items: flex-start;
                justify-content: space-between;
                height: 100%;
                .active-arrow {
                  transform: (rotate(180deg));
                }
                button {
                  background-color: #f2f2f2;
                  border-color: #f2f2f2;
                  color: rgba(0, 0, 0, 0.7) !important;
                  font-family: Avenir-DemiBold;
                  font-size: $h6;
                  border: unset;
                  padding: 5px 10px;
                  @include border-radius(1);
                  &.active {
                    background-color: $gray-button;
                    border-color: $gray-button;
                  }
                }
                img {
                  width: 20px;
                  margin-bottom: 8px;
                  margin-right: 20px;
                }
              }
            }
          }
        }
      }

      .accordion-body {
        background-color: rgb(26, 26, 26);
        @include border-radius(0px 0px 15px 15px);
        padding: 25px 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.8);
        .header {
          margin: auto;
          width: 90%;
          margin-bottom: 4px;
          .heading {
            font-family: Avenir-Medium;
            font-size: $h3;
            color: #ffffff;
          }
        }

        .earn-cashback {
          text-align: center;
          font-style: italic;
          font-size: $textFinePrint;
          span {
            color: $success-light;
          }
          color: #ffffffe0;
        }
        .pay-first-btn {
          button {
            font-size: $h5;
            padding: 8px 45px !important;
            border: unset;
            padding: 0.5rem 2rem;
            @include border-radius(10px !important);
            color: white;
          }
        }
        .partner-images {
          text-align: center;
          @include flexbox();
          @include justify-content(flex-end);
          @include align-items(center);
          padding-top: 10px;
          padding-bottom: 20px;
          .invested-at {
            margin-top: 15px;
            margin-bottom: 20px;
            font-size: $h5;
          }
          .icici-img {
            margin: 0px 15px;
          }
          img {
            margin-right: 10px;
          }
        }
        .total-amount-content {
          color: rgba(255, 255, 255, 0.7);
          font-size: $h6;
          margin-left: -1px;
          width: 90%;
          margin: 0px auto;
        }
      }
    }

    .timeline-wrapper {
      display: flex;
      justify-content: center;
      margin-top: 8px;
      margin-bottom: 1.5rem;
      .timeline {
        width: 90%;
        position: relative;
        margin: auto;

        .orange-box {
          color: $savvyYellow;
          font-family: Avenir-DemiBold;
        }
        .grey-bg {
          background-color: rgba(255, 255, 255, 0.15);
        }
        .common-box {
          position: relative;
          margin-bottom: 10px;
          color: white;
          @include border-radius(0px 6px 6px 0px);
          .hidder {
            width: 10px;
            height: 71px;
            background-color: rgb(26, 26, 26);
            position: absolute;
            top: 33px;
            left: -4px;
            z-index: 3;
          }
          .content {
            padding: 1rem 1rem;
            font-size: 1rem;
            display: flex;
            font-size: $h6;
            .left {
              @include flexbox();
            }
            .right {
              margin-left: auto;
            }
          }
          &:after {
            position: absolute;
            height: 10px;
            width: 10px;
            border-radius: 50%;
            background: #c4c4c4;
            left: -4.5px;
            top: 18px;
            content: '';
            z-index: 3;
          }
        }

        .point-node {
          // padding: 5px;
          position: relative;
          margin-bottom: 1rem;
          .content {
            padding: 0px 0px 0px 35px;
            font-size: 1rem;
            color: white;
            display: flex;
            align-items: center;
            font-size: $h6;
            .left {
              @include flexbox();
            }
            .right {
              margin-left: auto;
              color: $pureWhite;
            }
          }

          &:after {
            position: absolute;
            height: 1px;
            width: 26px;
            background: #c4c4c4;
            left: 0px;
            top: 9px;
            content: '';
            z-index: 3;
          }
        }

        .node {
          position: relative;
          z-index: 3;
          .content {
            font-size: $h4;
            color: $gray-light;
            margin-bottom: 1rem;
            background-color: #f3f3f3;
          }
        }
        &:after {
          position: absolute;
          height: 100%;
          width: 1px;
          background: rgb(207, 207, 207);
          left: 0;
          top: 0;
          content: '';
          z-index: 2;
        }
      }
    }
  }
}
