@import 'scss/media_queries';

.content-container {
  height: 100%;
  padding-bottom: 3rem;

  .dashboard {
    height: 100%;
    width: 100%;

    .logout {
      display: none;
    }
  }

  @include screen-below('lg') {
    .dashboard {
      .logout {
        width: 100%;
        border: none;
        font-family: Avenir-DemiBold;
        color: #353535;
        padding: 0.75rem 1rem;
        background-color: #f3f3f3;
        text-align: left;
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
