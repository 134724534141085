@import 'scss/theme';

.main{
  font-size: $headingMain;
  font-family: $fontFamilySemiBold;
  color: $greyBlack;
}

.section{
  font-size: $headingSection;
  font-family: $fontFamilySemiBold;
  color: $greyBlack;
}

.sub-heading{
  font-size: $headingSectionTwo;
  font-family: $fontFamilySemiBold;
  color: $greyBlack;
}