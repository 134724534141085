@import 'scss/variables';
@import 'scss/flex';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';

.header-nav {
  .savvy-logo {
    @include flexbox();
    height: 45px;
    padding: 3px 0px;
    .savvy-logo-x {
      font-size: $h1;
      font-family: Avenir-DemiBold;
      padding: 0px 10px;
    }
    img {
      height: 40px;
    }
    .another-logo {      
      img {
        height: 40px;
      }
    }
  }
  .dashboard {
    text-decoration: none;
    color: rgba(0, 0, 0, 0.7);
    font-family: Avenir-DemiBold;

    img {
      margin-left: 5px;
    }

    .name {
      font-size: $h4;
    }
  }
  @include screen-below('lg') {
    .dashboard {
      .name {
        display: none;
      }
    }
  }
}
