@import 'scss/media_queries';
@import 'scss/theme';

.tableHeaderContainer {
  // display: flex;
  align-items: center;

  .tableHeader {
    margin-right: 0.2rem;
  }

  .icon {
    height: 0.8em;
  }
}

.tableContainer {
  .table {
    background-color: #fff;
    margin-bottom: 0;
  }
}

.pagination {
  justify-content: center;
  background-color: #fff;
  padding: 1rem 0;
  .paginationLabel {
    align-self: center;
    color: rgb(95, 95, 95);
    margin: 0 0.5rem;
  }
  .active{
    a{
      background-color: $savvyYellow;
    }
  }
}

@include screen-below('lg') {
  .pagination {
    justify-content: center;
  }
}
