@import 'scss/media_queries';
@import 'scss/variables';

.responsive-tab {
  height: 100%;
  display: flex;
  // border: 1px solid #F3F3F3;

  .toggle-container {
    width: 15%;
    background-color: #f3f3f3;

    .toggle {
      width: 100%;
      padding: 2rem 1rem;
      border: none;
      font-family: Avenir-DemiBold;
      color: black;
      font-size: $h2;
      background-color: #f3f3f3;
      transform: scale(0.9);
      transition: background-color 0.4s, transform 0.4s;

      &:hover {
        transform: scale(1);
      }

      &.active {
        background-color: #ffffff;
        transform: scale(1);
        border: 1px solid #f3f3f3;
        border-right-color: #ffffff;
      }
    }
  }

  .body-container {
    flex: 1;
    position: relative;

    .body {
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background-color: #ffffff;
      opacity: 0;
      transform: scale(0.8);
      transition: opacity 0.2s, transform 0.4s;
      z-index: 1;

      &.active {
        opacity: 1;
        transform: scale(1);
        z-index: 2;
      }
    }
  }
}

@include screen-below('lg') {
  .responsive-tab {
    height: auto;
    flex-direction: column;
    border: none;

    .accordion-content {
      margin-bottom: 0.5rem;

      .toggle {
        width: 100%;
        border: none;
        font-family: Avenir-DemiBold;
        color: $dark;
        padding: 0.75rem 1rem;
        background-color: #f3f3f3;
        text-align: left;
        display: flex;
        justify-content: space-between;
      }

      .body {
      }
    }
  }
}
