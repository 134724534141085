@import 'scss/variables';
@import 'scss/flex';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';

.c-datepicker {
  padding-right: 45px;
  h2 {
    font-family: Avenir-DemiBold;
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 5px;
    color: rgba(0, 0, 0, 0.7);
  }
  .error {
    font-size: 0.8rem;
    color: $red-dark;
    margin-top: 0.1rem;
  }
  .hidden-input {
    display: none;
  }
  .dropdown-group {
    @include flexbox();
    .dropdown {
      margin-right: 20px;
      &:last-child {
        margin-right: 0px;
      }
      .dropdown-button {
        color: black;
        height: 46px;
        font-family: Avenir-Medium;
        @include border-radius(10px);
        border: 1px solid #ccc;
        min-width: 120px;
        position: relative;
        padding-right: 29px;
        &::after {
          margin-left: 23px;
          border-style: solid;
          border-width: 0px;
          content: '^';
          display: inline-block;
          height: 14px;
          position: absolute;
          right: 11px;
          top: 29px;
          @include transform(rotate(180deg));
          vertical-align: top;
          width: 14px;
          border-right: 0px;
          color: #ccc;
          font-size: 45px;
        }
      }
      .dropdown-button-name {
        width: 150px;
      }
      .dropdown-menu {
        min-width: unset;
        width: 100%;
        max-height: 150px;
        overflow: auto;
      }
    }
  }
}
