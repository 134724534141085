.grid-row {
  display: flex;
  flex: 1;

  &.column {
    flex-direction: column;
  }

  &.row {
    flex-direction: row;
  }

  &.justify-flex-start {
    justify-content: flex-start;
  }

  &.justify-center {
    justify-content: center;
  }

  &.justify-flex-end {
    justify-content: flex-end;
  }

  &.align-flex-start {
    align-items: flex-start;
  }

  &.justify-center {
    align-items: center;
  }

  &.justify-flex-end {
    align-items: flex-end;
  }

  &.flex-1 {
    flex: 1;
  }

  &.flex-2 {
    flex: 2;
  }

  &.flex-3 {
    flex: 3;
  }

  &.flex-4 {
    flex: 4;
  }

  &.flex-5 {
    flex: 5;
  }

  &.flex-6 {
    flex: 6;
  }
}
