@import 'scss/functions';
@import 'scss/theme';

.container {
  width: toRem(68);
  height: toRem(345);
  .header,
  .body,
  .footer {
    border: 0;
  }

  .body {
    .code-copied {
      margin-bottom: 10px;
    }
    .text {
      // white-space: pre;
      text-align: center;
      width: 100%;
      .link {
        font-family: $avenirLight;
        width: 100%;
        border: unset;
        text-align: center;
        overflow: clip;
        outline: unset;
      }
    }
  }
  .footer {
    .buttonsContainer {
      display: flex;
      .buttonOne {
        margin-right: toRem(20);
      }
    }
  }
  .icon {
    &.success {
      color: $actionGreen;
    }
    &.error {
      color: $errorRed;
    }
  }
}
