@import 'scss/theme';
@import 'scss/mixins';

.container{
  .basic{
    @include buttonStyles(
      toRem($textButtonCompact), 
      $greyBlack, 
      toRem($buttonCompactRadius), 
      toRem($buttonCompactPaddingVertical) toRem($buttonCompactPaddingHorizontal), 
      $pureWhite, 
      none,
      toRem($buttonCompactMinWidth),
      toRem($buttonCompactMinHeight)
    );
  }
}