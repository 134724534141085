@import 'scss/theme';

.container{
  border: 1px solid $lightGrey;
  border-radius: 10px;
  padding: 10px;
  background-color: #ededed;
  position: relative;
  .icon{
    position: absolute;
    top: -5px;
    font-size: toRem(12);
    left: 7px
    // background-color: $savvyYellow;
    // padding: 2px 3px;
    // border: 1px solid #c2c2c2;
  }
}