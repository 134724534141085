@import 'scss/variables.scss';
@import 'scss/media_queries.scss';

.container {
  margin-top: 2rem;
  .pageHeading {
    font-size: $pageHeading;
    font-weight: 600;
  }
  .card {
    border-radius: 5px;
    margin-bottom: 2rem;
    box-shadow: 0 3px 5px darken($gray-medium, 10%);
    .cardHeader {
      background-color: #fff;
      .cardHeading {
        font-size: $cardHeading;
        font-weight: 600;
      }
    }
    .cardBody {
      padding: 1rem 0;
    }
    .cardRow {
      margin-bottom: 1rem;
    }
    .cardSection {
      margin-bottom: 2rem;
    }
    .urlContainer {
      text-align: center;
      margin: 1rem 0 0;
      font-size: 0.8rem;

      .clipboard {
        background-color: transparent;
        border: none;
        padding-left: 5px;
      }
      .urlInput {
        width: 270px;
        padding: 0.25rem;
        border: none;
        background-color: transparent;
        outline: none !important;
      }
      .copied {
        color: $green;
      }
    }
  }
}

@include screen-below('lg') {
  .cardCol {
    margin-bottom: 1rem;
  }
}
