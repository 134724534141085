@import 'scss/variables.scss';
@import 'scss/mixins.scss';
@import 'scss/media_queries.scss';
@import 'scss/theme';

.container{
  padding: 3rem 0;
}
.containerHeader{
  border-bottom: 1px solid $dark;
  padding-bottom: 0.5rem;;
  margin-bottom: 2rem;
}
.containerHeading{
  font-size: 1.5rem;
  color: $dark-secondary;
}
.sectionHeading {
  font-size: 1.2rem;
  color: $dark-secondary;
  margin-bottom: 1.5rem;
}

.section{
  margin-bottom: 1rem;
  border-bottom: 1px solid $dark-secondary;
  padding: 0 1rem;
  padding-bottom: 1rem;
}

.buttonContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.demo-info-group-input {
  margin-bottom: 20px;
  label {
    font-family: Avenir-DemiBold;
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 5px;
    color: rgba(0, 0, 0, 0.7);
  }
  .demo-info-input,
  input {
    @include border-radius(8px);
    width: 100%;
    height: 48px;
    font-size: 15px;
    font-family: Avenir-Medium;
    border: 1px solid #ccc;
    outline: none;
    color: #333;
    background-color: transparent;
    padding: 0px 15px;
    &:disabled {
      background-color: $disabled-light;
    }

    &:focus {
      border: 1px solid $primary;
      box-shadow: 0 0 2px $primary;
    }
    margin-bottom: 5px;
    @include placeholder-color(#c4c4c4);
  }
}

.next-btn {
  background-color: $savvy-yellow;
  @include border-radius(7px);
  min-width: 140px;
  height: 44px;
  font-family: Avenir-DemiBold;
  font-size: 18px;
}

.customCardTwoContainer{
  width: 50%;
}

@include screen-below('lg'){
  .customCardTwoContainer{
    width: auto;
  }
  .containerHeading{
    font-size: 1rem;
  }
  .section{
    padding: 0 0 2rem;
  }
}