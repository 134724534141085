@import 'scss/variables';
@import 'scss/flex';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';

.payment-links-table {
  .badge-box {
    display: initial;
    .badge-style {
      font-family: Avenir-Medium;
      font-size: $h6;
      width: 110px;
      padding: 5px;
      @include border-radius(12px);
      @include inline-flex();
      @include justify-content(center);
      @include align-items(center);
    }
  }
  thead {
    tr {
      th {
        font-size: $headingSectionThree;
        color: $themeBlack;
      }
    }
  }
  tbody {
    tr :nth-child(odd) {
      padding: 12px 4px;
      font-family: Avenir-Medium;
    }
    td {
      font-size: $textParagraphOne;
      color: $themeBlack;
    }
    tr {
      a {
        .action-icon {
          padding: 0;
          color: $savvyYellow;
        }
      }
      .copy-clipboard {
        padding: 0;
        border: 0;
        background-color: transparent;

        .action-icon {
          padding: 0;
          color: $savvyYellow;
        }
      }
      .expire-link {
        padding: 0;
        border: 0;
        margin-left: 10px;
        background-color: transparent;

        .action-icon {
          padding: 0;
          color: $savvyYellow;
        }
      }
    }
  }
  .link-input {
    border: none;
    background-color: transparent;
    color: transparent;
  }
}
