@import 'theme';
@import 'bootstrap/scss/bootstrap';
@import 'variables';
@import 'media_queries';
@import 'mixins';
@import 'flex';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#notification-wrapper {
  .toast-notification {
    z-index: 2000 !important;
  }
}

html,
body {
  height: 100%;
  font-family: Avenir-Regular;
  font-weight: 400;
  font-size: 0.95rem;
  font-size: $baseFontSize + px;
  color: $dark;
}

.admin-screen-animate {
  overflow: scroll;
  height: calc(100vh - 83px);
  padding-bottom: 1rem;
}
.admin-screen-animation {
  overflow: scroll;
  height: calc(100vh);
  padding: 1rem;
}

.dropdown-profile-menu .nav-link {
  color: $savvyYellow;
}

.page-enter {
  .screen-animate {
    background-color: #ffffff;
    opacity: 0;
    transform: scale(1.1);
  }
  .admin-screen-animate {
    opacity: 0;
    transform: scale(0.9);
  }
  .admin-screen-layout {
    opacity: 0;
  }
}

.page-enter-active {
  .screen-animate {
    opacity: 1;
    transform: scale(1);
    transition: opacity 300ms, transform 300ms;
  }
  .admin-screen-animate {
    opacity: 1;
    transform: scale(1);
    transition: opacity 300ms, transform 300ms;
  }
  .admin-screen-layout {
    opacity: 1;
  }
}

.page-exit {
  .screen-animate {
    opacity: 1;
    transform: scale(1);
  }
  .admin-screen-animate {
    opacity: 1;
    transform: scale(1);
  }
  .admin-screen-layout {
    opacity: 1;
  }
}

.page-exit-active {
  .screen-animate {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 100ms, transform 100ms;
  }
  .admin-screen-animate {
    opacity: 0;
    transform: scale(1.1);
    transition: opacity 100ms, transform 100ms;
  }
  .admin-screen-layout {
    opacity: 0;
  }
}

.pay-demographic-datepicker {
  @include border-radius(8px);
  width: 100%;
  height: 35px;
  font-size: 15px;
  font-family: Avenir-Medium;
  border: 1px solid $disabled-medium;
  outline: none;
  color: $footerBlack;
  background-color: transparent;
  padding: 0px 15px;
  &:disabled {
    background-color: $disabled-light;
  }

  &:focus {
    border: 1px solid $primary;
    box-shadow: 0 0 2px $primary;
  }
  margin-bottom: 5px;
}
.demographic-datepicker-dob .react-datepicker-popper {
  @include screen-up('lg') {
    top: -62px !important;
    left: 110px !important;
  }
}
.demographic-datepicker-dob .react-datepicker__triangle {
  @include screen-up('lg') {
    display: none;
  }
}

.demographic-datepicker-dob .react-datepicker {
  // [Omitted styling to change react-datepicker colors to match our app]
  .react-datepicker__current-month.react-datepicker__current-month--hasYearDropdown,
  .react-datepicker__current-month.react-datepicker__current-month--hasMonthDropdown {
    // Avoid showing the month and year a second time when it has month and year
    // dropdowns.
    display: none;
  }
  .react-datepicker__year-dropdown-container {
    padding-left: 19px;
  }
  .react-datepicker__navigation--years-upcoming::before {
    top: 20px;
    right: 10px;
    content: '';
    display: inline-block;
    width: 0.5em;
    height: 0.5em;
    border-right: 0.1em solid $pureBlack;
    border-top: 0.1em solid $pureBlack;
    transform: rotate(316deg);
    position: absolute;
  }
  .react-datepicker__navigation--years-previous::before {
    top: 6px;
    right: 10px;
    content: '';
    display: inline-block;
    width: 0.5em;
    height: 0.5em;
    border-right: 0.1em solid $pureBlack;
    border-top: 0.1em solid $pureBlack;
    transform: rotate(135deg);
    position: absolute;
  }
}

.custom-template-product-select {
  .main-container__control {
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    padding-right: 2px;
    height: unset;
  }
  .main-container__indicator-separator {
    display: none;
  }
  .main-container__indicator {
    color: #656565;
  }
}

.payment-page-support {
  .global-savvy-support {
    @include screen-below('lg') {
      bottom: 60px;
    }
  }
}
