@import 'scss/variables';
@import 'scss/flex';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';

$minWidth: 453px;

.desktop {
  display: block;
  @include screen-below('lg') {
    display: none;
  }
}
.mobile {
  display: none;
  @include screen-below('lg') {
    display: block;
  }
}

.progress-bar {
  height: 4px;
  width: 100%;
  background-color: $greyWhite;
}

.active-progress-bar {
  background-color: $savvyYellow;
  height: 4px;
}

.note {
  text-transform: uppercase;
  font-size: $textFinePrint;
  color: $themeBlack;
  text-align: center;
  text-decoration: underline;
  margin-bottom: 10px;
}
.pay-content {
  padding: 0px 80px 0px 40px;
  min-height: 300px;
  @include screen-below('lg') {
    padding: 0px 0px 0px 0px;
  }
}
.sub-title {
  font-family: Avenir-DemiBold;
  color: $pureBlack;
  font-size: $headingSectionThree;
  margin-bottom: 8px !important;
  line-height: 28px;
  text-align: center;
  @include screen-below('lg') {
  }
}
.sub-title-mob {
  font-family: Avenir-DemiBold;
  color: $pureBlack;
  font-size: $headingSectionThree;
  margin-bottom: 18px !important;
  line-height: 28px;
  text-align: center;
  @include screen-below('lg') {
  }
}
.title {
  font-family: Avenir-DemiBold;
  color: $pureBlack;
  font-size: $headingSection;
  margin-bottom: 8px !important;
  line-height: 28px;
  text-align: center;
  @include screen-below('lg') {
    padding-top: 30px;
    padding-left: 15px;
    padding-right: 15px;
    br {
      display: none;
    }
  }
}

.folio {
  color: $greyBlack;
  font-family: Avenir-DemiBold;
  font-size: $headingSectionThree;
  margin-bottom: 30px;
  text-align: center;
  max-width: 440px;
  display: none;
  margin: 0px auto;
  padding: 50px 15px 20px 15px;
  @include screen-below('lg') {
    display: block;
  }
}
.pay-in-installment {
  font-family: Avenir-DemiBold;
  font-size: $textParagraphOne;
  color: $pureBlack;
  padding: 5px 15px;
  border-bottom: 2px solid $greyWhite;
  margin-bottom: 50px;
}
.header {
  @include flexbox();
  @include justify-content(center);
  padding: 15px 10px;
  .inner-header {
    @include flexbox();
    @include justify-content(flex-start);
    max-width: $minWidth;
    width: 100%;
    .sub-inner-header {
      text-align: center;
      width: 100%;

      .price {
        font-family: Avenir-DemiBold;
        color: $pureBlack;
        font-size: $headingSection;
      }
      .note {
        color: $themeBlack;
        font-size: $textFinePrint;
        text-transform: uppercase;
        font-family: Avenir-Medium;
        margin-bottom: 6px;
      }
      .amount {
        color: $themeBlack;
        font-size: $textFinePrint;
        font-family: Avenir-Medium;
        @include flexbox();
        @include justify-content(center);
        @include align-items(center);
        img {
          margin-top: -3px;
          margin-right: 3px;
        }
      }
    }
  }
}

.mobile-space {
  padding-left: 0px;
  padding-right: 0px;
  @include screen-below('lg') {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.mobile {
  display: none;
  @include screen-below('lg') {
    display: block;
  }
}

.line-mob {
  height: 4px;
  width: 24px;
  background-color: $savvyYellow;
  margin-bottom: 20px;
}
.progress-bar {
  height: 4px;
  width: 100%;
  background-color: $greyWhite;
}

.active-progress-bar {
  background-color: $savvyYellow;
  height: 4px;
}

.pay {
  @include flexbox();
  @include justify-content(center);
  @include flex-direction(column);
  @include align-items(center);
  padding-left: 20px;
  padding-right: 20px;
  height: 100vh;
  // padding-top: 60px;
  @include screen-below('lg') {
    display: unset;
  }
  .pay-box {
    @include flexbox();
    .left-box {
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
      border-radius: 8px;
      height: fit-content;
      margin-top: 100px;
      padding-top: 30px;
      max-width: 810px;
      @include screen-up('xxl') {
        min-width: 700px;
      }
    }
    .pay-content {
      .tick {
        text-align: center;
        @include flexbox();
        margin-bottom: 30px;
        padding-top: 20px;
        @include justify-content(center);
      }
      .message {
        color: $greyBlack;
        font-family: Avenir-Regular;
        font-size: $headingSectionThree;
        margin-bottom: 30px;
        text-align: center;
        max-width: 440px;
        margin: 0px auto;
      }

      @include screen-below('lg') {
        position: relative;

        .tick {
          padding-top: 20px;
          padding-bottom: 0px;
          margin-bottom: 20px;
        }
        .message {
          color: $greyBlack;
          font-family: Avenir-Regular;
          font-size: $textParagraphOne;
          text-align: center;
          line-height: 25px;
          padding-bottom: 100px;
          padding-left: 15px;
          padding-left: 15px;
          span {
            font-family: Avenir-DemiBold;
          }
        }
        .form-box {
          label {
            font-size: $textParagraphOne;
          }
        }
        .link {
          padding-top: 93px;
        }
        .pay-content {
          // height: 100vh;

          .line {
            display: none;
          }
        }
      }
    }
  }

  .extra-info {
    margin-top: 70px;
    @include flexbox();
    background: $greyWhiteThree;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    max-width: 952px;
    width: 100%;
    padding-bottom: 50px;
    margin-bottom: 30px;
    .extra-logo {
      padding: 14px 30px 14px 14px;
    }
    .extra-title {
      font-size: $headingSectionThree;
      color: $pureBlack;
      font-family: Avenir-Regular;
      opacity: 0.8;
      padding-top: 20px;
      padding-bottom: 10px;
    }
    .extra-desc {
      font-size: $headingSectionThree;
      color: $pureBlack;
      font-family: Avenir-Regular;
    }
  }

  @include screen-below('lg') {
    width: 100%;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    .pay-box {
      width: 100%;
      .left-box {
        padding-top: 0px;
        margin-top: 0px;
        width: 100%;
        box-shadow: unset;
        border-radius: unset;
      }
      .right-box {
        display: none;
        width: 100%;
      }
    }
    .extra-info {
      display: none;
    }
  }
}

.footer {
  height: 70px;
  width: 100%;
  background-color: $savvyYellow;
  border-radius: 0px 0px 0px 8px;
  @include flexbox();
  @include align-items(center);
  @include justify-content(center);
  font-family: Avenir-DemiBold;
  font-size: $headingSection;
  color: $pureBlack;
  cursor: pointer;
  @include screen-below('lg') {
    text-transform: uppercase;
    position: fixed;
    bottom: 0px;
    left: 0px;
  }
}
