@import 'scss/variables';
@import 'scss/flex';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';

.label {
  font-family: Avenir-Regular !important;
  font-size: 14px !important;
  font-weight: unset !important;
}

.select {
  border: 1px solid #e5e5e5 !important;
  border-radius: 0.45rem !important;
  font-size: 14px !important;
  padding: 0.25rem 0.5rem !important;
  color: $blackTwo !important;
  margin-top: -6px !important;
}
