@import 'scss/variables';
@import 'scss/flex';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';

.error-fallback {
  background-color: #1c1c1f;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .logo {
    width: 50px;
    position: absolute;
    left: 15px;
    top: 15px;
  }
  .oops {
    width: 350px;
    margin-bottom: 1rem;
  }
  h3 {
    color: #ffffff;
    font-family: Avenir-DemiBold;
  }
  p {
    color: #ffffff;
    text-align: center;
    font-family: Avenir-Regular;
    font-size: 0.85rem;
    padding: 0 1rem;
    max-width: 50%;
  }

  @include screen-below('lg') {
    .oops {
      width: 200px;
    }
    h3 {
      font-size: 1.2rem;
    }
    p {
      font-size: 0.8rem;
      max-width: 90%;
    }
  }
}
