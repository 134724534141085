@import 'scss/variables';
@import 'scss/flex';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';

.pay {
  @include flexbox();
  @include justify-content(center);
  @include flex-direction(column);
  @include align-items(center);
  padding-left: 20px;
  padding-right: 20px;
  height: 100%;

  .pay-box {
    @include flexbox();
    .left-box {
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
      border-radius: 8px;
      height: fit-content;
      margin-top: 130px;
      padding-top: 30px;
      max-width: 810px;
      @include screen-up('xxl') {
        min-width: 700px;
      }
    }
    .right-box {
      padding-top: 30px;
      padding-bottom: 20px;
    }
    .pay-header {
      .pay-heading {
        color: $pureBlack;
        font-family: Avenir-DemiBold;
        font-size: 1.1rem;
        margin: 0 0 1rem 0;
        padding: 0 25px 0;
      }

      .options-container {
        display: flex;
        border-bottom: 1px solid $disabled-light;
        padding: 0 25px 10px;
        margin-bottom: 25px;

        .option-button {
          display: flex;
          flex: 1;
          border: none;
          background-color: transparent;
          align-items: center;

          .option-circle {
            width: 20px;
            height: 20px;
            background-color: $disabled-medium;
            border-radius: 50%;
            padding: 3px;
          }
          img {
            width: 45px;
            height: 45px;
            margin: 0 5px;
          }
          h4 {
            color: $pureBlack;
            font-family: Avenir-Regular;
            font-size: 1.1rem;
            margin: 0;
          }

          &.active {
            .option-circle {
              border: 2px solid $savvyYellow;
              background-color: $extra-light;
              display: flex;
              justify-content: center;
              align-items: center;

              &::after {
                content: '';
                width: 100%;
                height: 100%;
                background-color: $savvyYellow;
                display: block;
                border-radius: 50%;
              }
            }
            h4 {
              font-family: Avenir-DemiBold;
            }
          }
        }
      }
    }
    .pay-content {
      padding: 0 25px 25px;

      .pay-line {
        height: 4px;
        width: 24px;
        background-color: $savvyYellow;
        margin-top: 15px;
      }
      .pay-title {
        font-size: $headingSection;
        color: $pureBlack;
        font-family: Avenir-DemiBold;
        margin-bottom: 35px;
      }
      .pay-sub-title {
        font-size: $headingSectionThree;
        color: $pureBlack;
        opacity: 0.9;
        font-family: Avenir-Regular;
      }
      .pay-desc {
        font-family: Avenir-Regular;
        font-size: $headingSectionThree;
        color: $pureBlack;
        margin-top: 5px;
        margin-bottom: 45px;
      }
      .pay-amount {
        @include flexbox();
      }
      .pay-total {
        margin-right: 60px;
      }
      .pay-value {
        color: $pureBlack;
        font-family: Avenir-DemiBold;
        font-size: $headingSection;
        margin-top: -3px;
      }
    }
  }

  .extra-info {
    margin-top: 70px;
    @include flexbox();
    background: $greyWhiteThree;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    max-width: 952px;
    width: 100%;
    padding-bottom: 50px;
    margin-bottom: 30px;
    .extra-logo {
      padding: 14px 30px 14px 14px;
    }
    .extra-title {
      font-size: $headingSectionThree;
      color: $pureBlack;
      font-family: Avenir-Regular;
      opacity: 0.8;
      padding-top: 20px;
      padding-bottom: 10px;
    }
    .extra-desc {
      font-size: $headingSectionThree;
      color: $pureBlack;
      font-family: Avenir-Regular;
    }
  }

  @include screen-below('lg') {
    width: 100%;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    height: auto;
    .pay-box {
      width: 100%;
      .left-box {
        display: none;
      }
      .right-box {
        width: 100%;
      }
    }
    .extra-info {
      display: none;
    }
  }
}

.footer {
  height: 70px;
  width: 100%;
  background-color: $savvyYellow;
  border-radius: 0px 0px 0px 8px;
  @include flexbox();
  @include align-items(center);
  @include justify-content(center);
  font-family: Avenir-DemiBold;
  font-size: $headingSection;
  color: $pureBlack;
  cursor: pointer;
  @include screen-below('lg') {
    text-transform: uppercase;
    position: fixed;
    bottom: 0px;
    left: 0px;
  }
}

.disabled-footer {
  opacity: 0.5;
  cursor: not-allowed;
}
