@import 'scss/variables';
@import 'scss/flex';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';

.setup-sip {
  text-align: center;
  position: relative;
  .custom-btn {
    button {
      font-size: $headingSectionThree;
    }
    padding-bottom: 30px;
  }
  .main-dropdown {
    button {
      width: 90px;
      height: 43px;
      background: #ffffff;
      color: $savvyYellow;
      border: 2.37299px solid $savvyYellow;
      box-sizing: border-box;
      border-radius: 9.49196px;
    }
    .dropdown-menu {
      max-height: 200px;
      overflow-y: auto;
    }
  }
  .product-image-wrapper {
    height: 200px;
    position: relative;
    margin: 2rem 0 2.5rem;

    .product-image {
      background-size: cover;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0.3;
      border-radius: 10px;
    }

    .product-info {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h2 {
        color: $dark-secondary;
        font-family: Avenir-Medium;
      }
      h4 {
        color: $dark-secondary;
        font-family: Avenir-Medium;
        font-size: 1rem;
        margin-bottom: 0.5rem;
        padding-bottom: 0.5rem;
        border-bottom: 1px solid $disabled;
      }
      p {
        color: $dark-secondary;
        font-size: 0.85rem;
        margin: 0;
      }
    }
  }

  .sip-title {
    color: $dark-secondary;
    font-size: 1.3rem;
    font-family: Avenir-Medium;
    margin-bottom: 2rem;
  }

  .content-wrapper {
    display: flex;
    padding: 0 1.5rem;
    border-radius: 15px;
    margin: 0 auto 1rem;
    width: 375px;
    height: 75px;
    @include box-shadow(0px 4px 10px #ddd);
    align-items: center;

    .left {
      flex: 1;
      text-align: left;
      font-size: 1.1rem;
      color: $dark-secondary;
    }
    .right {
      flex: 1;
      text-align: right;
      color: $dark-secondary;
      font-size: 1.1rem;
      font-family: Avenir-Medium;
    }

    .choose-date {
      border: 2px solid $disabled-medium;
      border-radius: 5px;
      padding: 0.5rem;
      margin: 0;
      color: $primary;
      outline: none !important;
    }
  }

  .manage-account {
    color: $disabled;
    font-size: 0.85rem;
    width: 80%;
    margin: 3rem auto 0rem;
  }

  .invested-in {
    color: $disabled;
    font-size: 0.85rem;
    margin: 30px 0px;
  }

  .setup-auto-debit {
    margin-bottom: 4rem;
    padding: 0.65rem;
    width: 250px;
  }

  @include screen-below('lg') {
    .product-image-wrapper {
      height: 150px;
      margin: 0 0 2.5rem;

      .product-image {
        border-radius: 5px;
      }

      .product-info {
        h2 {
          color: $dark-secondary;
          font-family: Avenir-Medium;
        }
        h4 {
          font-size: 1rem;
          margin-bottom: 0.5rem;
          padding-bottom: 0.5rem;
          border-bottom: 1px solid $disabled;
        }
        p {
          font-size: 0.85rem;
          margin: 0;
        }
      }
    }

    .sip-title {
      font-size: 1.3rem;
      margin-bottom: 2rem;
    }

    .content-wrapper {
      padding: 1.5rem;
      border-radius: 15px;
      margin: 0 auto 1rem;
      width: 90%;

      .left {
        font-size: 1.1rem;
      }
      .right {
        font-size: 1.1rem;
      }
    }

    .manage-account {
      font-size: 0.85rem;
      width: 80%;
      margin: 0 auto 4rem;
    }

    .invested-in {
      font-size: 0.85rem;
      width: 70%;
      margin: 0 auto 1.5rem;
    }

    .setup-auto-debit {
      width: 100%;
      margin-bottom: 2rem;
      padding: 0.65rem;
    }
  }
}
