@import 'scss/variables';
@import 'scss/media_queries';

.accordion {
  // position: relative;

  .accordion-wrapper {
    // position: relative;
  }

  .button {
    text-decoration: none;
    width: 100%;
    box-shadow: 0px 4px 3px #ddd !important;
    display: flex;
    background-color: $disabled-light;
    border-radius: 0 0 1rem 1rem;
    padding: 0.75rem 3rem;

    &.disabled {
      opacity: 0.5;
      cursor: default;
    }

    .title {
      flex: 1;
      text-align: left;
      color: $dark-secondary;
      font-family: Avenir-DemiBold;
      font-size: 1.2rem;
    }
    .icon {
      color: $dark-secondary;
    }
  }

  @include screen-below('lg') {
    .button {
      padding: 0.75rem 1rem;
    }
  }
}
