@import 'scss/variables';
@import 'scss/flex';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';

.container {
  background-color: #f3f3f3;
  padding: 0.8rem 0.8rem 0.8rem 1.5rem;

  .title {
    font-weight: 600;
    font-size: $headingSection;
    color: $themeBlack;
    font-family: Avenir-DemiBold;
    margin-bottom: 0.8rem;
  }

  .content {
    display: flex;
    align-items: center;
    .statsContainer {
      display: flex;
      align-items: flex-end;
    }
    .metaContainer {
      margin-left: auto;
    }
  }
}

.stats {
  margin-right: 2rem;
  // text-align: center;
  .value {
    font-size: $largeHeading;
    font-family: Avenir-Regular;
    color: $themeBlack;
  }
  .stats-title {
    font-size: $textParagraphThree;
    text-transform: uppercase;
    margin-top: -8px;
  }
}

.dropdown {
  .dropdownToggle {
    background-color: #fff;
  }
}

.left-side {
  @include flexbox();
  @include justify-content(flex-end);
  .link {
    margin-left: 0.5rem;
  }
}

@include screen-below('lg') {
  // .container {
  //   .content {
  //     flex-direction: column;
  //     .statsContainer{
  //       flex-direction: column;
  //       align-items: center;
  //     }
  //     .metaContainer{
  //       margin: 0;
  //     }
  //   }
  // }
  .container {
    .content {
      align-items: flex-start;
      flex-direction: column;
      .metaContainer {
        margin-left: 0;
      }
    }
  }
  .stats {
    margin-bottom: 1.5rem;
  }
}

@include screen-below('sm') {
  .left-side {
    flex-direction: column;
  }
  .link {
    margin-left: 0;
    margin: 0.7rem 0 1rem 0;
  }
}
