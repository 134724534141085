@import 'scss/variables';
@import 'scss/flex';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';

.pay-kyc {
  @include flexbox();
  @include justify-content(center);
  @include flex-direction(column);
  @include align-items(center);
  padding-left: 20px;
  padding-right: 20px;

  padding-top: 60px;
  .pay-box {
    @include flexbox();
    .left-box {
      @include flexbox();
      @include flex-direction(column);
      @include justify-content(space-between);

      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
      border-radius: 8px;
      height: fit-content;
      margin-top: 100px;
      max-width: 810px;
      min-height: 450px;
      @include screen-up('xxl') {
        min-width: 700px;
      }
    }

    .pay-line {
      height: 4px;
      width: 24px;
      background-color: $savvyYellow;
      margin-top: 15px;
    }
    .pay-title {
      font-size: $headingSection;
      color: $pureBlack;
      font-family: Avenir-DemiBold;
      margin-bottom: 35px;
    }
    .footer {
      height: 70px;
      width: 100%;
      background-color: $savvyYellow;
      border-radius: 0px 0px 0px 8px;
      color: $pureBlack;
      font-size: $headingSection;
      @include flexbox();
      @include justify-content(center);
      @include align-items(center);
      font-family: Avenir-DemiBold;
      cursor: pointer;
    }
  }

  .extra-info {
    margin-top: 70px;
    @include flexbox();
    background: $greyWhiteThree;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    max-width: 952px;
    width: 100%;
    padding-bottom: 50px;
    margin-bottom: 30px;
    .extra-logo {
      padding: 14px 30px 14px 14px;
    }
    .extra-title {
      font-size: $headingSectionThree;
      color: $pureBlack;
      font-family: Avenir-Regular;
      opacity: 0.8;
      padding-top: 20px;
      padding-bottom: 10px;
    }
    .extra-desc {
      font-size: $headingSectionThree;
      color: $pureBlack;
      font-family: Avenir-Regular;
    }
  }

  @include screen-below('lg') {
    width: 100%;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    .pay-box {
      width: 100%;
      .left-box {
        max-width: 100%;
        margin-top: 0px;
        box-shadow: unset;
        border-radius: unset;
      }
      .right-box {
        display: none;
        width: 100%;
      }
    }
    .extra-info {
      display: none;
    }
  }
}
