@import 'scss/variables';
@import 'scss/flex';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';

.offer-tab-item {
  .offer-content {
    .offer-upper-header {
      padding-top: 25px;
      width: 90%;
      margin: auto;

      @include flexbox();
      .offer-header-tab-arrow {
        @include flexbox();
        @include align-items(center);
        padding: 0px 30px;
      }
      .offer-header-tab {
        .offer-header-title {
          color: $dark;
          font-size: $textParagraphThree;
          font-family: Avenir-Regular;
          text-align: left;
          span {
            font-family: Avenir-Medium;
            color: $pureBlack;
          }
          line-height: 17px;
        }
        .offer-header-heading {
          color: $pureBlack;
          font-size: $headingSectionTwo;
          font-family: Avenir-Medium;
          text-align: left;
        }
        .offer-header-heading-bold {
          color: $pureBlack;
          font-size: $headingSectionTwo;
          font-family: Avenir-Regular;
          font-weight: 600;
          span {
            font-size: $textParagraphThree;
          }
        }
      }
    }

    .offer-detail {
      @include border-radius(0px 0px 15px 15px);
      padding: 25px 0;
      // border-bottom: 1px solid rgba(0, 0, 0, 0.8);
      .header {
        margin: auto;
        width: 90%;
        font-size: $textParagraphOne;
        color: $footerBlack;
        font-family: Avenir-DemiBold;
      }

      .earn-cashback {
        text-align: center;
        padding-top: 0.5rem;

        span {
          color: $success-light;
          font-family: Avenir-DemiBold;
        }
      }
      .tnc-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 1rem;
        padding-left: 10px;
        padding-right: 10px;
        .tnc-checkbox {
          margin-right: 5px;
          height: 18px;
          width: 18px;
          cursor: pointer;
          position: relative;
          top: 4px;
        }
        .tnc-text {
          font-size: $textParagraphThree;
          color: $themeBlack;
        }
      }
      .pay-first-btn {
        padding-bottom: 20px;
        button {
          font-size: $h5;
          padding: 8px 45px !important;
          border: unset;
          padding: 0.5rem 2rem;
          @include border-radius(10px !important);
        }
      }
      .total-amount-content {
        font-size: $textParagraphThree;
        margin-left: -1px;
        width: 90%;
        margin: 0px auto;
        color: $footerBlack;
        span {
          font-family: Avenir-Medium;
        }
      }
      .offer-amount-content {
        font-size: 12px;
        margin-left: -1px;
        width: 90%;
        margin: 0px auto;
        padding-bottom: 10px;
      }

      .timeline-wrapper {
        display: flex;
        justify-content: center;
        margin-top: 8px;
        margin-bottom: 1.5rem;
        .timeline {
          width: 90%;
          position: relative;
          margin: auto;

          .dark-box {
            color: $pureBlack;
            font-family: Avenir-DemiBold;
          }
          .grey-bg {
            background-color: rgba(255, 255, 255, 0.15);
          }
          .common-box {
            position: relative;
            margin-bottom: 10px;
            @include border-radius(0px 6px 6px 0px);
            .icici-link {
              padding-left: 18px;
            }
            .hidder {
              width: 10px;
              height: 51px;
              background-color: $pureWhite;
              position: absolute;
              top: 33px;
              left: -4px;
              z-index: 3;
            }
            .content {
              padding: 1rem 0rem 0.7rem 1rem;
              // font-size: 1rem;
              display: flex;
              // font-size: $h6;
              .left {
                // @include flexbox();
              }
              .right {
                margin-left: auto;
              }
            }

            &:after {
              position: absolute;
              height: 13px;
              width: 13px;
              border-radius: 50%;
              left: -6px;
              top: 21px;
              content: '';
              z-index: 3;
              background: #c4c4c4;
            }
          }

          .common-box-after {
            &:after {
              position: absolute;
              height: 10px;
              width: 10px;
              border-radius: 50%;
              background: $savvyYellow !important;
              left: -4.5px;
              top: 18px;
              content: '';
              z-index: 3;
            }
          }
          .tick {
            position: absolute;
            left: -13px;
            z-index: 5;
            top: 14px;
            width: 26px;
          }
          .buy-the-holiday {
            margin-bottom: 0px;
            .content {
              padding-bottom: 0px;
            }
          }

          .point-node {
            // padding: 5px;
            position: relative;
            margin-bottom: 1rem;
            .content {
              padding: 0px 0px 0px 35px;
              font-size: 1rem;
              display: flex;
              align-items: center;
              color: $footerBlack;
              font-size: $textParagraphThree;
              .left {
                // @include flexbox();
                font-family: Avenir-Medium;
              }
              .right {
                margin-left: auto;
              }
            }

            &:after {
              position: absolute;
              height: 1px;
              width: 26px;
              background: #c4c4c4;
              left: 0px;
              top: 9px;
              content: '';
              z-index: 3;
            }
          }

          .point-node-last {
            margin-bottom: 0.2rem !important;
          }

          .node {
            position: relative;
            z-index: 3;
            .content {
              font-size: $h4;
              color: $gray-light;
              margin-bottom: 1rem;
              background-color: #f3f3f3;
            }
          }
          &:after {
            position: absolute;
            height: calc(100% - 13.5px);
            width: 1px;
            background: rgb(207, 207, 207);
            left: 0;
            top: 0;
            content: '';
            z-index: 2;
          }
          .first-common-box {
            margin-bottom: 0px;
            .content {
              padding-top: 0px;
            }
            .tick {
              position: absolute;
              left: -13px;
              z-index: 5;
              top: 0px;
              width: 26px;
            }
            &:after {
              position: absolute;
              height: 13px;
              width: 13px;
              border-radius: 50%;
              left: -6px;
              top: 3px;
              content: '';
              z-index: 3;
              background: #c4c4c4;
            }
          }
        }
      }
    }

    @include screen-below('lg') {
      .offer-upper-header {
        text-align: center;
        .offer-header-tab-arrow {
          @include flexbox();
          @include align-items(center);
          padding: 10px 1px;
          justify-content: center;
          width: 100%;
          img {
            width: 8px;
          }
        }
        .offer-header-tab {
          .offer-header-title {
            color: $dark;
            font-size: $textFinePrint;
            font-family: Avenir-Regular;
            text-align: center;
            white-space: pre;
            span {
              font-family: Avenir-Medium;
            }
            line-height: 17px;
          }
          .offer-header-heading {
            font-size: $textParagraphThree;
            font-family: Avenir-Regular;
            font-weight: 600;
          }
          .offer-header-heading-bold {
            font-size: $textParagraphThree;
          }
        }
      }

      .offer-detail {
        .timeline-wrapper {
          .timeline {
            .point-node {
              .content {
                font-size: 0.87rem;
              }
            }
            .earn-cashback {
              font-size: 0.87rem;
            }
          }
          .content {
            font-size: 0.87rem;
          }
          .icici-link {
            font-size: 0.87rem;
          }
        }
      }
    }
  }
}
