@import 'scss/variables';
@import 'scss/flex';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';

.complete-my-kyc {
  .title {
    font-family: Avenir-DemiBold;
    font-style: normal;
    font-size: 28px;
    line-height: 24px;
    text-align: center;
    color: rgba(0, 0, 0, 0.7);
    padding: 30px 10px 25px 10px;
  }
  .product-name {
    font-family: Avenir-DemiBold;
    font-style: normal;
    font-size: 22px;
    text-align: center;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.7);
    padding: 0px 10px 10px 0px;
  }
  .accordion {
    max-width: 561px;
    margin: 0px auto;
  }
}
