@import 'scss/variables';
@import 'scss/media_queries';
@import 'scss/_theme';

.fund-info {
  .wrapper {
    background-color: #fbfbfb;
    border-radius: 1.5rem;
    box-shadow: 0px 5px 5px #ccc;
    width: 500px;
    // width: 100%;

    .top-container {
      background-color: #f3f3f3;
      padding: 1rem 1.5rem;
      box-shadow: 0px 1px 4px #ccc;
      border-radius: 1.5rem;

      .title {
        font-size: $headingSection;
        font-family: Avenir-DemiBold;
        color: $offBlack;
        border-bottom: 1px solid #ddd;
        margin-bottom: 1rem;
        line-height: 35px;
        padding-bottom: $textFinePrint;
      }
      .details {
        display: flex;
        flex: 1;

        .invested {
          display: flex;
          flex-direction: column;
          justify-content: center;
          flex: 1;
          position: relative;

          .year-ago {
            color: $offBlack;
            font-size: $textFinePrint;
          }
          .if-saved {
            color: $offBlack;
            font-size: $textParagraphOne;
            font-family: Avenir-Medium;
          }
          .amount {
            font-family: Avenir-DemiBold;
            font-size: $textSection;
          }
          .chevron-icon {
            color: #ffffff;
            font-size: 4rem;
            position: absolute;
            right: 5%;
            width: 35px;
          }
          .chevron-icon-2 {
            right: 10%;
          }
        }
        .become {
          display: flex;
          flex-direction: column;
          justify-content: center;
          flex: 1;
          padding-left: 1rem;
          .year-ago {
            color: $offBlack;
            visibility: hidden;
            font-size: $textFinePrint;
          }
          .have-become {
            color: $offBlack;
            font-size: $textParagraphOne;
            font-family: Avenir-Medium;
          }
          .amount {
            font-family: Avenir-DemiBold;
            font-size: $textSection;
          }
        }
      }
    }
    .bottom-container {
      display: flex;
      flex: 1;
      padding: 10px 0;

      .risk {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex: 1;
        color: $green;
        font-size: $textFinePrint;
        font-family: Avenir-Medium;
        border-right: 1px solid #ddd;

        img {
          width: 70px;
        }
      }
      .returns {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex: 1;
        font-family: Avenir-Medium;
        color: $offBlack;
        font-size: $textFinePrint;
        border-right: 1px solid #ddd;
        .percentage {
          font-family: Avenir-Medium;
          margin-bottom: 2px;
          color: $offBlack;
          font-size: $textParagraphOne;
        }
        .year-returns {
          color: $offBlack;
          font-size: $textFinePrint;
        }
      }
      .suitable {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex: 1;
        font-family: Avenir-Medium;
        color: $offBlack;
        font-size: $textFinePrint;

        img {
          width: 80px;
          margin-bottom: 6px;
        }
      }
      .dropdown {
        flex: 1;
      }
    }
  }

  &.overview {
    background-color: #f3f3f3;
    padding: 1.4rem 2rem;
    border-radius: 8px;
    height: 100%;

    .heading {
      text-align: center;
      font-size: $textSection;
      font-family: Avenir-DemiBold;
      color: $offBlack;
      padding-bottom: 20px;
    }

    .wrapper {
      width: 100%;
      border-radius: 1rem;

      .top-container {
        border-radius: 1rem;
        padding: 0.75rem 1rem;
        background-color: transparent;
        box-shadow: 0px 1px 4px #ccc;

        .title {
          margin-bottom: 0.5rem;
          font-size: $textSection;
          font-family: Avenir-DemiBold;
          color: $offBlack;
          line-height: 25px;
          padding-bottom: 3px;
        }
        .details {
          .invested {
            .year-ago {
              font-size: $textFinePrint;
              color: $offBlack;
            }
            .if-saved {
              font-size: $textParagraphTwo;
              color: $offBlack;
            }
            .amount {
              font-size: $textSection;
              color: $offBlack;
            }
            .chevron-icon {
              font-size: 3rem;
              color: #ddd;
              margin-top: 8px;
            }
          }
          .become {
            padding-left: 1rem;
            .year-ago {
              font-size: $textFinePrint;
              color: $offBlack;
              visibility: hidden;
            }
            .have-become {
              font-size: $textParagraphTwo;
              color: $offBlack;
            }
            .amount {
              font-size: $textSection;
              color: $offBlack;
            }
          }
        }
      }

      .bottom-container {
        padding: 1.1rem 0 0.6rem 0rem;

        .risk {
          font-size: $textFinePrint;
          color: $green;
          img {
            width: 60px;
          }
        }
        .returns {
          font-size: $textFinePrint;
          color: black;

          .percentage {
            font-size: $textParagraphTwo;
            color: black;
            margin-bottom: 0px;
          }
          .year-returns {
            font-size: $textFinePrint;
          }
        }
        .suitable {
          font-size: $textFinePrint;
          color: black;

          img {
            width: 74px;
            margin-bottom: 4px;
          }
        }
      }
    }
  }
}

@include screen-below('lg') {
  .fund-info {
    margin-bottom: 1rem;

    .heading {
      font-family: Avenir-DemiBold;
      color: rgba(0, 0, 0, 0.7);
      text-align: center;
      font-size: 1.2rem;
    }

    .wrapper {
      width: 100%;
      border-radius: 1rem;

      .top-container {
        border-radius: 1rem;
        padding: 0.75rem 1rem;
        background-color: transparent;
        box-shadow: 0px 1px 4px #ccc;

        .title {
          font-size: 1em;
          margin-bottom: 0.5rem;
          padding-right: 2rem;
        }
        .details {
          .invested {
            .year-ago {
              font-size: 0.6rem;
            }
            .if-saved {
              font-size: 0.7rem;
            }
            .amount {
              font-size: 1rem;
            }
            .chevron-icon {
              font-size: 3rem;
              color: #ddd;
            }
          }
          .become {
            padding-left: 1rem;

            .have-become {
              font-size: 0.7rem;
            }
            .amount {
              font-size: 1rem;
            }
          }
        }
      }

      .bottom-container {
        padding: 1.1rem 0 0.6rem 0rem;

        .risk {
          font-size: $textFinePrint;
          color: $green;
          img {
            width: 60px;
          }
        }
        .returns {
          font-size: 0.6rem;

          .percentage {
            font-size: 1rem;
          }
        }
        .suitable {
          font-size: 0.6rem;

          img {
            width: 60px;
          }
        }
      }
    }
  }

  &.overview {
    margin-top: 0 !important;
    padding: 1rem !important;
  }
}

// fix for height of fund-info in lg to xxxl screens
@media (min-width: 992px) and (max-width: 1399px) {
  .fund-info {
    &.overview {
      padding: 1rem 2rem 2rem 2rem;
    }
  }
}
