@import 'scss/variables';
@import 'scss/flex';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';

$minWidth: 453px;

.desktop-discount {
  border-bottom: 2px solid $gray-medium;
  display: block;
  margin-bottom: 10px;
  margin-top: -20px;
  padding: 10px 30px 10px 50px;
  .disc-title {
    font-size: $textParagraphThree;
  }
  .view-discount-title {
    font-size: $textParagraphThree;
    span {
      font-family: Avenir-Bold;
    }
  }
  @include screen-below('lg') {
    display: none;
  }
}

.desktop-discount-margin-top {
  margin-top: 0px;
}

.offer-box {
  .header {
    @include flexbox();
    @include justify-content(center);
    padding: 15px 30px;
    .inner-header {
      @include flexbox();
      @include justify-content(flex-start);
      max-width: $minWidth;
      width: 100%;
      .sub-inner-header {
        @include flexbox();
        @include justify-content(center);
      }
    }
    .logo {
      width: 95px;
      height: 95px;
      border-radius: 100%;
      background-color: $extra-light;
      object-fit: contain;
      @include flexbox();
      @include justify-content(center);
      @include align-items(center);

      img {
        width: 100%;
      }
    }
    .content {
      padding-left: 20px;
      .title {
        font-family: Avenir-Bold;
        color: $pureBlack;
        font-size: $headingSectionThree;
      }
      .identity {
        font-size: $textFinePrint;
        font-family: Avenir-Regular;
        color: $themeBlack;
        margin-bottom: 25px;
      }
      .price {
        font-size: $textParagraphThree;
        font-family: Avenir-Regular;
        text-decoration: line-through;
        color: $themeBlack;
        line-height: 10px;
      }
      .discount {
        font-family: Avenir-Bold;
        color: $pureBlack;
        font-size: $headingSection;
      }
    }
  }

  .schedule-date {
    font-size: $textFinePrint;
    color: $offBlack;
    font-family: Avenir-Medium;
    text-transform: uppercase;
    @include flexbox();
    padding-top: 5px;
  }

  .schedule-date-title {
    font-family: Avenir-Bold;
    margin-right: 5px;
  }
  .disabled-schedule-date {
    opacity: 0.5;
  }

  .schedule-title {
    font-size: $textFinePrint;
    color: $offBlack;
    font-family: Avenir-Medium;
    text-transform: uppercase;
    padding: 15px 50px 20px 0px;
  }

  .save-title {
    font-size: $headingSectionThree;
    color: $offBlack;
    font-family: Avenir-Bold;
    text-transform: uppercase;
    padding: 5px 10px 0px 50px;
  }
  .schedule-title-returns {
    font-size: $textFinePrint;
    color: $pureBlack;
    padding: 2px 15px 5px 50px;
    border-top: 2px solid $gray-medium;
    border-bottom: 2px solid $gray-medium;
    margin-top: 0px;
    span {
      font-family: Avenir-Medium;
    }
    margin-bottom: 20px;
  }
  .returns-title {
    color: $green;
  }
  .empty-box {
    height: 60px;
  }
  .info-title {
    font-size: $textFinePrint;
    color: $offBlack;
    font-family: Avenir-Medium;
    text-transform: uppercase;
    padding: 20px 40px 10px 0px;
  }

  .info-desc {
    font-size: $textParagraphOne;
    color: $offBlack;
    text-align: center;
    a {
      text-decoration: underline;
      color: $offBlack;
    }
  }

  .info-box {
    border: 1px solid $disabled-medium;
    border-radius: 4px;
    margin: 0px 30px;
    width: fit-content;
    @include flexbox();
    color: $offBlack;
    font-size: $textFinePrint;
    @include justify-content(space-around);
    padding: 10px 20px;
    margin: auto;
    .info-line {
      width: 1px;
      height: 14px;
      background-color: $pureBlack;
      margin-top: 1px;
      margin-left: 16px;
      margin-right: 16px;
    }
    .info-phone {
      min-width: 120px;
    }
    .info-email {
      min-width: 120px;
    }
  }

  .your-save {
    font-size: $textFinePrint;
    color: $greyBlack;
    text-align: center;
    padding: 16px 10px 70px 10px;
    display: none;
  }

  // .footer {
  //   font-family: Avenir-Bold;
  //   color: $pureBlack;
  //   font-size: $headingSection;
  //   background-color: $savvyYellow;
  //   text-align: center;
  //   padding: 13px 10px;
  //   position: fixed;
  //   left: 0px;
  //   width: 100%;
  //   bottom: 0px;
  //   z-index: 100;
  //   img {
  //     margin-right: 4px;
  //     margin-left: 7px;
  //   }
  // }

  .proceed-btn {
    font-family: Avenir-Bold;
    color: $pureBlack;
    font-size: $headingSection;
    background-color: $savvyYellow;
    text-align: center;
    padding: 13px 10px;
    position: fixed;
    left: 0px;
    width: 100%;
    bottom: 0px;
    z-index: 100;
    border: none;
    img {
      margin-right: 4px;
      margin-left: 7px;
    }

    &:disabled {
      background-color: #ffeea0;
      color: #999999;
    }
  }
  .desktop-your-save {
    display: none;
  }
  .encrypt {
    padding-top: 2px;
    padding-bottom: 15px;
    .encrypt-inner {
      padding: 10px 0px 5px 0px;
      background-color: $gray-medium;
      text-align: center;
      .encrypt-text {
        color: $greyBlack;
        font-size: $textFinePrint;
        font-style: italic;
        a {
          color: $greyBlack;
        }
      }
    }
    @include screen-below('lg') {
      padding-bottom: 80px;
    }
  }

  .mobile-view-discount {
    .mobile-pay-heading {
      color: $pureBlack;
      font-family: Avenir-DemiBold;
      font-size: 0.9rem;
      margin: 10px 0;
      padding: 0 25px 0;
    }

    .mobile-options-container {
      display: flex;
      border-top: 1px solid $disabled-light;

      .option-button {
        flex: 1;
        border: none;
        background-color: transparent;
        align-items: center;
        padding: 10px 0;
        box-shadow: 0px 1px 3px $disabled-medium;
        border-radius: 0 0 4px 4px;

        img {
          width: 45px;
          height: 45px;
          margin: 0 5px;
        }
        h4 {
          color: $pureBlack;
          font-family: Avenir-Regular;
          font-size: 0.9rem;
          margin: 0;
        }

        &:first-child {
          box-shadow: 0px 1px 3px $disabled-medium;
        }

        &.active {
          h4 {
            font-family: Avenir-DemiBold;
          }
          box-shadow: none;
        }
      }
    }
    .mobile-view-discount-content {
      @include flexbox();
      @include justify-content(center);
      padding: 0px 30px;
      width: 100%;
      .mobile-view-sub-discount-content {
        @include flexbox();
        @include justify-content(flex-start);
        width: 100%;
        max-width: $minWidth;
        border-bottom: 1px solid $disabled-light;
      }
    }
    .total-cost {
      color: $themeBlack;
      font-family: Avenir-Regular;
      font-size: $textParagraphThree;
      padding-top: 10px;
    }
    .view-discount {
      @include flexbox();
      font-family: Avenir-Regular;
      font-size: $textFinePrint;
      color: $greyBlack;
      padding-bottom: 10px;
      padding-top: 3px;

      .view-discount-title {
        img {
          margin-top: -4px;
        }
      }
      span {
        font-family: Avenir-DemiBold;
      }
      .discount-line {
        width: 1px;
        height: 14px;
        margin: 0px 10px;
        background-color: $pureBlack;
      }
      .view-discount-btn {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .main-schedule {
    @include flexbox();
    @include justify-content(center);
    padding: 0px 30px;
    .inner-main-schedule {
      @include flexbox();
      @include justify-content(flex-start);
      max-width: $minWidth;
      width: 100%;
      .sub-inner-main-schedule {
        @include flexbox();
        @include justify-content(center);
      }
    }
  }

  .your-info {
    @include flexbox();
    @include justify-content(center);
    padding: 0px 30px;
    .inner-your-info {
      @include flexbox();
      @include justify-content(flex-start);
      max-width: $minWidth;
      width: 100%;
      .sub-inner-your-info {
        @include flexbox();
        @include justify-content(center);
      }
    }
    @include screen-below('lg') {
      padding: 0px 10px;
      .info-title {
        text-align: center;
      }
      .sub-inner-your-info {
        width: 100%;
      }
      .info-box {
        margin: 0px auto;
        width: 100%;
      }
      .inner-your-info {
        max-width: 100%;
      }
      .info-phone {
        min-width: unset;
      }
      .info-email {
        min-width: unset;
      }
    }
  }

  .mobile-discount-applied {
    width: 100%;
    position: fixed;
    bottom: 0px;
    z-index: 100;
    padding: 0px 4px;
    background-color: $pureWhite;
    display: block;
    .mobile-discount-applied-inner {
      background-color: $savvyYellow;
      height: 366px;
      border-radius: 30px 30px 0px 0px;
      .mobile-discount-applied-header {
        @include flexbox();
        @include justify-content(space-between);
        padding: 16px 35px 0px 0px;
      }
      .applied-title {
        text-transform: uppercase;
        @include flexbox();
        @include justify-content(center);
        width: 100%;
        font-family: Avenir-Medium;
        padding-left: 5%;
        font-size: $textParagraphOne;
        color: $blackTwo;
      }
    }

    .below-content {
      @include flexbox();
      @include justify-content(center);
      @include flex-direction(column);
      @include align-items(center);
      padding-top: 40px;
      .cost-list {
        @include flexbox();
        @include justify-content(space-between);
        width: 275px;
        font-size: $headingSectionThree;
        color: $blackTwo;
        font-family: Avenir-Regular;
        padding: 5px 0px;
      }
      .cost-line {
        width: 100%;
        height: 2px;
        width: 275px;
        border-top: 2px dashed $pureWhite;
      }
      .total-amount-saved {
        font-size: $textParagraphOne;
        color: $themeBlack;
        font-family: Avenir-Regular;
        padding-top: 40px;
        span {
          font-family: Avenir-Bold;
        }
      }
    }
  }

  .payment-terms {
    font-size: $textFinePrint;
    a {
      color: $pureBlack;
    }
    text-align: center;
    padding: 16px 10px 0px 10px;
  }

  @include screen-up('lg') {
    box-shadow: 0px 5.03333px 5.03333px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    width: 453px;
    position: relative;
    z-index: 1;
    background-color: white;
    .mobile-discount-applied {
      display: none;
    }
    .main-schedule {
      padding: 0px 0px;
    }
    .mobile-view-discount {
      display: none;
    }

    .scheduler {
      padding: 0px 70px;
    }
    .info-box {
      font-size: $textParagraphOne;
      .info-line {
        margin-top: 5px;
      }
    }
    .schedule-title {
      font-size: $textFinePrint;
      color: $offBlack;
      font-family: Avenir-Medium;
      text-transform: uppercase;
      padding: 15px 50px 20px 50px;
    }
    .schedule-title-bsb {
      padding: 0px 50px 10px 50px;
    }
    .header {
      @include justify-content(flex-start);
      .content {
        .title {
          font-size: $headingSection;
        }
        .identity {
          font-size: $textParagraphThree;
          margin-bottom: 15px;
        }
        .discount {
          font-size: $headingMainTwo;
        }
      }
    }

    .your-save {
      font-size: $textFinePrint;
      color: $greyBlack;
      text-align: center;
      padding: 16px 10px 10px 10px;
      display: none;
    }
    .desktop-your-save {
      display: block;
      img {
        margin-right: 4px;
        margin-top: -5px;
      }
      padding: 20px 10px 10px 10px;
    }
    .your-save-bsb {
      padding: 10px 10px 0px 10px;
    }
    // .footer {
    //   display: none;
    // }

    .proceed-btn {
      display: block;
      width: 100%;
      position: unset;
      background-color: $savvyYellow;
      border-radius: 0px 0px 10px 10px;
      font-size: $headingSection;
      font-family: Avenir-DemiBold;
      height: 78px;
      color: $pureBlack;
      @include flexbox();
      @include justify-content(center);
      @include align-items(center);
      text-transform: uppercase;
      display: none;
    }

    .progress-bar {
      height: 4px;
      width: 100%;
      background-color: $greyWhite;
    }
    .active-progress-bar {
      background-color: $savvyYellow;
      height: 4px;
    }
  }
}

.success-bg {
  background-color: $successGreen !important;
}
.scheduler {
  padding: 0px 20px;
  .scheduler-progress-box {
    @include flexbox();
    .scheduler-content {
      padding-bottom: 20px;

      .scheduler-title {
        font-size: $textParagraphOne;
        font-family: Avenir-Bold;
        color: $offBlack;
        line-height: 20px;
      }
      .scheduler-desc {
        font-family: Avenir-Regular;
        font-size: $textFinePrint;
        .scheduler-ul {
          padding-top: 10px;
          .scheduler-list {
            margin-bottom: 6px;
            padding-left: 16px;
            font-size: 12px;
          }
          .scheduler-list-first {
            font-family: Avenir-Bold;
          }
        }
      }

      .disabled-scheduler-title {
        color: $offBlack;
        opacity: 0.5;
      }
      .disabled-scheduler-desc {
        opacity: 0.5;
      }
      .scheduler-down-icon {
        opacity: 1;
        top: 0px;
        transition: 0.1s;
        z-index: 2;
        cursor: pointer;
      }
      .scheduler-up-icon {
        transform: rotate(180deg);
        transition: 0.1s;
      }
    }
    .scheduler-progress-bar {
      position: relative;
      margin-right: 5px;

      .scheduler-progress-line {
        width: 2px;
        position: absolute;
        top: 0px;
        height: 100%;
        background-color: $input-border;
        left: 10px;
      }
      .scheduler-progress-number {
        width: 22px;
        position: relative;
        height: 22px;
        background-color: $input-border;
        color: $offBlack;
        border-radius: 100%;
        font-size: 6px;
        z-index: 2;
        @include flexbox();
        @include justify-content(center);
        @include align-items(center);
        line-height: 18px;
        border: 4px solid $pureWhite;
      }
      .active-progress-line {
        background-color: $savvyYellow;
      }
      .active-progress-number {
        background-color: $savvyYellow;
      }
      .progress-number-layer {
        padding: 0px 4px;
        border-radius: 100%;
        background-color: $pureWhite;
        position: relative;
        left: -5px;
        .progress-number-border {
          border: 1px solid transparent;
          border-radius: 100%;
        }
      }
      .active-progress-number-layer {
        padding: 4px 4px;

        .active-progress-number-border {
          border: 1px solid $savvyYellow;
        }
      }
    }

    @include screen-up('lg') {
      .scheduler-content {
        .scheduler-title {
          font-size: $headingSectionTwo;
          padding-top: 5px;
        }
        .scheduler-desc {
          font-size: $textParagraphOne;
        }
      }
      .scheduler-progress-bar {
        .scheduler-progress-line {
          left: 14px;
        }
        .scheduler-progress-number {
          width: 28px;
          height: 28px;
          font-size: 8px;
        }
      }
    }
  }
}
