@import 'scss/variables';
@import 'scss/flex';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';

// modal
.mandate-modal {
  background-color: rgba(255, 255, 255, 0.75);

  .modal-dialog {
    max-width: unset !important;

    .modal-content {
      max-width: 400px !important;
      margin: 0px auto;
      @include border-radius(16px);
      background-color: unset;
      border: unset;
      .lock-fare-deposite-box {
        @include border-radius(16px);
        background-color: white;
        margin-top: 50px;
        text-align: center;
        position: relative;
        .cross-icon {
          top: 20px;
          right: 20px;
          cursor: pointer;
          img {
            width: 20px;
            opacity: 0.6;
          }
          position: absolute;
        }
        .lock-fare-title {
          font-size: 18px;
          line-height: 21px;
          font-family: Avenir-DemiBold;
          color: rgba(0, 0, 0, 0.7);
          margin-bottom: 1rem;
        }
        .lock-fare-ok-icon {
          width: 125px;
          height: 125px;
          margin-top: -50px !important;
          margin-bottom: 10px !important;
        }
        .lock-fare-error-icon {
          width: 100px;
          height: 100px;
          margin-top: -50px !important;
          margin-bottom: 50px !important;
        }
        .lock-fare-row {
          font-size: 16px;
          line-height: 16px;
          margin-bottom: 10px;
          color: $gray-text;
        }
        .lock-fare-failed-reason {
          margin-bottom: 50px;
        }
        .lock-fare-start-saving {
          font-size: 18px;
          line-height: 38px;
          font-family: Avenir-DemiBold;
          color: rgba(0, 0, 0, 0.7);
          margin-bottom: 30px;
        }
        .verify-kyc-btn {
          width: 300px;
          height: 40px;
          @include border-radius(7px);
          background-color: $savvy-yellow;
          outline: unset;
          border: unset;
          font-size: 16px;
          font-family: Avenir-DemiBold;
          color: white;
          margin-bottom: 60px;
          display: flex;
          justify-content: center;
          align-items: center;
          text-decoration: none;
          margin: auto auto 2rem auto;
        }
      }
    }
  }
  @include screen-below('lg') {
    .modal-dialog {
      max-width: 820px;
      .modal-content {
        @include border-radius(16px);
        background-color: unset;
        border: unset;
        .lock-fare-deposite-box {
          @include border-radius(16px);
          background-color: white;
          margin-top: 50px;
          text-align: center;
          .lock-fare-ok-icon {
            margin-top: -25px;
            margin-bottom: 50px;
          }
          .lock-fare-row {
            font-size: 16px;
            line-height: 21px;
            color: $gray-text;
            padding: 0px 10px;
          }
          .lock-fare-start-saving {
            font-size: 18px;
            line-height: 21px;
            font-family: Avenir-DemiBold;
            color: rgba(0, 0, 0, 0.7);
            margin-bottom: 40px;
            padding: 0px 10px;
          }
          .verify-kyc-btn {
            width: 273px;
            height: 40px;
            @include border-radius(7px);
            background-color: $savvy-yellow;
            outline: unset;
            border: unset;
            font-size: 16px;
            font-family: Avenir-DemiBold;
            color: white;
            margin-bottom: 45px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
          }
        }
      }
    }
  }
}
