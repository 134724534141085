@import 'scss/variables';
@import 'scss/media_queries';
@import 'scss/theme';

.titleContainer {
  position: relative;
  min-height: 58px;
  height: 58px;

  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid $greyWhite;

  .title {
    font-weight: 600;
    display: inline-block;
    font-size: $baseFontSize;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 20px;
    padding-right: 20px;
    color: $pureWhite;
    text-transform: uppercase;
  }

  .menuCollapseButton {
    position: absolute;
    left: 0px;
    background-color: #fff;
    border: 0;
    display: flex;
    height: 100%;
    width: 2rem;
    align-items: center;
    justify-content: center;
  }
}

.menu {
  list-style-type: none;
  padding: 0px;
  .listItem {
    padding: 0.5rem 0;

    .linkContainer {
      .linkItem {
        display: flex;
        align-items: center;
        text-decoration: none;
        font-size: 1rem;
        color: $dark;
        font-weight: 600;
        &.active {
          color: $primary;
        }
      }
    }
  }

  .childMenu {
    list-style-type: none;
    padding-left: 0.5rem;

    .childItemStyle {
      font-size: 1rem;
    }
  }
}

.footerContainer {
  display: flex;
  // align-items: center;
  margin-top: auto;
  padding: 1rem;
  border-top: 1px solid $borderBlack;
  justify-content: center;

  .logo {
    height: 35px;
  }
}

.circleBox {
  background: $pureWhite;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  display: flex;
  border: 0;
}

.menu-box {
  position: relative;
}
.menu-box-inner {
  opacity: 1;
  transition: opacity 0.4s ease-in-out;
}
.menu-box-inner-inactive {
  opacity: 0.05;
  transition: opacity 0.1s ease-in-out;
}
.menu-box-outer {
  width: 0px;
  overflow: hidden;
  position: absolute;
  top: 0px;
  left: 0px;
  a {
    white-space: pre;
    padding-left: 0px !important;
    padding-right: 0px !important;
    transition: padding 0.7s;
  }
  transition: width 0.3s ease-in, overflow 0.5s ease-in-out;
}

.menu-box-outer-active {
  width: 100%;
  overflow: unset;

  a {
    white-space: pre;
    padding-left: 50px !important;
    padding-right: 30px !important;
    transition: padding 0.7s;
  }
  transition: width 0.5s, overflow 0.5s;
}

@include screen-below('xl') {
  .userMenu {
    span {
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 6rem;
      overflow: hidden;
    }
  }
  .titleContainer {
    .title {
      font-size: 1rem;
    }
  }
}

.savvy-icon {
  cursor: pointer;
}

@include screen-up('lg') {
  .titleContainer {
    .menuCollapseButton {
      display: none;
    }
  }
}

@include screen-below('lg') {
  .titleContainer {
    justify-content: flex-end;
    margin-right: 0.5rem;
    .title {
      font-size: 1.2rem;
    }
  }
  .userMenu {
    span {
      max-width: 10rem;
    }
  }
}
