@import 'scss/media_queries';
@import 'scss/variables';
@import 'scss/_theme';
@import 'scss/_flex';

.fund-information {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 2rem;

  .title {
    font-family: Avenir-DemiBold;
    color: $offBlack;
    font-size: $headingSection;
    padding-top: 20px;
    margin-bottom: 30px;
  }

  .description {
    font-family: Avenir-Regular;
    font-size: $headingSection;
    margin-bottom: 35px;
    color: $offBlack;
  }
  .fund-info-box {
    padding-bottom: 20px;
  }

  .know-more {
    margin-top: 1rem;
    background-color: $savvyYellow !important;
    border: 1px solid $savvyYellow;
    font-size: $headingSection;
    font-family: Avenir-DemiBold;
    color: black !important;
    border-radius: 7px !important;
    padding: 7px 20px;
    margin-bottom: 50px;
  }
}

@include screen-below('lg') {
  .fund-information {
    padding: 0;
    margin: 1rem 0;

    .title {
      display: none;
    }
    .description {
      display: none;
    }
  }
}
