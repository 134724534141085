@import 'scss/variables';
@import 'scss/theme';

.inputLabelContainer {
  margin-bottom: 0.2rem;
  .inputLabel {
    font-weight: 600;
    font-size: $inputLabel;
  }
  .largeLabel {
    font-size: $headingSectionThree;
    color: $themeBlack;
  }
}
