@import 'scss/theme';

.input{
  width: 100%;
  height: map-get($textInput , "height");
  font-size: map-get($textInput , "fontSize");
  padding: map-get($textInput , "padding");
  color: $greyBlack;
  border-radius: map-get($textInput , "borderRadius");
  border: map-get($textInput , "borderWidth") solid $greyBlack;
  &::placeholder {
    color: $promptGrey;
  }
  &:focus{
    outline: none
  }
}