@import 'scss/variables';
@import 'scss/flex';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';

.imageInputContainer {
  border: 2px dashed #d0d0d0;
  border-radius: 10px;
  padding: 0.7rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  .header {
    .heading {
    }
  }

  .label {
    margin-left: auto;
    color: $pureWhite;
    background-color: $primary;
    border-color: $primary;
    // background-color: $primary;
    // padding: 0.375rem 0.75rem;
    // align-items: center;
    // display: flex;
    // justify-content: center;
    // border-radius: 5px;
  }
  .sync-btn {
    font-size: $textParagraphOne !important;
    svg {
      margin-right: 7px;
      text-decoration: underline;
    }
  }
  .text {
    font-size: 0.8rem;
    font-family: Avenir-Regular;
  }
  .preview {
    width: 90px;
    display: flex;
    align-items: center;
    font-size: 12px;

    .image {
      width: 100%;
    }
  }
  .uploading {
    width: 32px;
    height: 32px;
  }
  .input {
    width: 68%;
  }
}
.helpText {
  font-size: 0.78rem;
  margin-left: 0.3rem;
  color: #c5c5c5;
}
