@import 'scss/variables';
@import 'scss/flex';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';

.penny-check {
  border-radius: 8px 8px 0px 0px;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 3;
  height: 100%;
  width: 100%;

  .penny-check-layer {
    background: $gray-medium;
    border-radius: 8px 8px 0px 0px;
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 4;
    opacity: 0.95;
    height: 100%;
    width: 100%;
  }
  .penny-check-box {
    width: 452px;
    height: 402px;
    position: relative;
    z-index: 5;
    margin: 0px auto;
    margin-top: 34px;
    background: $pureWhite;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;

    .title {
      font-size: $headingSection;
      color: $greyBlack;
      font-family: Avenir-DemiBold;
      margin-bottom: 10px;
      text-align: center;
      padding-top: 20px;
    }
    .sub-title-light {
      font-size: $headingSectionThree;
      font-family: Avenir-Regular;
      color: $greyBlack;
      margin-bottom: 15px;
      padding: 10px;
      text-align: center;
    }
  }
  .loader {
    text-align: center;
    padding: 25px 0px 35px 0px;
    @include flexbox();
    @include justify-content(center);
  }
  .mobile-loader {
    display: none;
  }
  @include screen-below('lg') {
    .mobile-loader {
      @include flexbox();
      @include justify-content(center);
      padding-top: 40px;
      padding-bottom: 20px;
    }
    .loader {
      display: none;
    }
    .penny-check-layer {
      background-color: unset;
    }
    .penny-check-box {
      background-color: $savvyYellow;
      width: 100%;
      border-radius: 40px 40px 0px 0px;
      bottom: 0px;
      position: absolute;
      .title {
        color: $footerBlack;
        padding-top: 40px;
        margin-bottom: 0px;
        font-size: $headingMain;
      }
      .sub-title-light {
        color: $footerBlack;
        font-family: Avenir-Medium;
        letter-spacing: -1px;
        font-size: $textParagraphOne;
        width: 280px;
        margin: 0px auto;
      }
    }
  }
}
