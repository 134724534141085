@import 'scss/variables';
@import 'scss/flex';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';

.app-wizard {
  padding: 0 -1rem;

  .progress-wrapper {
    background-color: #e4e4e4;
    height: 3px;
    border-radius: 1.5px;
  }
  .progress-bar {
    background-color: $savvyYellow;
    height: 100%;
    width: 0%;
    transition: ease all 0.5s;
    border-radius: 1.5px;
    position: relative;

    .progress-circle {
      width: 8px;
      height: 8px;
      background-color: $savvyYellow;
      border-radius: 4px;
      position: absolute;
      right: 0;
      top: -2px;
    }
  }

  .section-container {
    display: flex;

    .section {
      display: flex;
      flex: 1;
      justify-content: center;
      padding: 1rem;
      text-align: center;
      text-decoration: none;

      @include screen-up('lg') {
        // font-size: $h4;
      }
    }
  }

  @include screen-below('lg') {
    .section-container {
      .section {
        font-size: $textParagraphThree;
      }
    }
  }
}
