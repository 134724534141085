@import 'scss/variables';
@import 'scss/flex';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';

.auto-pay {
  .title {
    color: $themeBlack;
    font-size: $headingMain;
    line-height: 1.5rem;
    font-family: Avenir-DemiBold;
    margin-top: 2rem;
    text-align: center;
  }
  .sub-title {
    color: $themeBlack;
    font-size: $headingSectionThree;
    font-family: Avenir-Regular;
    text-align: center;
    margin-bottom: 50px;
  }

  .offer-tab {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 2.15562px 2.15562px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    margin-bottom: 35px;
    overflow: hidden;
    .offerHeader {
      background-color: #f3f3f3;
      padding: 1rem 1.5rem;

      img {
        height: 50px;
        width: 50px;
      }

      span {
        font-family: Avenir-DemiBold;
        margin-left: 1rem;
        font-size: $headingSectionThree;
        color: $pureBlack;
      }
    }
  }

  @include screen-below('lg') {
  }
}
