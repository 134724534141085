@import 'scss/functions';
@import 'scss/variables';
@import 'scss/flex';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';

.buttonContainer {
  text-align: center;
  margin: toRem(15) 0;
  @include flexbox();
  @include justify-content(center);
  .offer-refund-btn {
    margin-right: 20px;
  }
}
