@import './functions';

// theme colors
$savvy-yellow: #eda12c;

$primary: $savvy-yellow;

.btn-primary {
  color: #ffffff !important;
}

/* Theme
==============================

/* Fonts */
$fontFamilySemiBold: Avenir-DemiBold;
$fontFamilyRegular: Avenir-Regular;

$avenirBold: Avenir-Bold-Original;
$avenirSemiBold: Avenir-DemiBold-Original;
$avenirRegular: Avenir-Regular-Original;
$avenirLight: Avenir-Light-Original;

/* Colors */
$pureBlack: #000000;
$pureWhite: #ffffff;
$savvyYellow: #ffd401;
$offBlack: #1a1a1a;
$blackTwo: #1c1c1c;
$greyBlack: #333333;
$greyBlackTwo: #8c8787;
$disabledYellow: #ffe02d8c;
$promptGrey: #c2c2c2;
$greyWhite: #f3f3f3;
$greyWhiteTwo: #f4f4f4;
$greyWhiteThree: #f5f5f5;
$greyWhiteFour: #a9a3a3;
$lightGrey: #e5e5e5;
$borderBlack: #0000004d;
$themeBlack: #000000b3;
$footerBlack: #1c1c1f;
$greenBright: #7ad606;
$redBright: #ff5152;
$skyblueBright: #36b9e2;
$successGreen: #7ad606;

//feedback colors
$actionGreen: #89caa7;
$errorRed: #ff5152;

/* Font size/property size */
$baseFontSize: 16; //all rems will be set relative to this px value
$largeHeading: 30px;
$headingMain: 1.75rem; //28px
$headingMainTwo: 1.625rem;
$headingSection: 1.375rem; //22px
$headingSectionTwo: 1.25rem;
$headingSectionThree: 1.125rem; //18px

$textSection: 1.375rem;
$textParagraphOne: 1rem;
$textParagraphTwo: 1rem;
$textParagraphThree: 0.87rem;
$textFinePrint: 0.75rem;

$textButtonOne: 22;
$textButtonCompact: 16;

$buttonOneRadius: 8;
$buttonOnePaddingHorizontal: 16;
$buttonOnePaddingVertical: 10;
$buttonOneMinWidth: 300;
$buttonOneMinHeight: 56;
$buttonOneStoke: 1.5;

$buttonCompactRadius: 8;
$buttonCompactPaddingHorizontal: 12;
$buttonCompactPaddingVertical: 8;
$buttonCompactMinWidth: 172;
$buttonCompactMinHeight: 42;

//text input
$textInput: (
  'height': toRem(48),
  'fontSize': toRem(16),
  'padding': 0 toRem(15),
  'borderRadius': toRem(8),
  'borderWidth': toRem(1),
);

$label: (
  'fontFamily': $fontFamilySemiBold,
  'fontSize': toRem(16),
);

$modal: (
  'height': toRem(184),
  'width': toRem(310),
  'borderRadius': toRem(16),
);

//heading/text
$headingColor: $greyBlack;
$textColor: $greyBlack;
$labelColor: $greyBlack;
