@import 'scss/variables';
@import 'scss/flex';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';
.profile {
  .input-box {
    margin-bottom: 10px;
  }
  .btn-group {
    @include flexbox();
    @include justify-content(center);
  }
  input {
    &::placeholder {
      color: $greyBlack !important;
      opacity: 0.3;
    }
  }
}
