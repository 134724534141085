@import 'scss/variables';

.container{
  .body{
    display: flex;
    flex-direction: column;
    height: 20rem;
    // max-width: none;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 2;
    .header{
      display: flex;
      align-items: center;
      flex-direction: column;
      .title{
        font-family: Avenir-DemiBold;
        font-size: 3rem;
      }
      .sub-title{
  
      }
    }
    .leading{
      display: flex;
      align-items: center;
      width: 60%;
      flex-direction: column;
      border-top: 1px solid $gray-light;
      margin-top: 2rem;
      padding-top: 1rem;
    }
  }
}