@import 'scss/variables';
@import 'scss/flex';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';

.save-btn {
  @include flexbox();
  @include justify-content(center);
  width: 100%;
}
.coming-soon {
  text-align: center;
  padding: 100px 0px;
}
