@import 'scss/variables';
@import 'scss/flex';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';

.payment-links {
  .heading {
    @include flexbox();
    @include align-items(center);
    @include justify-content(space-between);
    .dropdown {
      .dropdown-toggle-btn {
        background-color: white;
        margin-right: 12px;
      }
    }
    h2 {
      font-size: $h2;
      color: rgba(0, 0, 0, 0.7);
      font-family: Avenir-DemiBold;
      margin-bottom: 0px;
      padding: 20px !important;
    }
  }
  .title {
    background-color: white;
    font-family: Avenir-DemiBold;
    color: rgba(0, 0, 0, 0.7);
    a {
      color: rgba(0, 0, 0, 0.7);
    }
    font-size: $h2;
    padding: 30px 20px 10px 15px;
  }
  .detail {
    font-family: Avenir-Medium;
    padding: 10px 20px 10px 15px;
    font-size: $h4;
    p {
      margin-bottom: 10px;
    }
    @include flexbox();
    @include justify-content(space-between);
    .badge-style {
      font-family: Avenir-Medium;
      font-size: $h5;
      width: 110px;
      padding: 5px;
      @include border-radius(12px);
      @include inline-flex();
      @include justify-content(center);
      @include align-items(center);
    }
  }
  .expiry-btn {
    background-color: white;
    padding: 30px 20px 10px 15px;
    text-align: center;
    button {
      font-family: Avenir-DemiBold;
      color: rgba(0, 0, 0, 0.7) !important;
      font-size: $h2;
      padding-left: 50px;
      padding-right: 50px;
      @include border-radius(10px);
    }
  }
  .modal-btn {
    font-family: Avenir-DemiBold;
    color: rgba(0, 0, 0, 0.7) !important;
    font-size: $h2;
    padding-left: 50px;
    padding-right: 50px;
    @include border-radius(10px);
  }
}
