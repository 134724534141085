@import 'scss/media_queries';
@import 'scss/variables';
@import 'scss/_theme';
@import 'scss/_flex';

.account-details {
  .account-details-box {
    padding-bottom: 30px;
  }
  .invest-more {
    width: 100%;
  }

  .withdraw-savings {
    width: 100%;
  }

  .custom-btns {
    @include flexbox();
    @include justify-content(center);
    padding: 40px 0px;
    .custom-btn {
      font-size: $h2;
      background-color: $savvyYellow;
      font-family: Avenir-DemiBold;
      color: black !important;
      border: 1px solid $savvyYellow;
      padding: 5px 25px;
      min-width: 325px;
      &:nth-child(1) {
        margin-right: 20px;
      }
    }
  }

  @include screen-below('lg') {
    .invest-more {
      margin-bottom: 0.5rem;
    }

    .withdraw-savings {
      margin-bottom: 1rem;
    }
    .custom-btns {
      @include flexbox();
      @include justify-content(center);
      @include flex-direction(column);

      padding: 40px 0px;
      .custom-btn {
        font-size: $h2;
        background-color: $savvyYellow;
        font-family: Avenir-DemiBold;
        color: black !important;
        border: 1px solid $savvyYellow;
        padding: 5px 25px;
        max-width: 325px;
        &:nth-child(1) {
          margin: 0px auto;
          margin-bottom: 20px;
        }

        margin: 0px auto;
      }
    }
  }
}
