@import 'scss/variables';
@import 'scss/flex';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';

// modal
.folio-created-modal {
  background-color: rgba(255, 255, 255, 0.75);
  .modal-dialog {
    max-width: 820px;

    .modal-content {
      @include border-radius(16px);
      background-color: unset;
      border: unset;
    }
  }
}
