@import 'scss/variables';
@import 'scss/flex';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';

.container {
  display: flex;
  .leftContainer,
  .rightContainer {
    flex-basis: 50%;
    font-family: Avenir-Regular !important;
  }
}
