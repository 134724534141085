@import 'scss/media_queries';
@import 'scss/variables';
@import 'scss/theme';

.wrapper {
  // background-color: $gray-medium;
}

.admin-layout {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.layout-container {
  display: flex;

  .aside {
    position: fixed;
    left: -20%;
    width: 20%;
    height: 100%;
    background-color: $blackTwo;
    transition: all 0.25s ease-in;
    border-right: 1px solid $borderBlack;
    display: flex;
    flex-direction: column;

    &.toggle {
      left: 0;
    }
  }
  .main {
    width: 80%;
    margin-left: 20%;
    transition: all 0.25s ease-in;
    background-color: $gray-medium;

    &.toggle {
      width: 100%;
      margin-left: 0;
    }
  }
}

@include screen-below('lg') {
  .layout-container {
    .aside {
      box-shadow: 0 0 10px rgb(167, 167, 167);
      width: 17rem;
      left: 0;
      z-index: 3;
      &.toggle {
        left: -17rem;
      }
    }
    .main {
      width: 100%;
      margin-left: 0;
      transition: all 0.25s ease-in;
    }
  }
}
