@import 'scss/variables';
@import 'scss/flex';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';

.custom-modal {
  background-color: rgba(255, 255, 255, 0.75);
  .modal-dialog {
    max-width: 928px;
    .modal-content {
      @include border-radius(24px);
      background-color: unset;
      border: unset;
      .penny-check-box {
        @include border-radius(24px);
        background-color: white;
        @include box-shadow(0px 3px 5px rgba(0, 0, 0, 0.25));
        margin-top: 50px;
        text-align: center;
        min-height: 252px;
        .title {
          font-family: Avenir-DemiBold;
          color: black;
          font-size: 32px;
          padding-top: 50px;
          padding-bottom: 10px;
        }
        .description {
          font-size: 22px;
          line-height: 30px;
          padding-bottom: 30px;
        }
        .timer {
          padding-top: 30px;
          padding-bottom: 40px;
          font-size: 22px;
        }
        .sand-clock {
          height: 200px;
          width: 200px;
        }
      }
    }
  }
}
