.chartWrapperOne {
  background-color: #fff;
  .contentContainer {
    display: flex;
    padding: 1rem;
    .right {
      margin-left: auto;
    }
  }
  .chartContainer {
    height: 15rem;
  }
}
.multiChartWrapper {
  display: flex;
  justify-content: space-evenly;
  padding: 0 0 2.8rem;
  background-color: #fff;

  .chartWrapperTwo {
    width: 40%;
    background-color: #f3f3f3;
    padding: 2rem 0;
    border-radius: 10px;
    .contentContainer {
      text-align: center;
    }

    .chartContainer {
      height: 15rem;
    }
  }
}
