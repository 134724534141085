@import 'scss/media_queries';
@import 'scss/variables';
@import 'scss/_theme';
@import 'scss/_flex';

.modal-content {
  min-height: 554px !important;
}

.modal-lg {
  max-width: 894px !important;
}

.redeem-modal {
  .cross-icon {
    @include flexbox();
    @include justify-content(flex-end);
    padding: 20px 20px 0px 0px;
    span {
      cursor: pointer;
    }
    img {
      opacity: 0.8;
      width: 18px;
    }
    margin-bottom: 15px;
  }
  .title {
    font-size: $headingMain;
    font-family: Avenir-DemiBold;
    text-align: center;
    color: $greyBlack;
  }
  .sub-title {
    font-size: $headingSection;
    color: $greyBlack;
    font-family: Avenir-DemiBold;
  }
  .heading {
    font-size: $headingSection;
    color: $greyBlack;
    font-family: Avenir-Regular;
  }
  .sub-heading {
    font-size: $textParagraphOne;
    color: $greyBlack;
    font-family: Avenir-Regular;
  }
  .custom-btn {
    font-size: $headingSection;
    background-color: $savvyYellow;
    font-family: Avenir-DemiBold;
    color: $greyBlack !important;
    border: 1px solid $savvyYellow;
    padding: 18px 25px;
    border-radius: 8px;
    min-width: 325px;
  }
  .common-btn {
    width: 100%;
    text-align: center;
  }
  .text-input {
    max-width: 420px;
    text-align: center;
    width: 100%;
    height: 64px;
    background-color: $greyWhite;
    outline: unset;
    border: unset;
    font-size: $headingSection;
    padding: 0px 20px;
    border-radius: 4px;
  }

  .input-box {
    @include flexbox();
    @include flex-direction(column);
    @include justify-content(center);
    @include align-items(center);
    .error {
      padding-top: 10px;
      color: $errorRed;
    }
  }
  .urlContainer {
    text-align: center;
    font-size: 0.8rem;
    padding-top: 150px;
    padding-bottom: 50px;
    .clipboard {
      background-color: transparent;
      border: none;
      padding-left: 5px;
    }
    .urlInput {
      width: 100%;
      padding-top: 300px;
      padding: 0.25rem;
      border: none;
      background-color: transparent;
      outline: none !important;
      font-size: $headingSection;
      color: $greyBlack;
      text-align: center;
      text-decoration: underline;
      font-family: Avenir-Regular;
    }
  }
  .redeem-screen {
    padding: 0px 40px 40px 40px;
    .upper-section {
      padding: 50px 0px 30px 0px;
      @include flexbox();
      @include justify-content(space-around);
      text-align: center;
    }

    .note {
      margin-top: 20px;
      font-size: $textParagraphOne;
      font-style: italic;
      margin-bottom: 90px;
    }
  }

  .redeem-success-screen {
    padding: 0px 40px 40px 40px;
    .upper-section {
      padding: 0px 0px 10px 0px;
      .okay-icon {
        padding-bottom: 30px;
      }

      text-align: center;
    }
    .money-transferred {
      background-color: $greyWhiteTwo;
      border-radius: 8px;
      padding: 20px;
      margin-bottom: 25px;
      .heading {
        text-align: center;
        margin-bottom: 9px;
      }
      .line {
        width: 30%;
        height: 1px;
        background-color: $promptGrey;
        margin: 0px auto;
      }
      .transfer {
        @include justify-content(space-between);
        @include flexbox();
        padding-top: 10px;
      }
      .icon {
        padding: 0px 30px 0px 50px;
        @include justify-content(center);
        @include align-items(flex-end);
        @include flexbox();
        img {
          width: 35px;
        }
      }
      .content-box {
        width: 50%;
        text-align: center;
        .number {
          width: 33px;
          height: 33px;
          border-radius: 50%;
          margin: 0px auto;
          background-color: $savvyYellow;
          font-size: $headingSection;
          color: $pureWhite;
          @include justify-content(center);
          @include align-items(center);
          @include flexbox();
          font-family: Avenir-DemiBold;
          margin-bottom: 10px;
        }
      }
    }
  }
}

@include screen-below('lg') {
  .modal-content {
    min-width: unset;
  }
}
