@import 'scss/theme';
@import 'scss/functions';

.container{
  display: inline-block;
}

@mixin buttonStyles($fontSize, $color, $borderRadius, $padding, $backgroundColor, $border, $minWidth, $minHeight){
  font-family: $fontFamilySemiBold;
  font-size: $fontSize;
  text-transform: uppercase;
  color: $color;
  border-radius: $borderRadius;
  padding: $padding;
  background-color: $backgroundColor;
  border:$border;
  min-width: $minWidth;
  min-height: $minHeight;
  display: flex;
  align-items: center;
  justify-content: center;
}

.primary{
  // font-family: $fontFamilySemiBold;
  // font-size: toRem($textButtonOne);
  // text-transform: uppercase;
  // color: $greyBlack;
  // border-radius: toRem($buttonOneRadius);
  // padding: toRem($buttonOnePaddingVertical) toRem($buttonOnePaddingHorizontal);
  // background-color: $savvyYellow;
  // border:none;
  // min-width: toRem($buttonOneMinWidth);
  // min-height: toRem($buttonOneMinHeight);
  @include buttonStyles(
    toRem($textButtonOne), 
    $greyBlack, 
    toRem($buttonOneRadius), 
    toRem($buttonOnePaddingVertical) toRem($buttonOnePaddingHorizontal), 
    $savvyYellow, 
    none,
    toRem($buttonOneMinWidth),
    toRem($buttonOneMinHeight)
  )
}
.secondary{
  // font-family: $fontFamilySemiBold;
  // font-size: toRem($textButtonOne);
  // text-transform: uppercase;
  // color: $savvyYellow;
  // border-radius: toRem($buttonOneRadius);
  // padding: toRem($buttonOnePaddingVertical) toRem($buttonOnePaddingHorizontal);
  // background-color: transparent;
  // border: toRem($buttonOneStoke) solid $savvyYellow;
  // min-width: toRem($buttonOneMinWidth);
  // min-height: toRem($buttonOneMinHeight);
  @include buttonStyles(
    toRem($textButtonOne), 
    $savvyYellow, 
    toRem($buttonOneRadius), 
    toRem($buttonOnePaddingVertical) toRem($buttonOnePaddingHorizontal), 
    transparent, 
    toRem($buttonOneStoke) solid $savvyYellow,
    toRem($buttonOneMinWidth),
    toRem($buttonOneMinHeight)
  )
}
.compact{
  // font-family: $fontFamilySemiBold;
  // font-size: toRem($textButtonCompact);
  // text-transform: uppercase;
  // color: $greyBlack;
  // border-radius: toRem($buttonCompactRadius);
  // padding: toRem($buttonCompactPaddingVertical) toRem($buttonCompactPaddingHorizontal);
  // background-color: $savvyYellow;
  // border:none;
  // min-width: toRem($buttonCompactMinWidth);
  // min-height: toRem($buttonCompactMinHeight);
  @include buttonStyles(
    toRem($textButtonCompact), 
    $greyBlack, 
    toRem($buttonCompactRadius), 
    toRem($buttonCompactPaddingVertical) toRem($buttonCompactPaddingHorizontal), 
    $savvyYellow, 
    none,
    toRem($buttonCompactMinWidth),
    toRem($buttonCompactMinHeight)
  )
}