@import 'scss/variables';

.home-page-container {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;

  .description {
    text-align: center;
    position: relative;
    top: -100px;
    line-height: normal;

    h1 {
      color: #ff4f4e;
      margin-bottom: 0.5rem;
    }

    p {
      color: $dark;
      font-size: 0.9rem;
    }
  }
}
