@import 'scss/variables';
@import 'scss/flex';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';

.card {
  border-radius: 12px !important;

  margin-bottom: 2rem;
  box-shadow: 0 3px 5px darken($gray-medium, 10%);
  .cardHeader {
    border-radius: 12px 12px 0px 0px !important;
    background-color: $savvyYellow;
    border-bottom: unset !important;
    display: flex;
    .cardHeading {
      font-size: $headingSection;
      font-weight: 600;
      color: $themeBlack;
    }

    .toggleButton {
      margin-left: auto;
    }
  }
  .cardBody {
    padding: 1rem 0;
  }
  .cardRow,
  .field {
    margin-bottom: 1rem;
  }
  .cardSection {
    padding: 0 1rem;
    margin-bottom: 1rem;
  }
}
.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-btn-group {
  @include flexbox();
  @include justify-content(center);
  padding: 30px 20px;
  width: 100%;
  .custom-btn {
    font-size: $headingSection;
    background-color: $savvyYellow;
    font-family: Avenir-DemiBold;
    color: $offBlack !important;
    border: 1px solid $savvyYellow;
    padding: 8px 65px;
    border-radius: 8px;
    min-width: 250px;
  }
}

.list {
  @include flexbox();
  @include align-items(center);
  font-size: $h4;
  margin-bottom: 10px;

  &.description {
    margin-bottom: 0;
  }
  .inputLabel {
    font-weight: 600;
    width: 180px;
    min-width: 180px;
    margin-right: 10px;
  }
  .editor {
    width: 100%;
  }
}

.mainImage {
  text-align: center;
  img {
    width: 100%;
    max-height: 180px;
    height: 100%;
    object-fit: contain;
    @include border-radius(4px);
  }
}
.innerImageBox {
  width: 100%;
  min-height: 180px;
  background: rgba(196, 196, 196, 0.2);
  @include flexbox();
  @include justify-content(center);
  @include align-items(center);
  @include border-radius(4px);
}

.description {
  @include align-items(flex-start);
}

.anchorHand {
  cursor: pointer;
}

.solidHeadingStyles {
  font-family: 'Avenir-DemiBold';
}

.helpText {
  font-size: 0.78rem;
  margin-left: 0.3rem;
  color: #9b9b9b;
}

@include screen-below('lg') {
  .cardCol {
    margin-bottom: 1rem;
  }
}

@include screen-below('sm') {
  .list {
    align-items: flex-start;
    flex-direction: column;
  }
}
