@import 'scss/variables';

.container {
  display: flex;
  align-items: center;
  .inputLabel {
    font-family: Avenir-DemiBold;
  }
  .input {
    margin-left: auto;
  }
}
