@import 'scss/media_queries';
@import 'scss/variables';
@import 'scss/_theme';
@import 'scss/_flex';

.modal-content {
  min-height: unset !important;
  min-width: 894px;
}

.pan-message-modal {
  .cross-icon {
    @include flexbox();
    @include justify-content(flex-end);
    padding: 20px 20px 0px 0px;
    span {
      cursor: pointer;
    }
    img {
      opacity: 0.7;
      width: 20px;
    }
    margin-bottom: 15px;
  }

  .title {
    font-size: $headingMain;
    color: $greyBlack;
    line-height: 38px;
    font-family: Avenir-Regular;
    text-align: center;
    margin-bottom: 5px;
  }

  .amount {
    font-size: $headingMain;
    color: $greyBlack;
    line-height: 38px;
    font-family: Avenir-DemiBold;
    text-align: center;
  }

  .text-input {
    max-width: 400px;
    text-align: center;
    width: 100%;
    height: 64px;
    background-color: $greyWhite;
    outline: unset;
    border: unset;
    font-size: $textParagraphOne;
    padding: 0px 20px;
    border-radius: 4px;
  }

  .input-box {
    @include flexbox();
    @include flex-direction(column);
    @include justify-content(center);
    @include align-items(center);
    .error {
      padding-top: 10px;
      color: $errorRed;
    }
  }

  .custom-btn {
    font-size: $headingSection;
    background-color: $savvyYellow;
    font-family: Avenir-DemiBold;
    color: black !important;
    border: 1px solid $savvyYellow;
    padding: 5px 25px;
    border-radius: 8px;
    min-width: 325px;
    margin: 5px;
  }

  .cancel-btn {
    font-size: $headingSection;
    background-color: $gray-button;
    font-family: Avenir-DemiBold;    
    border: none;
    padding: 5px 25px;
    border-radius: 8px;
    min-width: 325px;
    margin: 5px;
  }

  .modal-lg {
    max-width: 894px !important;
  }

  .withdraw-saving {
    .amount {
      padding-bottom: 35px !important;
    }

    .horizontal-line-box {
      padding: 35px 50px;
      .horizontal-line {
        height: 1px;
        width: 100%;
        background-color: #e2e2e2;
      }
    }
    .transfer {
      @include flexbox();
      @include justify-content(space-between);
      @include align-items(center);
      padding: 35px 60px 30px 60px;
      .transfer-box {
        background-color: $greyWhite;
        border-radius: 4px;
        text-align: center;
        padding: 15px 30px;
        min-width: 280px;
        .label {
          font-family: Avenir-Regular;
          font-size: $textParagraphTwo;
        }
        .text {
          font-family: Avenir-Medium;
          font-size: $textParagraphTwo;
        }
        .text-hdfc {
          text-decoration: underline;
        }
      }
    }
    .withdraw-btn {
      @include flexbox();
      @include justify-content(center);
      padding: 30px 0px 30px 0px;
    }
  }

  .confirm-withdraw {
    .amount {
      padding-bottom: 100px !important;
    }
    .input-box {
      input {
        text-align: center;
      }
    }
    .resend-otp {
      color: $greyBlack;
      text-decoration: underline;
      font-size: $textParagraphOne;
      margin-top: 6px;
      margin-bottom: 100px;
      cursor: pointer;
      background: transparent;
      border: 0;
    }
    .confirm-btn {
      @include flexbox();
      @include justify-content(center);
      padding: 30px 0px 30px 0px;
      .custom-btn {
        margin-right: 0px;
      }
    }
  }

  .message-successfull {
    // .tick {
    //   text-align: center;
    //   padding-bottom: 22px;
    // }
    // .title {
    //   padding-bottom: 80px;
    // }
    min-height: 400px !important;
    padding-bottom: 45px;
    @include flexbox();
    @include align-items(center);
    @include flex-direction(column);
    @include justify-content(center);
    .done-btn {
      @include flexbox();
      @include justify-content(center);
      padding: 30px 0px 30px 0px;
      .custom-btn {
        margin-right: 0px;
      }
    }
    .description {
      color: $greyBlack;
      font-family: Avenir-Regular;
      font-size: $headingSection;
      text-align: center;
    }
  }
}

.disabled-btn {
  opacity: 0.5;
  cursor: not-allowed;
}

@include screen-below('lg') {
  .modal-content {
    min-width: unset;
  }
}
