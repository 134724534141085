@import 'scss/variables';
@import 'scss/flex';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';

.add-edit-product {
  position: relative;
}
.image-note {
  color: $footerBlack;
  font-size: $textParagraphThree;
  margin-top: -5px;
  margin-bottom: 9px;
}
.error {
  font-size: $textParagraphThree;
  color: $errorRed;
  margin-top: 0.1rem;
}

.content-error {
  margin-top: -15px;
}

.editorClassName {
  border: 1px solid #8080802b !important;
  border-radius: 0px 0px 8px 8px;
  min-height: 200px;
  padding: 0px 10px;
  margin-bottom: 20px;
}
.save-btn {
  @include flexbox();
  @include justify-content(center);
  width: 100%;
}
.titleContainer {
  display: flex;
  flex: 1;
  margin-bottom: 10px;
  .titleInput {
    flex: 1;
  }
  .titleAction {
    justify-content: center;
    align-items: center;
    display: flex;
    padding-top: 12px;
    .delete {
      color: $red-dark;
      border: transparent;
      background-color: transparent;
      padding: 0 1rem;
      position: relative;
      top: 4px;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.label {
  font-family: Avenir-Regular;
  font-size: $textParagraphThree;
}

.addMoreContainer {
  text-align: center;

  .addMore {
    color: $footerBlack;
    border: transparent;
    background-color: transparent;
    padding: 0 1rem;
    position: relative;
    top: 4px;
    text-decoration: underline;
    cursor: pointer;
    font-family: Avenir-Regular;
    font-size: $textParagraphThree;
  }
}
