@import 'scss/variables';
@import 'scss/flex';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';

.drawer-modal {
  background-color: rgba(255, 255, 255, 0.75);
  opacity: 0;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: ease-in-out 0.5s opacity;

  .drawer-modal-wrapper {
    text-align: center;
    position: fixed;
    bottom: -70%;
    left: 0;
    background-color: $savvy-yellow;
    @include border-radius(32px 32px 0px 0px);
    color: white;
    width: 100%;
    transition: ease-in-out 0.4s bottom;
    padding: 1rem;
  }

  &.open {
    opacity: 1;

    .drawer-modal-wrapper {
      bottom: 0%;
    }
  }
}
