@import 'scss/variables';

.app-button {
    background-color: $primary;
    border: none;
    color: #FFFFFF;
    font-family: Avenir-Medium;
    font-size: 1rem;
    padding: 0.75rem 2rem;
    // width: 100%;
    border-radius: 0.25rem;
    transition: ease 0.4s background-color;
    cursor: pointer;    
    position: relative;    

    &.outline {
        border: 1px solid $primary;
        color: $primary;
        background-color: transparent;

        &:hover {
            background-color: $dark-secondary;
        }
    
        &:disabled {
            background-color: #bebebe;
            cursor: auto;
        }
    }

    &.rounded {
        border-radius: 25px!important;
    }

    &:hover {
        background-color: $primary-dark;
    }

    &:disabled {
        background-color: #999999;
        cursor: auto;
        opacity: 0.7;
    }
}