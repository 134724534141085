@import 'scss/variables';
@import 'scss/flex';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';

.container {
  display: flex;
  flex-direction: column;
  background-color: $pureWhite;
  background-color: #ffffff;
  // border-radius: 0.5rem;
  // box-shadow: 0px 3px 5px #ccc;
  border-bottom: 1px solid $greyWhite;
  padding-bottom: 1rem;

  .header {
    display: flex;
    flex: 1;
    padding: 0.75rem 1rem 0.5rem 1rem;    

    .left {
      flex: 1;
      display: flex;
      align-items: center;
      font-family: Avenir-DemiBold;
      font-size: 18px;
    }
    .right {
      flex: 1;
      display: flex;
      justify-content: end;

      .button {
        background-color: $savvyYellow;
        border: none;
        padding: 0.5rem;
        border-radius: 0.5rem;        
        font-size: 14px;
        color: $blackTwo;

        span {
          margin-left: 5px;
        }
      }
    }
  }

  .body {
    padding: 0 1rem 0.5rem 1rem; 
  }
}