@import 'scss/variables';
@import 'scss/flex';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';

.auto-pay {
  .title {
    color: $themeBlack;
    font-size: $headingMain;
    font-family: Avenir-DemiBold;
    margin-top: 2rem;
    text-align: center;
    margin-bottom: 2rem;
  }

  .offer-tab {
    box-shadow: 0px 3px 5px #ccc;
    border: 1px solid #eee;
    border-radius: 10px;
    margin-bottom: 35px;

    .offerHeader {
      background-color: #f3f3f3;
      padding: 1rem 1.5rem;

      img {
        height: 50px;
        width: 50px;
      }

      span {
        font-family: Avenir-DemiBold;
        margin-left: 1rem;
      }
    }
  }

  @include screen-below('lg') {
  }
}
