@import 'scss/media_queries';

.container {
  display: flex;
  position: static;
  margin-top: -3.5rem;
  transition: all 0.25s ease-in;
  &.toggle {
    margin-top: 0;
  }
  .searchbar {
    width: 100%;
    border: 0;
    height: 3.5rem;
    font-size: 2rem;
    padding: 0.8rem;
    color: #afafaf;
    outline: none;
  }
}

@include screen-below('lg') {
  .container {
    .searchbar {
      font-size: 1rem;
    }
  }
}
