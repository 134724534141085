@import 'scss/variables';
@import 'scss/flex';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';

.product-create-modal {
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: rgba(243, 243, 243, 0.9);
  height: 100%;
  width: 100%;
  z-index: 100;
  margin-left: 20%;
  @include flexbox();
  @include justify-content(center);
  @include align-items(center);
  padding-right: 20%;
  @include screen-below('lg') {
    padding-right: 0%;
  }
}
.code-copied {
  margin-bottom: 10px;
}
.copy-icon {
  cursor: pointer;
}
.close-btn {
  @include flexbox();
  @include justify-content(flex-end);
  img {
    opacity: 0.7;
    width: 20px;
    cursor: pointer;
  }
}

.link {
  @include flexbox();
  @include align-items(center);
  @include justify-content(center);
  padding-bottom: 50px;
  padding-left: 5%;
  padding-right: 5%;
}

.product-box {
  max-width: 688px;
  width: 100%;
  background-color: $pureWhite;
  border-radius: 20px;
  padding: 20px;
  // margin-top: -10%;
  text-align: center;
}

.product-img {
  margin-top: 0px;
}
.title {
  color: $footerBlack;
  font-size: $headingSectionThree;
  font-family: Avenir-DemiBold;
  margin-top: 20px;
  margin-bottom: 40px;
}

.confirmation {
  color: $footerBlack;
  font-size: $headingSectionThree;
  font-family: Avenir-Regular;
  margin-left: 10px;
  border: none;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
  outline: unset;
  text-decoration: underline;
  width: -webkit-fill-available;
}
.html {
  margin-top: 20px;
  max-height: 300px;
  overflow-y: auto;
}

.custom-btns-group {
  @include flexbox();
  @include justify-content(center);
  padding-bottom: 15px;
  .custom-btns-first {
    margin-right: 5%;
  }
}

@include screen-below('lg') {
  .product-create-modal {
    margin-left: 0%;
  }
}
