@import 'scss/variables';
@import 'scss/flex';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';

.folio-created-box {
  @include border-radius(16px);
  background-color: white;
  margin-top: 50px;
  padding-bottom: 50px;
  text-align: center;
  .folio-created-ok-icon {
    width: 150px;
    transform: scale(1.5);
  }
  .folio-created-thank-you {
    font-size: 28px;
    line-height: 38px;
    color: $gray-text;
    margin-bottom: 50px;
  }
  .folio-created-start-saving {
    font-size: 28px;
    line-height: 38px;
    font-family: Avenir-DemiBold;
    color: rgba(0, 0, 0, 0.7);
    margin-bottom: 50px;
  }
  .folio-created-btn {
    min-width: 300px;
    height: 56px;
    @include border-radius(7px);
    background-color: $savvy-yellow;
    outline: unset;
    border: unset;
    font-size: 16px;
    font-family: Avenir-DemiBold;
    color: white;
    margin-bottom: 60px;
  }
}

@include screen-below('lg') {
  .folio-created-box {
    @include border-radius(16px);
    background-color: white;
    margin-top: 50px;
    text-align: center;
    .folio-created-ok-icon {
      margin-top: -25px;
      margin-bottom: 50px;
    }
    .folio-created-thank-you {
      font-size: 16px;
      line-height: 21px;
      color: $gray-text;
      margin-bottom: 30px;
    }
    .folio-created-start-saving {
      font-size: 16px;
      line-height: 21px;
      font-family: Avenir-DemiBold;
      color: rgba(0, 0, 0, 0.7);
      margin-bottom: 40px;
    }
    .folio-created-btn {
      min-width: 273px;
      height: 45px;
      @include border-radius(7px);
      background-color: $savvy-yellow;
      outline: unset;
      border: unset;
      font-size: 18px;
      font-family: Avenir-DemiBold;
      color: white;
      margin-bottom: 45px;
    }
  }
}