@import 'scss/variables';
@import 'scss/flex';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  .button {
    min-width: 15rem;
    font-family: Avenir-DemiBold;
    color: $greyBlack;
    background-color: $savvyYellow;
  }
}
