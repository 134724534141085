@import 'scss/variables';
@import 'scss/flex';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';

.payment-links {
  .custom-btn-group {
    @include flexbox();
    @include justify-content(center);
    padding: 0px 20px;
    background-color: $pureWhite;
    padding: 30px 0px;
    .custom-btn {
      font-size: $headingSection;
      background-color: $savvyYellow;
      font-family: Avenir-DemiBold;
      color: $offBlack !important;
      border: 1px solid $savvyYellow;
      padding: 10px 25px;
      border-radius: 8px;
      min-width: 250px;
      margin-right: 80px;
    }
  }

  .heading {
    @include flexbox();
    @include align-items(center);
    @include justify-content(space-between);
    .dropdown {
      .dropdown-toggle-btn {
        background-color: $pureWhite;
        margin-right: 12px;
      }
    }
    h2 {
      font-size: $h2;
      color: $greyBlack;
      font-family: Avenir-DemiBold;
      margin-bottom: 0px;
      padding: 20px !important;
    }
  }
  .title {
    background-color: $pureWhite;
    font-family: Avenir-DemiBold;
    color: $greyBlack;
    a {
      color: $offBlack;
    }
    font-size: $h2;
    padding: 30px 20px 10px 15px;
  }
  .detail {
    font-family: Avenir-Medium;
    padding: 10px 20px 10px 15px;
    font-size: $h4;

    p {
      font-family: Avenir-Regular;
      color: $offBlack;
      margin-bottom: 10px;
    }
    .booking {
      color: $offBlack;
      font-family: Avenir-DemiBold;
    }
    @include flexbox();
    @include justify-content(space-between);
    .badge-style {
      font-family: Avenir-Medium;
      font-size: $h5;
      width: 110px;
      padding: 5px;
      @include border-radius(12px);
      @include inline-flex();
      @include justify-content(center);
      @include align-items(center);
    }
  }
  .expiry-btn {
    background-color: $pureWhite;
    padding: 30px 20px 10px 15px;
    text-align: center;
    button {
      font-family: Avenir-DemiBold;
      color: $offBlack !important;
      font-size: $h2;
      padding-left: 50px;
      padding-right: 50px;
      @include border-radius(10px);
    }
  }
  .modal-btn {
    font-family: Avenir-DemiBold;
    color: $offBlack !important;
    font-size: $h2;
    padding-left: 50px;
    padding-right: 50px;
    @include border-radius(10px);
  }
}
