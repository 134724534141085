@import 'scss/variables';

.inputLabelContainer {
  margin-bottom: 0.2rem;
  .inputLabel {
    font-family: Avenir-DemiBold;
    font-size: $inputLabel;
  }
}
.addonText {
  font-family: Avenir-DemiBold;
}
