@import 'scss/variables';
@import 'scss/flex';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';

.detail-modal {
  .detail-header {
    color: $dark;
  }
  .close-btn {
    img {
      width: 20px;
      opacity: 0.8;
    }
  }
  .modal-footer {
    @include justify-content(center);
    button {
      width: 150px;
    }
  }
}

.plane-contact-us {
  @include flexbox();
  color: $themeBlack;
  font-size: $headingSectionThree;
  padding-bottom: 40px;
  .plane-contact-us-text {
    text-decoration: underline;
    padding-left: 10px;
    cursor: pointer;
  }
}

.offer-detail-item {
  padding-top: 30px;
  .offer-container {
    .left-box {
      padding-left: 20px;
    }
    .offer-title {
      // font-size: $h2;
      color: $dark;
      font-family: Avenir-DemiBold;
      margin-bottom: 3px;
    }
    .offer-description {
      font-size: $h4;
      color: rgba(0, 0, 0, 0.7);
      font-family: Avenir-Medium;
      max-width: 450px;
    }
    .offer-view-more {
      text-decoration: underline;
      a {
        color: rgba(0, 0, 0, 0.7);
      }
      cursor: pointer;
      margin-bottom: 40px;
    }
  }
  @include screen-below('lg') {
    .offer-container {
      .left-box {
        padding-left: 0px;
      }
      .offer-title {
        font-size: $h3;
        color: $dark;
        font-family: Avenir-DemiBold;
        margin-bottom: 3px;
      }
      .offer-description {
        font-size: $h5;
        color: rgba(0, 0, 0, 0.7);
        font-family: Avenir-Medium;
        max-width: 450px;
      }
      .offer-view-more {
        font-family: Avenir-Regular;
        font-size: $h5;
        a {
          color: rgba(0, 0, 0, 0.7);
        }
        margin-bottom: 40px;
      }
    }
  }
}
