@import 'scss/variables';
@import 'scss/flex';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';

.container {
  .previewContainer {
    margin-top: 1rem;
    img {
      max-width: 20rem;
    }
  }
}
.imageInputContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .label {
    width: 100%;
    background-color: $savvyYellow;
    padding: 0.375rem 0.75rem;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 8px;
    color: $pureWhite;
    font-family: Avenir-Medium;
    text-transform: capitalize;
  }

  .input {
    width: 68%;
  }
}
.helpText {
  font-size: 0.78rem;
  margin-left: 0.3rem;
  color: #c5c5c5;
}
