@import 'scss/media_queries';
@import 'scss/variables';

.product-image {
  height: 100%;
  width: 100%;
  padding: 5px 5px 0px 0;
  justify-content: center;
  align-items: center;
  display: flex;
  
  .product-image-button {
    width: 100%;
    height: 100%;
    border: none;    
    background-color: transparent;

    .product-image-content {
      height: 100%;
      width: 100%;
      background-size: cover;
      background-position: center;      
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;            
    }
  }

  .loader-image {
    height: 22px;
    width: 22px;
    opacity: 0.7;
  }  
}

.product-banner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;  

  .product-image-center {
    width: 100%;
    height: 100%;

    .product-image-content {
      border-radius: 20px;         
    }
  }

  .product-image-left {
    width: 60%;
    height: 100%;
    
    .product-image-content {
      border-radius: 20px 0 0 20px;
      display: flex;     
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      background-color: #EEE;   
    }
  }
  .product-image-right {
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .product-image-content {
      border-radius: 0 20px 20px 0;
      display: flex;     
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      background-color: #EEE; 
    }

    .product-image-row {
      flex: 1;
      display: flex;

      .product-image-wrapper {
        flex: 1;
        display: flex;

        .product-image {
          padding: 5px 5px 0px 0px;
        }
      } 

      &:first-child {
        .product-image-content {
          border-radius: 20px 0 0 0;
        }
      }
      &:last-child {
        .product-image-content {
          border-radius: 0 0 0 20px;
        }
      }
    }
    :first-child {  
      .product-image-wrapper {    
        &:last-child {
          .product-image-content {
            border-radius: 0 20px 0 0;
          }
        }
      }     
    }
    :last-child {
      .product-image-wrapper {
        &:last-child {
          .product-image-content {
            border-radius: 0 0 20px 0;
          }
        }
      }      
    }  

    &.fifth {
      .product-image-row {
        :first-child {
          border-radius: 0px;          
        }
      }
    }
  }

  @include screen-below('lg') {
    height: 250px;
    margin: 0 -15px;

    .product-image {         
      .product-image-button {
        .product-image-content {
          border-radius: 0px!important;
        }
      }            
    } 
  }
}

.slideshow-modal {
  max-width: 80%!important;
  padding: 5px;
  background: #FFF;
  border-radius: 10px;
  position: relative;

  .close-button {
    width: 28px;
    height: 28px;
    background: #FFF;
    border: none;
    position: absolute;
    top: -15px;
    right: -15px;
    z-index: 2;
    border-radius: 14px;

    .close-button-icon {
      color: $primary;
      width: 28px;
      height: 28px;
    } 
  }

  .slide-image {
    border-radius: 10px;
    max-height: calc(90vh);
  }
}

@include screen-below('lg') {
  .slideshow-modal {
    max-width: 100%!important;
  }
}