@import 'scss/variables';
@import 'scss/flex';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  border-radius: 15px;

  .catalog-image {
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .partner-title {
    color: #ffffff;
    font-family: Avenir-Bold;
    font-size: 2.5rem;
    text-shadow: 0px 0px 4px #333;
    margin-top: -3rem;
  }
  .partner-sub-title {
    color: #ffffff;
    font-size: $h4;

    text-shadow: 0px 0px 3px #333;
  }
  .loader-image {
    width: 32px;
    height: 32px;
    opacity: 0.4;
  }

  @include screen-below('lg') {
    height: 150px;
    .catalog-image {
      height: 100%;
      width: 100%;
      background-size: cover;
      background-position: center;
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .partner-title {
      color: #ffffff;
      font-family: Avenir-Bold;
      font-size: 16px;
      text-shadow: 0px 0px 4px #333;
      margin-top: -3rem;
    }
    .partner-sub-title {
      text-align: center;
      color: #ffffff;
      font-size: 7px;
      text-shadow: 0px 0px 3px #333;
    }
    .loader-image {
      width: 32px;
      height: 32px;
      opacity: 0.4;
    }
  }
}
