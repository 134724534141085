@import 'scss/variables';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';
@import 'scss/flex';

.contact-us-works-modal {
  .modal-dialog {
    max-width: 1170px;
    .modal-content {
      min-width: unset;
      width: 100%;
      border-radius: 12px;
      padding: 40px;
      .close-btn {
        position: absolute;
        right: 25px;
        top: 15px;
        span {
          cursor: pointer;
          img {
            width: 15px;
            opacity: 0.9;
          }
        }
      }
    }
  }
  .title {
    color: $dark;
    font-family: Avenir-DemiBold;
    text-align: center;
    font-size: $headingMainTwo;
  }
  .text-content {
    label {
      color: $dark;
      font-family: Avenir-DemiBold;
      text-align: center;
      font-size: $headingMainTwo;
    }
    textarea {
      border: 2px solid #f3f3f3;
      border-radius: 4px;
      font-size: $headingMainTwo;
    }
  }

  .custom-btn-group {
    @include flexbox();
    @include justify-content(center);
    background-color: $pureWhite;
    padding: 30px 0px 0px 0px;
    .custom-btn {
      font-size: $headingSection;
      background-color: $savvyYellow;
      font-family: Avenir-DemiBold;
      color: $offBlack !important;
      border: 1px solid $savvyYellow;
      padding: 10px 25px;
      border-radius: 8px;
      min-width: 250px;
    }
  }
  .text-label {
    color: $dark;
    font-family: Avenir-DemiBold;
    font-size: $headingMainTwo;
    white-space: pre;
    margin-right: 10px;
  }
  .text-dropdown {
    @include flexbox();
    .custom-dropdown {
      width: 100%;
    }
    .custom-dropdown-btn {
      background-color: $pureWhite !important;
      width: 100%;
      border: 2px solid #f3f3f3 !important;
      color: $dark !important;
      border-radius: 4px;
      height: 38px;
      font-size: $headingMainTwo;
      padding-left: 10px;
      padding-top: 0px;
      line-height: 35px;
      padding-right: 10px;
      @include flexbox();
      @include justify-content(space-between);
      box-shadow: unset !important;
      &:active {
        outline: unset !important;
        background-color: $pureWhite;
      }
      &::after {
        top: 12px;
        position: relative;
      }
    }
    .custom-dropdown-item {
      width: 100% !important;
    }
  }

  .disabled-btn {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
