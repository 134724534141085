@import 'scss/variables';
@import 'scss/flex';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';

.payment-links {
  .heading {
    @include flexbox();
    @include align-items(center);
    @include justify-content(space-between);
    .dropdown {
      .dropdown-toggle-btn {
        background-color: white;
        margin-right: 12px;
      }
    }
    h2 {
      font-size: $h2;
      color: rgba(0, 0, 0, 0.7);
      padding: 20px !important;
      font-family: Avenir-DemiBold;
      margin-bottom: 0px;
      padding: 20px !important;
    }
  }
}
