@import 'scss/variables';
@import 'scss/media_queries';
@import 'scss/theme';

.titleContainer {
  position: relative;
  min-height: 58px;
  height: 58px;

  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid $borderBlack;

  .title {
    font-weight: 600;
    display: inline-block;
    font-size: $headingSection;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 20px;
    padding-right: 20px;
  }

  .menuCollapseButton {
    position: absolute;
    left: 0px;
    background-color: #fff;
    border: 0;
    display: flex;
    height: 100%;
    width: 2rem;
    align-items: center;
    justify-content: center;
  }
}

.menu {
  list-style-type: none;
  margin: 2rem 2rem 0 1.5rem;

  .listItem {
    padding: 0.5rem 0;

    .linkContainer {
      .linkItem {
        display: flex;
        align-items: center;
        text-decoration: none;
        font-size: 1rem;
        color: $dark;
        font-weight: 600;

        &.active {
          color: $primary;
        }
      }
    }
  }

  .childMenu {
    list-style-type: none;
    padding-left: 0.5rem;

    .childItemStyle {
      font-size: 1rem;
    }
  }
}

.footerContainer {
  display: flex;
  // align-items: center;
  margin-top: auto;
  padding: 1rem;
  border-top: 1px solid $borderBlack;

  .userMenu a {
    display: flex;
    // justify-content: center;
    align-items: center;
  }

  .icon {
    color: $primary;
  }

  .text {
    font-weight: 600;
  }
  .footerMenuToggle {
    display: flex;
    align-items: center;
    font-size: $headingSectionThree;
    .displayEmail {
      color: $themeBlack;
      font-family: Avenir-DemiBold;
      display: inline-block;
      text-overflow: ellipsis;
      width: 150px;
      white-space: nowrap;
      overflow: hidden;
    }
    &:active,
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
  .footerMenu {
    width: 100%;
    border-radius: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border: 1px solid $pureWhite;
    box-shadow: 0px 2px 4px 5px rgba(0, 0, 0, 0.1);
    position: relative;
    left: 0px;
    width: 109%;
    transform: translate(-10px, -61px) !important;
    > a {
      border-bottom: 1px solid #d8d8d8;
      padding: 0.25rem 0.5rem;
      font-size: $textParagraphOne;
      font-family: Avenir-DemiBold;
      color: $themeBlack;
      &:hover {
        background-color: inherit;
        color: $primary;
      }
    }
    &:after {
      content: '';
      background-color: $pureWhite;
      width: 1.5rem;
      height: 2rem;
      bottom: -2rem;
      position: absolute;
      right: -1px;
      z-index: 0;
      border: 1px solid #dadada;
      border-top: 0;
      cursor: pointer;
    }
  }
}

.circleBox {
  background: $pureWhite;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  display: flex;
  border: 0;
}

@include screen-below('xl') {
  .userMenu {
    span {
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 6rem;
      overflow: hidden;
    }
  }
  .titleContainer {
    .title {
      font-size: 1rem;
    }
  }
}

@include screen-up('lg') {
  .titleContainer {
    .menuCollapseButton {
      display: none;
    }
  }
}

@include screen-below('lg') {
  .titleContainer {
    justify-content: flex-end;
    margin-right: 0.5rem;
    .title {
      font-size: 1.2rem;
    }
  }
  .userMenu {
    span {
      max-width: 10rem;
    }
  }
}
