@import 'scss/variables';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';
@import 'scss/flex';

.text-input {
  @include flexbox();
  @include align-items(center);
}
.text-label {
  color: $dark;
  font-family: Avenir-DemiBold;
  font-size: $headingMainTwo;
  white-space: pre;
  margin-right: 10px;
}
.text-input-box {
  width: 100%;
  font-family: Avenir-Regular;
  border: 2px solid #f3f3f3;
  border-radius: 4px;
  height: 38px;
  font-size: $headingMainTwo;
  color: $dark;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 2px;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $greyBlack;
  opacity: 0.2; /*   opacity: 0.2; Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $greyBlack;
  opacity: 0.2;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: $greyBlack;
  opacity: 0.2;
}

::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
