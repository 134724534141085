@import 'scss/variables';
@import 'scss/flex';
@import 'scss/mixins';
@import 'scss/media_queries';
@import 'scss/theme';

.user-collect-request {
  .title {
    color: $greyBlack;
    font-size: $headingMain;
    font-family: Avenir-DemiBold;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 60px;
  }
  .card-group {
    @include flexbox();
    @include justify-content(space-between);
    padding: 0px 10%;
    .card {
      box-shadow: 0px 6.32797px 31.6399px rgba(0, 0, 0, 0.09);
      border-radius: 31.6399px;
      max-width: 226px;
      width: 100%;
      min-height: 185px;
      font-size: $headingSection;
      @include flexbox();
      @include align-items(center);
      @include justify-content(center);
      @include flex-direction(column);
      padding: 20px 30px;
      .card-title {
        font-family: Avenir-Regular;
        text-align: center;
        margin-bottom: 20px;
      }
      .card-footer {
        text-align: center;
        font-family: Avenir-DemiBold;
      }
    }
  }
  .amount {
    width: 492px;
    min-height: 115px;
    font-size: $headingSection;
    border: 1px solid $offBlack;
    @include flexbox();
    @include align-items(center);
    border-radius: 31.64px;
    box-shadow: 0px 6.32797px 31.6399px rgba(0, 0, 0, 0.09);
    margin: 60px auto;
    .title {
      font-family: Avenir-Regular;
      font-size: $headingSection;
      text-align: left;
      padding-left: 30px;
      margin-bottom: 0px;
      margin-top: 0px;
      padding-right: 40px;
    }
    .desc {
      font-family: Avenir-DemiBold;
      text-align: center;
      font-size: $headingSection;
      @include flexbox();
      @include align-items(center);
    }
  }

  .custom-btn-group {
    @include flexbox();
    @include justify-content(center);
    padding: 0;
    .custom-btn {
      font-size: $headingSection;
      background-color: $savvyYellow;
      font-family: Avenir-DemiBold;
      color: $greyBlack !important;
      border: 1px solid $savvyYellow;
      padding: 15px 45px;
      border-radius: 8px;
      min-width: 250px;
    }
  }

  .disabled-btn {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .redeem-screen {
    padding: 0px 0px 50px 0px;
    .redeem-screen-content {
      background-color: $pureWhite;
      max-width: 578px;
      border-radius: 8px;
      margin: 0px auto;
      padding-bottom: 20px;
      .heading {
        font-family: Avenir-Regular;
        color: $greyBlack;
        font-size: $textParagraphOne;
        text-align: center;
        text-decoration: underline;
        padding-top: 20px;
        padding-bottom: 20px;
        cursor: pointer;
      }
      .transfer {
        @include flexbox();
        @include align-items(center);
        @include justify-content(space-around);
        .content-box {
          text-align: center;
          max-width: 240px;
          .number {
            font-size: $headingSection;
            color: $pureWhite;
            background-color: $savvyYellow;
            width: 33px;
            height: 33px;
            border-radius: 50%;
            @include flexbox();
            @include align-items(center);
            @include justify-content(center);
            margin: 10px auto;
          }
          .sub-heading {
            font-size: $textParagraphOne;
          }
        }
        .icon {
          padding-top: 53px;
        }
      }
    }
    .screen-content {
      background-color: $greyWhiteTwo;
      max-width: 578px;
      border-radius: 8px;
      margin: 0px auto;
      padding-bottom: 20px;
    }
  }
  .redeem-screen-active {
    padding: 30px 0px 50px 0px;
  }
}
